import * as React from 'react';
import { useAutocomplete, AutocompleteGetTagProps } from '@mui/base/useAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { HTMLAttributes, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InputAdornment from '@mui/material/InputAdornment';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Typography } from '@mui/material';
import { COLORS } from 'theme/colors';

const Root = styled('div')`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
`;

interface InputWrapperProps extends HTMLAttributes<HTMLDivElement> {
  error?: string;
}

const Label = styled('label')(
  ({ theme: { palette } }) => `
  display: flex;
  font-size: 0.9rem;
  font-weight:700;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  color: ${palette.text.lightGrey.main};
`,
);

const InputWrapper = styled('div')<InputWrapperProps>(
  ({ theme: { palette, spacing }, error }) => `
  border: 1px solid ${error ? COLORS.RED.MAIN : palette.border.darkGrey};
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: ${spacing(1.5, 4.5, 1.5, 1)};
  cursor: pointer;
  
  &:hover {
    border-color: ${palette.border.secondary};
  }

  &.focused {
    border-color: ${error ? COLORS.RED.MAIN : palette.border.secondary};
    border-width: 1px;
  }

  & input {
    z-index: 5;
    background: transparent;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;    
  }
  
  & .MuiInputAdornment-root {
    position: absolute;
    right: 8px;
    top: 50%; 
  }
`,
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

function Tag(props: TagProps) {
  const { label, onDelete, ...other } = props;
  return (
    <div onMouseDown={(e) => e.stopPropagation()} {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 8px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }
  
  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`,
);

const Listbox = styled('ul')(
  ({ theme }) => `
  
  margin: 2px 0 0;
  padding: 0;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  & li {
    padding: 5px 12px;
    display: flex;
    z-index: 10;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
);

type locationType = { identifier: string; name: string };

interface Props {
  locations: locationType[];
  onChange?: (selectedLocations: string[]) => void;
  defaultValue?: locationType[];
  label: string;
  errorText?: string;
  required?: boolean;
}

export const LocationSelect = ({
  locations,
  onChange,
  defaultValue,
  label,
  errorText,
  required,
}: Props) => {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    popupOpen,
  } = useAutocomplete({
    multiple: true,
    options: locations,
    defaultValue: defaultValue ?? [],
    getOptionLabel: (option) => option.name,
  });

  useEffect(() => {
    if (value && onChange) {
      const selectedLocations = value.map((location) => location.identifier);
      onChange(selectedLocations);
    }
  }, [value]);

  return (
    <Root>
      <div
        style={{ display: ' flex', gap: '0.5rem', flexDirection: 'column' }}
        {...getRootProps()}
      >
        <Label {...getInputLabelProps()}>
          {label}
          {required && <Typography textAlign={'start'}>*</Typography>}
        </Label>
        <InputWrapper
          onMouseDown={getInputProps().onMouseDown}
          className={focused ? 'focused' : ''}
          error={errorText}
        >
          {value.map((option: locationType, index: number) => (
            <StyledTag
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              key={option.identifier}
              label={option?.name}
              {...getTagProps({ index })}
            />
          ))}
          <input {...getInputProps()} />
          <InputAdornment position="end">
            {popupOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </InputAdornment>
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as typeof locations).map((option, index) => (
            <li key={index} {...getOptionProps({ option, index })}>
              <span>{option.name}</span>
              <CheckIcon fontSize="small" />
            </li>
          ))}
        </Listbox>
      ) : null}
      {errorText && <Typography color="error">{errorText}</Typography>}
    </Root>
  );
};
