import { Button as MuiButton, ButtonProps, styled } from '@mui/material';

const StyledButton = styled(MuiButton)(({ theme: { typography } }) => ({
  ...typography.button,
  textTransform: 'none',
  whiteSpace: 'nowrap',
}));

const AppButton: React.FC<ButtonProps & { hideButton?: boolean }> = ({
  children,
  hideButton = false,
  ...rest
}) => {
  return !hideButton ? <StyledButton {...rest}>{children}</StyledButton> : null;
};

export default AppButton;
