import { ApiResponse } from 'types/utilityInterfaces';
import { useQuery } from 'react-query';
import { Merchant, UserRole } from 'types/commonTypes';
import { getNotEmptyParams } from 'utils/funcs/convert';
import { api } from '../index';
import { FileType, getFile } from 'utils/funcs/downloadFile';

export interface MerchantsFilter {
  merchantId: string;
  name: string;
}

const getAllMerchants = async (
  networkId: string,
  params: Partial<Record<string, unknown>>,
) => {
  const notEmptyParams = getNotEmptyParams(params);
  const response = await api.get<ApiResponse<Merchant[]>>(
    `/networks/${networkId}/merchants`,
    {
      params: notEmptyParams,
    },
  );
  return response.data;
};

export const useGetAllMerchantsQuery = (
  networkId: string,
  currentRole: UserRole,
  params = {},
) =>
  useQuery(['getAllMerchants', networkId, currentRole, params], () =>
    getAllMerchants(networkId, params),
  );

const getMerchant = async (networkId: string, merchantId: string) => {
  const response = await api.get<Merchant>(
    `/networks/${networkId}/merchants/${merchantId}`,
  );
  return response.data;
};

export const useGetMerchantQuery = (networkId: string, merchantId: string) =>
  useQuery(
    ['getMerchant', networkId, merchantId],
    () => getMerchant(networkId, merchantId),
    {
      staleTime: 0,
    },
  );

export const exportMerchants = async (
  networkId: string,
  type: FileType,
  filterParams = {},
) => {
  const notEmptyParams = getNotEmptyParams(filterParams);
  return await getFile(
    `/networks/${networkId}/merchants/export/${type}`,
    type,
    'Merchants',
    notEmptyParams,
  );
};
