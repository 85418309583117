import { styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import PointOfSale from '@mui/icons-material/PointOfSale';
import HomeIcon from '@mui/icons-material/Home';
import {
  ROOT_URL,
  NAVBAR_LABELS,
  TERMINALS_URL,
  LOCATIONS_URL,
  TRANSACTIONS_URL,
  MERCHANTS_URL,
} from 'pages/appRoutes/appRoutesConst';
import PinDropIcon from '@mui/icons-material/PinDrop';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { COLORS } from 'theme/colors';
import { NavbarField } from '../authenticatedLayout';
import { Store } from '@mui/icons-material';
import { useAppSelector } from 'stores/store';

export const NAVBAR_FIELDS: NavbarField[] = [
  {
    icon: <HomeIcon />,
    url: ROOT_URL,
    label: 'dashboard',
  },
  {
    icon: <Store />,
    label: NAVBAR_LABELS.MERCHANTS_LABEL,
    url: MERCHANTS_URL,
  },
  {
    icon: <PinDropIcon />,
    label: NAVBAR_LABELS.LOCATIONS_LABEL,
    url: LOCATIONS_URL,
  },
  {
    icon: <PointOfSale />,
    label: NAVBAR_LABELS.TERMINALS_LABEL,
    url: TERMINALS_URL,
  },
  {
    icon: <SwapHorizIcon />,
    label: NAVBAR_LABELS.TRANSACTIONS_LABEL,
    url: TRANSACTIONS_URL,
  },
];

type NavButtonProps = { isActive: boolean };

const NavButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<NavButtonProps>(({ theme: { palette }, isActive }) => ({
  ':first-letter': {
    textTransform: 'capitalize',
  },
  textDecoration: 'none',
  color: isActive ? COLORS.DARK_BLUE.MAIN : palette.text.lightGrey.main,
  background: isActive ? palette.primary.main : 'unset',
  borderRadius: '10px',
}));

interface StandardNavItemProps {
  url?: string;
  label: string;
  icon: React.ReactNode;
  isCollapsed: boolean;
  handleToggleCollapsed: () => void;
}

export const StandardNavItem: React.FC<StandardNavItemProps> = ({
  url = '/',
  label,
  icon,
  isCollapsed = false,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'navigation' });
  const isNetworkRole = useAppSelector((state) => state.user.isNetworkRole);

  if (url === MERCHANTS_URL && !isNetworkRole) {
    return <></>;
  }

  return (
    <NavLink
      style={{
        textDecoration: 'none',
        display: 'block',
      }}
      to={url}
    >
      {({ isActive }) => {
        return (
          <NavButton sx={{ padding: '16px' }} isActive={isActive}>
            <div
              style={{
                display: 'flex',
                gap: '2rem',
                justifyContent: isCollapsed ? 'center' : 'start',
              }}
            >
              {icon}
              {!isCollapsed && <Typography>{t(label)}</Typography>}
            </div>
          </NavButton>
        );
      }}
    </NavLink>
  );
};
