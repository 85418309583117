// AUTHENTICATED ROUTES
export const ROOT_URL = '/';

export const MERCHANTS_URL = '/merchants';
export const TERMINALS_URL = '/terminals';
export const TRANSACTIONS_URL = '/transactions';
export const NOTIFICATION_URL = '/notifications';
export const LOCATIONS_URL = '/locations';

// DETAIL URL
export const TRANSACTION_DETAIL_URL =
  '/transactions/:transactionId/:merchantId/:locationId/:terminalId';
export const TERMINAL_DETAIL_URL = '/terminals/:terminalId/:locationId/:merchantId';
export const LOCATION_DETAIL_URL = '/locations/:locationId/:merchantId';

export const MERCHANT_DETAIL_URL = 'merchants/:id';
export const CALLBACK_URL = '/callback';
export const LOGOUT_URL = '/logout';
export const ERROR_PAGE = '/error';
export const LOGIN_URL = '/login';

// NAVIGATION ROUTES AND BREACRUMB LABELS
export const NAVBAR_LABELS = {
  MERCHANTS_LABEL: 'merchants',
  TRANSACTIONS_LABEL: 'transactions',
  TERMINALS_LABEL: 'terminals',
  NOTIFICATION_LABEL: 'notification',
  LOCATIONS_LABEL: 'locations',
};
