import { Card, IconButton, Typography, useTheme } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridColumns,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { csCZ, deDE, enUS, frFR, nlNL, esES, roRO, skSK } from '@mui/x-data-grid/locales';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsIcon from '@mui/icons-material/Settings';
import MapIcon from '@mui/icons-material/Map';
import TableChartIcon from '@mui/icons-material/TableChart';
import AppMenuButton from 'components/appMenuButton';
import { ThemeProvider } from '@mui/material/styles';
import { dataGridTheme } from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';

export interface AppTableProps {
  rows: readonly {
    [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }[];
  columns: GridColumns;
  rowsPerPage?: number[];
  initalPage?: number;
  appTitle: string;
  // TODO: change component type
  mapComponent?: React.ReactNode;
  showSettings?: boolean;
  showExportButton?: boolean;
  exportMenuItems?: { onClick: () => void; label: string }[];
  hideHeaderRow?: boolean;
  page?: number;
  pageSize?: number;
  setPageSize?: (pageSize: number) => void;
  setPage?: (page: number) => void;
  totalRows?: number;
  handleSortModelChange?: (sortModel: GridSortModel) => void;
}

export const AppTable = ({
  rows,
  columns,
  page,
  pageSize,
  setPage,
  setPageSize,
  rowsPerPage = [5, 10, 20],
  totalRows = 0,
  appTitle,
  mapComponent,
  showSettings = true,
  showExportButton = true,
  hideHeaderRow = false,
  exportMenuItems,
  handleSortModelChange,
  ...rest
}: AppTableProps & DataGridProProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [localeText, setLocaleText] = useState(enUS);
  const [isMapDisplayed, setIsMapDisplayed] = useState(false);
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation('common', { keyPrefix: 'components.table' });

  useEffect(() => {
    if (resolvedLanguage === 'en') setLocaleText(enUS);
    if (resolvedLanguage === 'cs') setLocaleText(csCZ);
    if (resolvedLanguage === 'de') setLocaleText(deDE);
    if (resolvedLanguage === 'fr') setLocaleText(frFR);
    if (resolvedLanguage === 'nl') setLocaleText(nlNL);
    if (resolvedLanguage === 'es') setLocaleText(esES);
    if (resolvedLanguage === 'ro') setLocaleText(roRO);
    if (resolvedLanguage === 'sk') setLocaleText(skSK);
  }, [resolvedLanguage]);

  const rowsWithId = rows.length
    ? rows.map((row) => ({ ...row, id: row.id ?? row.uuid }))
    : [];

  return (
    <Card sx={{ width: '100%' }} onMouseDown={(e) => e.stopPropagation()}>
      {!hideHeaderRow && (
        <Typography
          component="div"
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '8px',
          }}
        >
          <Typography component="div" sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5" component="div">
              {appTitle}
            </Typography>
            {mapComponent && (
              <IconButton onClick={() => setIsMapDisplayed(!isMapDisplayed)}>
                {isMapDisplayed ? <TableChartIcon /> : <MapIcon />}
              </IconButton>
            )}
          </Typography>
          <div>
            {showSettings && (
              <IconButton>
                <SettingsIcon />
              </IconButton>
            )}
            {showExportButton && exportMenuItems && exportMenuItems?.length > 0 && (
              <AppMenuButton
                startIcon={<DownloadIcon />}
                endIcon={<KeyboardArrowDownIcon />}
                color="secondary"
                size="small"
                variant="contained"
                menuItems={exportMenuItems}
              >
                {t('export')}
              </AppMenuButton>
            )}
          </div>
        </Typography>
      )}
      {isMapDisplayed ? (
        mapComponent
      ) : (
        <ThemeProvider theme={dataGridTheme}>
          <DataGridPro
            disableColumnFilter
            disableColumnSelector
            localeText={localeText.components.MuiDataGrid.defaultProps.localeText}
            columns={columns as unknown as GridColumns}
            rows={rowsWithId}
            getRowId={(row) => (row?.uuid ? row.uuid : row.identifier)}
            pagination
            rowCount={totalRows}
            rowsPerPageOptions={rowsPerPage}
            pageSize={pageSize}
            page={page}
            onPageSizeChange={setPageSize}
            onPageChange={setPage}
            autoHeight
            sortingMode="server"
            paginationMode="server"
            onSortModelChange={handleSortModelChange}
            sx={{
              width: '100%',
              borderRadius: '0px',
              borderLeft: 'none',
              borderRight: 'none',
              borderBottom: 'none',
              color: theme.palette.text.darkGrey.main,
              '& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-columnHeaderDraggableContainer':
                {
                  flexDirection: isMobile ? 'row-reverse' : 'row',
                },
            }}
            {...rest}
          />
        </ThemeProvider>
      )}
    </Card>
  );
};
