import { MenuItem, Box, Typography, styled } from '@mui/material';
import {
  getTransactionOverviewForLocation,
  getTransactionOverviewForTerminal,
} from 'api/endpoints/transactions';
import AppButton from 'components/appButton';
import { AppDataContainer } from 'components/appDataContainer';
import { DataGraphContainer } from 'components/appDataContainer/dataGraphContainer';
import { SimpleAppSelect } from 'components/form/select/simpleAppSelect';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'stores/store';
import { TimePeriod } from 'types/enumTypes';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { TRANSACTIONS_URL } from 'pages/appRoutes/appRoutesConst';
import { useNavigate, useParams } from 'react-router';
import {
  LocationType,
  TerminalDetail,
  TransactionOverviewResponse,
} from '../../types/commonTypes';
import { useFiltersContext } from 'context/FiltersContext';
import { getTransformedTimePeriod } from 'utils/funcs/formatTimePeriod';
import { errorHandling } from 'utils/funcs/errorHandling';
import FullscreenLoader from 'components/fullscreenLoader';

const ImportantDataBox = styled('div')(({ theme: { spacing } }) => ({
  marginBottom: spacing(3),
  padding: spacing(1, 2),
}));

const ImportantDataBoxColored = styled('div')(({ theme: { spacing, palette } }) => ({
  marginBottom: spacing(3),
  backgroundColor: palette.primary.light,
  padding: spacing(1, 2),
  borderRadius: '10px',
  width: '100%',
}));

const ImportantDataBoxSmallText = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.lightGrey.main,
}));

const ImportantDataBoxLargeText = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.h3,
}));

interface RevenueOverviewProps {
  showSquaredButton?: boolean;
  buttonText?: string;
  buttonFunction?: () => void;
  endpoint: 'location' | 'terminals';
  dataDirection?: 'row' | 'column';
  locationId?: string;
  terminalId?: string;
  propsData: LocationType | TerminalDetail | undefined;
}

export const RevenueOverview: React.FC<RevenueOverviewProps> = ({
  showSquaredButton = false,
  endpoint,
  dataDirection,
  locationId,
  terminalId,
  propsData,
}) => {
  if (!propsData) return <></>;

  const { networkId } = useAppSelector((state) => state.user);
  const [timeframe, setDays] = useState(TimePeriod['LAST_30_DAYS']);
  const { t } = useTranslation('common', { keyPrefix: 'components.revenueOverview' });
  const { t: tEnums } = useTranslation('common', { keyPrefix: 'enums' });
  const { filterValues, setFilters } = useFiltersContext();
  const { merchantId } = useParams();
  const [data, setData] = useState<TransactionOverviewResponse>();
  const [currency, setCurrency] = useState(propsData.currencies[0]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  if (!merchantId) {
    return null;
  }

  useEffect(() => {
    if (endpoint === 'location') {
      setIsLoading(true);
      getTransactionOverviewForLocation(networkId, merchantId, locationId as string, {
        timeframe,
        currency,
      })
        .then((res) => {
          setData(res);
        })
        .catch((error) => errorHandling(error))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      getTransactionOverviewForTerminal(
        networkId,
        merchantId,
        locationId as string,
        terminalId as string,
        {
          timeframe,
          currency,
        },
      )
        .then((res) => {
          setData(res);
        })
        .catch((error) => errorHandling(error))
        .finally(() => setIsLoading(false));
    }
  }, [networkId, merchantId, locationId, terminalId, propsData, timeframe, currency]);

  const BUTTONS = [
    <SimpleAppSelect
      onChange={(e) => setDays(e.target.value as TimePeriod)}
      value={timeframe}
      key="selectPeriod"
    >
      {Object.values(TimePeriod).map((option, index) => (
        <MenuItem key={index} value={option}>
          {tEnums(`timePeriod.${option}`)}
        </MenuItem>
      ))}
    </SimpleAppSelect>,
  ];

  if (propsData?.currencies.length > 0) {
    BUTTONS.push(
      <SimpleAppSelect
        onChange={(e) => setCurrency(e.target.value as string)}
        value={currency}
        key="selectCurrency"
      >
        {propsData?.currencies.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </SimpleAppSelect>,
    );
  }

  const showAllTransactionsHandler = () => {
    setFilters({
      ...filterValues,
      LOCATION: locationId,
      DATE_RANGE: getTransformedTimePeriod(timeframe),
    });
    navigate(TRANSACTIONS_URL);
  };

  if (isLoading) return <FullscreenLoader />;

  return (
    <AppDataContainer
      titleRow={{
        title: t('title'),
        buttons: BUTTONS,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: dataDirection || 'row',
          padding: 3,
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ marginY: 'auto', flex: 1, display: 'flex', flexDirection: 'column' }}>
          <ImportantDataBox>
            <ImportantDataBoxLargeText>{`${(
              parseFloat(data?.amount_total || '0') / 100
            ).toFixed(2)} ${data?.currency ?? ''}`}</ImportantDataBoxLargeText>
            <ImportantDataBoxSmallText>{t('amountTotal')}</ImportantDataBoxSmallText>
          </ImportantDataBox>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <ImportantDataBoxColored>
              <ImportantDataBoxLargeText>
                {data?.transactions_total ?? 0}
              </ImportantDataBoxLargeText>
              <ImportantDataBoxSmallText>
                {t('transactionsTotal')}
              </ImportantDataBoxSmallText>
            </ImportantDataBoxColored>
            <ImportantDataBoxColored>
              <ImportantDataBoxLargeText>
                {`${((data?.average_amount ?? 0) / 100).toFixed(2)} ${
                  data?.currency ?? ''
                }`}
              </ImportantDataBoxLargeText>
              <ImportantDataBoxSmallText>
                {t('transactionsAverage')}
              </ImportantDataBoxSmallText>
            </ImportantDataBoxColored>
          </Box>
          {showSquaredButton && (
            <Box>
              <AppButton
                onClick={showAllTransactionsHandler}
                variant="squared"
                startIcon={<AttachMoneyIcon />}
              >
                {t('showAllTransactions')}
              </AppButton>
            </Box>
          )}
        </Box>
        <Box>
          {data?.data && data?.data.length > 0 && (
            <DataGraphContainer
              yAxisUnit={` ${data.currency}`}
              dataBoxes={data.data.map((graphItem) => ({
                value: parseInt(graphItem.amount) / 100,
                label: graphItem.date,
              }))}
            />
          )}
        </Box>
      </Box>
    </AppDataContainer>
  );
};
