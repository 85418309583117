/* eslint-disable @typescript-eslint/no-explicit-any */
type Callback = (...args: any[]) => void;

export function delayedCallback(callback: Callback, delay: number): Callback {
  let timer: NodeJS.Timeout;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, delay);
  };
}
