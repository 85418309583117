import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  pageSize: number;
  setPageSize: (value: number) => void;
  page: number;
  setPage: (value: number) => void;
  isNextPage: boolean;
}

export const CustomPagination = ({
  pageSize,
  setPageSize,
  page,
  setPage,
  isNextPage,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation('common', { keyPrefix: 'pages.terminalsPage.table' });
  return (
    <Grid container justifyContent={'end'}>
      <Box display={'flex'} alignItems={'center'} gap={'1rem'}>
        <Typography variant={'body2'}>{t('rowsPerPage')}:</Typography>
        <Select
          value={pageSize}
          onChange={(e) => setPageSize(e.target.value as number)}
          sx={{
            '.MuiSelect-select': {
              padding: '5px 30px 5px 10px',
              fontSize: '0.9rem',
            },
            '.MuiOutlinedInput-notchedOutline': { border: 'none !important' },
          }}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </Box>
      <Box display={'flex'} alignItems={'center'} padding={'0 2rem'}>
        <IconButton
          onClick={() => setPage(page - 1)}
          disabled={page <= 0}
          sx={{ fontSize: '1rem' }}
        >
          {theme.direction === 'rtl' ? '❯' : '❮'}
        </IconButton>
        <Typography>{page + 1}</Typography>
        <IconButton
          onClick={() => setPage(page + 1)}
          disabled={!isNextPage}
          sx={{ fontSize: '1rem' }}
        >
          {theme.direction === 'rtl' ? '❮' : '❯'}
        </IconButton>
      </Box>
    </Grid>
  );
};
