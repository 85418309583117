import { useState } from 'react';
import { TSortModel } from 'types/commonTypes';
import * as React from 'react';
import { GridSortModel } from '@mui/x-data-grid-pro';

export const useGridState = (
  defaultPage: number,
  defaultPageSize: number,
  defaultQueryOptions?: TSortModel,
) => {
  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [queryOptions, setQueryOptions] = useState<TSortModel | undefined>(
    defaultQueryOptions,
  );

  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel) => {
      setQueryOptions(sortModel[0] as TSortModel);
      setPage(defaultPage);
    },
    [defaultPage],
  );

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    queryOptions,
    handleSortModelChange,
  };
};
