import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { Box, Typography } from '@mui/material';
import { TransactionStatus } from 'types/enumTypes';

interface StatusHiglightProps {
  status: TransactionStatus | null;
  displayCircle?: boolean;
}

type ColorType =
  | 'inherit'
  | 'action'
  | 'disabled'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

const formatStatus = (status: TransactionStatus | null) => {
  let formatedStatus: ColorType = 'success';
  switch (status?.toLowerCase()) {
    case TransactionStatus['ACCEPTED']:
      formatedStatus = 'success';
      break;
    case TransactionStatus['DECLINED']:
      formatedStatus = 'error';
      break;
    case TransactionStatus['FAILED']:
      formatedStatus = 'error';
      break;
    case TransactionStatus['FINALIZED']:
      formatedStatus = 'success';
      break;
    case TransactionStatus['OPENED']:
      formatedStatus = 'success';
      break;
    case TransactionStatus['REVERSED']:
      formatedStatus = 'info';
      break;

    default:
      formatedStatus = 'info';
      break;
  }
  return formatedStatus;
};

export const AppStatusHighlight: React.FC<StatusHiglightProps> = ({
  status,
  displayCircle = true,
}) => {
  const statusToDisplay = status ? status.toUpperCase() : '-';
  return (
    <Box sx={{ display: 'flex' }}>
      {displayCircle && (
        <CircleIcon sx={{ marginRight: 1 }} color={formatStatus(status)} />
      )}
      <Typography
        sx={{ textTransform: 'capitalize' }}
        component="span"
        color={formatStatus(status) === 'success' ? 'success.main' : formatStatus(status)}
      >
        {statusToDisplay}
      </Typography>
    </Box>
  );
};
