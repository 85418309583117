import { Card, IconButton, ThemeProvider, Typography, useTheme } from '@mui/material';
import { DataGridPro, GridColumns, GridSortModel } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsIcon from '@mui/icons-material/Settings';
import MapIcon from '@mui/icons-material/Map';
import TableChartIcon from '@mui/icons-material/TableChart';
import AppMenuButton from 'components/appMenuButton';
import { CustomPagination } from 'components/appTable/customPagging';
import { dataGridTheme } from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { csCZ, deDE, enUS, esES, frFR, nlNL, roRO, skSK } from '@mui/x-data-grid/locales';
export interface AppTableProps {
  isLoading: boolean;
  rows: readonly {
    [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }[];
  rowCount: number;
  columns: GridColumns;
  page: number;
  setPage: (value: number) => void;
  pageSize: number;
  setPageSize: (value: number) => void;
  isNextPage: boolean;
  appTitle: string;
  mapComponent?: React.ReactNode;
  showSettings?: boolean;
  showExportButton?: boolean;
  exportMenuItems?: { onClick: () => void; label: string }[];
  hideHeaderRow?: boolean;
  handleSortModelChange?: (sortModel: GridSortModel) => void;
}

export const TransactionsTable = ({
  isLoading,
  rows,
  columns,
  page,
  setPage,
  pageSize,
  rowCount,
  setPageSize,
  isNextPage,
  appTitle,
  mapComponent,
  showSettings = true,
  showExportButton = true,
  hideHeaderRow = false,
  exportMenuItems,
  handleSortModelChange,
}: AppTableProps) => {
  const [isMapDisplayed, setIsMapDisplayed] = useState(false);
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation('common', { keyPrefix: 'components.table' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const rowsWithId = rows.map((row) => ({ ...row, id: row.id ?? row.uuid }));
  const [localeText, setLocaleText] = useState(enUS);

  useEffect(() => {
    if (resolvedLanguage === 'en') setLocaleText(enUS);
    if (resolvedLanguage === 'cs') setLocaleText(csCZ);
    if (resolvedLanguage === 'de') setLocaleText(deDE);
    if (resolvedLanguage === 'fr') setLocaleText(frFR);
    if (resolvedLanguage === 'nl') setLocaleText(nlNL);
    if (resolvedLanguage === 'es') setLocaleText(esES);
    if (resolvedLanguage === 'ro') setLocaleText(roRO);
    if (resolvedLanguage === 'sk') setLocaleText(skSK);
  }, [resolvedLanguage]);

  return (
    <Card sx={{ width: '100%' }}>
      {!hideHeaderRow && (
        <Typography
          component="div"
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '8px',
          }}
        >
          <Typography component="div" sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5" component="div">
              {appTitle}
            </Typography>
            {mapComponent && (
              <IconButton onClick={() => setIsMapDisplayed(!isMapDisplayed)}>
                {isMapDisplayed ? <TableChartIcon /> : <MapIcon />}
              </IconButton>
            )}
          </Typography>
          <div>
            {showSettings && (
              <IconButton>
                <SettingsIcon />
              </IconButton>
            )}
            {showExportButton && exportMenuItems && exportMenuItems?.length > 0 && (
              <AppMenuButton
                startIcon={<DownloadIcon />}
                endIcon={<KeyboardArrowDownIcon />}
                color="secondary"
                size="small"
                variant="contained"
                menuItems={exportMenuItems}
              >
                {t('export')}
              </AppMenuButton>
            )}
          </div>
        </Typography>
      )}
      {isMapDisplayed ? (
        mapComponent
      ) : (
        <ThemeProvider theme={dataGridTheme}>
          <DataGridPro
            disableColumnSelector
            loading={isLoading}
            disableColumnFilter
            columns={columns as unknown as GridColumns}
            rows={rowsWithId}
            pagination
            sortingMode="server"
            localeText={localeText.components.MuiDataGrid.defaultProps.localeText}
            onSortModelChange={handleSortModelChange}
            components={{ Pagination: CustomPagination }}
            componentsProps={{
              pagination: {
                page,
                setPage,
                pageSize,
                setPageSize,
                isNextPage,
              },
            }}
            autoHeight
            getRowId={(row) => (row?.uuid ? row.uuid : row.identifier)}
            paginationMode="server"
            rowCount={rowCount}
            sx={{
              width: '100%',
              borderRadius: '0px',
              borderLeft: 'none',
              borderRight: 'none',
              borderBottom: 'none',
              color: theme.palette.text.darkGrey.main,
              '& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-columnHeaderDraggableContainer':
                {
                  flexDirection: isMobile ? 'row-reverse' : 'row',
                },
            }}
          />
        </ThemeProvider>
      )}
    </Card>
  );
};
