import { api } from 'api';
import { errorHandling } from 'utils/funcs/errorHandling';

export type FileType = 'xlsx' | 'csv' | 'pdf';

export const getFile = async (
  route: string,
  type: FileType,
  name: string,
  filterParams?: Record<string, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  try {
    const response = await api.get(route, {
      responseType: 'blob',
      params: filterParams,
    });

    const contentType = response.headers['content-type'];

    const fileExtension = contentType.includes('csv')
      ? 'csv'
      : contentType.includes('excel')
      ? 'xlsx'
      : contentType.includes('pdf')
      ? 'pdf'
      : type;

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: contentType }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}.${fileExtension}`);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
    link.remove();
  } catch (e) {
    errorHandling(e);
  }
};
