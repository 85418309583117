import { Dialog, IconButton, MenuItem, Typography } from '@mui/material';
import { AppDataContainer } from 'components/appDataContainer';
import { useMemo, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { UpdateWidget } from './dialogs/updateWidget';
import { LatestTransaction } from './widgets/latestTransaction';
import { NetworkLoad } from './widgets/networkLoad';
import { CardsWidget } from './widgets/cardsWidget';
import { StatesOfTransactionWidget } from './widgets/statesOfTransactions';
import AppSelect from 'components/form/select';
import { TimePeriod, WidgetTypes } from 'types/enumTypes';
import { useTranslation } from 'react-i18next';
import { Widget } from 'types/commonTypes';
import { RevenueOverviewWidget } from './widgets/revenueOverview';
import dataNotFound from '../../assets/images/icon-data-not-found.svg';
import { Stack } from '@mui/system';
import { COLORS } from 'theme/colors';

interface WidgetProps extends Widget {
  updateCallback?: () => void;
  onTimeSelectChange?: (time: TimePeriod) => void;
}

export type WidgetState = Omit<Widget, 'data'>;

const showTimeSelect = (type: WidgetTypes) => {
  return (
    type === WidgetTypes['CARDS'] ||
    type === WidgetTypes['NETWORK_LOAD'] ||
    type === WidgetTypes['STATES_OF_TRANSACTIONS'] ||
    type === WidgetTypes['ACTIVITY_OVERVIEW']
    // type === WidgetTypes['TERMINAL_NOTIFICATIONS']
  );
};

export const WidgetContainer: React.FC<WidgetProps> = ({
  type,
  data,
  id,
  name,
  position,
  settings,
  updateCallback,
  onTimeSelectChange,
}) => {
  const [widgetState, setWidgetState] = useState<WidgetState>({
    type,
    id,
    position,
    name,
    settings,
  });
  const { t: tEnums } = useTranslation('common', { keyPrefix: 'enums' });
  const { t } = useTranslation('common', { keyPrefix: 'widgets' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeValue, setTimeValue] = useState(widgetState?.settings?.time_period);

  const handleClose = () => setIsModalOpen(false);
  const handleOpen = () => setIsModalOpen(true);

  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const widget = useMemo(() => {
    switch (type) {
      case 'cards':
        return <CardsWidget data={data} />;

      case 'activity_overview':
        return <RevenueOverviewWidget data={data} />;

      case 'states_of_transactions':
        return <StatesOfTransactionWidget data={data} />;

      case 'latest_transactions':
        return <LatestTransaction data={data} />;

      // case 'terminals':
      //   return <TerminalsWidgets data={data} />;

      // case 'terminal_notifications':
      //   return <TerminalsNotifications data={data} />;

      case 'network_load':
        return (
          <NetworkLoad
            // settings={widgetState.settings as NetworkLoadSettings}
            data={data}
          />
        );
    }
  }, [type, data, widgetState]);
  return (
    <>
      <AppDataContainer
        sx={{ display: 'flex', flexDirection: 'column' }}
        titleRow={{
          title: name,
          slimTitle: undefined,
          buttons: [
            showTimeSelect(widgetState.type) ? (
              <AppSelect
                onChange={(e) => {
                  setTimeValue(e.target.value as TimePeriod);
                  if (onTimeSelectChange) {
                    onTimeSelectChange(e.target.value as TimePeriod);
                  }
                }}
                displayEmpty
                value={timeValue}
                key="TimeSelect"
              >
                {Object.values(TimePeriod).map((dayOption, index) => (
                  <MenuItem key={index} value={dayOption}>
                    {tEnums(`timePeriod.${dayOption}`)}
                  </MenuItem>
                ))}
              </AppSelect>
            ) : (
              <></>
            ),
            <IconButton
              key="Settings"
              onClick={() => {
                handleOpen();
              }}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <SettingsIcon />
            </IconButton>,
          ],
        }}
      >
        {data.length > 0 || Object.keys(data).length > 0 ? (
          widget
        ) : (
          <Stack
            flex={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography variant={'body1'} color={COLORS.GREY.MAIN}>
              {t('notFound')}
            </Typography>
            <img width={150} height={150} src={dataNotFound} />
          </Stack>
        )}
      </AppDataContainer>
      <Dialog
        onClose={handleClose}
        onMouseDown={handleClickOutside}
        open={isModalOpen}
        fullWidth={true}
        maxWidth={'xs'}
      >
        <UpdateWidget
          handleClose={handleClose}
          setWidgetState={setWidgetState}
          widgetState={widgetState}
          updateCallback={updateCallback}
          setTimeValue={setTimeValue}
        />
      </Dialog>
    </>
  );
};
