import DragableGrid from 'components/layouts/gridLayout';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetContainer } from 'components/widgetContainer';
import { getWidget, getWidgets, updateWidget } from 'api/endpoints/widgets';
import { alpha, Box, Dialog, useTheme } from '@mui/material';
import { CreateWidget } from 'components/widgetContainer/dialogs/createWidget';
import AppButton from 'components/appButton';
import { Widget } from 'types/commonTypes';
import { TimePeriod } from 'types/enumTypes';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { PageTitle } from 'components/pageTitle';
import { useAppSelector } from 'stores/store';
import useMediaQuery from '@mui/material/useMediaQuery';
import { errorHandling } from 'utils/funcs/errorHandling';
const DashboardPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isCreateWidgetModalOpen, setIsCreateWidgetModalOpen] = useState(false);
  const [number, setNumber] = useState(1);
  const { networkId } = useAppSelector((state) => state.user);
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.dashboard',
  });

  const [widgets, setWidgets] = useState<Widget[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getWidgets();
        setWidgets(data);
      } catch (error) {
        errorHandling(error);
      }
    };
    fetchData();
  }, [networkId, number]);
  const closeCreateWidgetModal = () => setIsCreateWidgetModalOpen(false);

  const onResizeStop = useCallback(
    async (id: string, position: Widget['position']) => {
      const parsedId = parseInt(id);
      const foundWidget = widgets?.find((widget) => widget.id === parsedId);

      if (foundWidget) {
        try {
          await updateWidget(parsedId, {
            type: foundWidget.type,
            position,
            name: foundWidget.name,
            settings: foundWidget.settings,
          });
        } catch (error) {
          errorHandling(error);
        }
      }
    },
    [widgets],
  );

  const getSingleWidget = async (widgetId: number, timePeriod: TimePeriod) => {
    try {
      const response = await getWidget(widgetId, timePeriod);
      return response.data;
    } catch (error) {
      errorHandling(error);
    }
  };

  const periodChangeHandler = async (widgetId: number, period: TimePeriod) => {
    const updatedWidgetData = await getSingleWidget(widgetId, period);
    if (updatedWidgetData) {
      const updatedWidgets = widgets.map((widget) => {
        if (widget.id === widgetId) {
          return updatedWidgetData;
        } else {
          return widget;
        }
      });

      setWidgets(updatedWidgets as Widget[]);
    }
  };
  const widgetsInContainers = useMemo(() => {
    return widgets?.map((widget, index) => ({
      element: (
        <React.Fragment key={index}>
          <WidgetContainer
            {...widget}
            updateCallback={() => setNumber(number + 1)}
            onTimeSelectChange={(period) => periodChangeHandler(widget.id, period)}
          />
        </React.Fragment>
      ),
    }));
  }, [widgets]);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <PageTitle title={t('pageTitle')} />
        <AppButton
          variant="contained"
          onClick={() => setIsCreateWidgetModalOpen(true)}
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            padding: theme.spacing(2, 2),
            ':hover': {
              backgroundColor: alpha(theme.palette.secondary.main, 0.8),
            },
            gap: 1,
          }}
        >
          <AddCircleIcon />
          {t('createWidget')}
        </AppButton>
      </Box>
      <Dialog open={isCreateWidgetModalOpen} fullWidth={true} maxWidth={'xs'}>
        <CreateWidget
          createCallBack={() => {
            setNumber(number + 1);
          }}
          handleClose={closeCreateWidgetModal}
        />
      </Dialog>
      <DragableGrid
        containers={widgetsInContainers || []}
        onResizeStop={onResizeStop}
        onDragStop={onResizeStop}
      />
    </>
  );
};

export default DashboardPage;
