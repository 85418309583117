import { useGlobalContext } from 'GlobalContext';
import AuthenticatedLayout from 'components/layouts/authenticatedLayout';
import { CALLBACK_URL } from 'pages/appRoutes/appRoutesConst';
import { useEffect } from 'react';
import { errorHandling } from 'utils/funcs/errorHandling';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  children: React.ReactNode;
}

const AuthGuard = ({ children }: Props) => {
  const { oidcRpClient, oidcRpLoginData } = useGlobalContext();
  const location = useLocation();
  const { i18n } = useTranslation();
  const getCurrentLocale = () =>
    localStorage.getItem('retailLang') ?? i18n.resolvedLanguage;
  const login = () => {
    oidcRpClient
      .loginWithRedirect({
        redirectUri: `${window.location.origin}${CALLBACK_URL}`,
        scope: '',
        state: `${location.pathname}${location.search}`,
        uiLocales: getCurrentLocale(),
      })
      .then(() => {
        window.location.reload();
      })
      .catch((e: unknown) => {
        errorHandling(e);
      });
  };
  useEffect(() => {
    if (!oidcRpLoginData) {
      login();
    }
  }, []);

  return <AuthenticatedLayout>{children}</AuthenticatedLayout>;
};

export default AuthGuard;
