import { useDispatch } from 'react-redux';
import { setUserGroup, setUserRole } from 'stores/slices/userSlice';
import { UserGroup, UserRole } from 'types/commonTypes';
import { parseRoleContext } from 'utils/funcs/convert';
import { findGroupById } from 'utils/findGroupById';

const useAuthentication = () => {
  const dispatch = useDispatch();
  const userGroups = window?.userGroups ?? [];

  const getRoleById = (roleId: string): UserRole | undefined => {
    for (const group of userGroups) {
      const role = group.roles.find((r: UserRole) => r.id === roleId);
      if (role) {
        return role; // Return the role if found in any group.
      }
    }
    return undefined; // Return undefined if the group with the specified ID is not found.
  };

  const changeRole = async (roleId: string) => {
    const pickedRole = getRoleById(roleId);
    const pickedGroup = findGroupById(userGroups, roleId);

    if (pickedRole) {
      window.userRoleId = roleId;
      window.userRole = pickedRole;
      window.userGroup = pickedGroup;
      dispatch(
        setUserRole({ ...parseRoleContext(pickedRole.context), role: { ...pickedRole } }),
      );
      dispatch(setUserGroup({ group: { ...pickedGroup } }));
      localStorage.setItem('roleIdRetail', JSON.stringify(roleId));
    }
  };

  const currentRole = window?.userRole as UserRole;
  const currentUserGroup = window?.userGroup as UserGroup;

  const getPermission = () => {
    return currentRole.actions;
  };

  const hasPermission = (permissionName: string) => {
    return !!currentRole?.actions?.find((row) => row === permissionName);
  };

  return {
    changeRole: changeRole,
    userGroups,
    currentRole,
    currentUserGroup,
    getPermission,
    hasPermission,
  };
};

export default useAuthentication;
