import { api } from '../index';
import { useQuery } from 'react-query';
import { Widget } from 'types/commonTypes';
import { TimePeriod } from 'types/enumTypes';

export const getWidgets = async () => {
  const response = await api.get<Widget[]>(`/widgets`);
  return response.data;
};

export const useWidgetsQuery = (trigger: number, networkId: string) =>
  useQuery(['getTransactionDetail', trigger, networkId], () => getWidgets());

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getWidget = async (widgetId: number, time_period?: TimePeriod) => {
  const response = await api.get<Widget>(`/widgets/${widgetId}`, {
    params: { time_period },
  });
  return response;
};

export const deleteWidget = async (widgetId: number) => {
  const response = await api.delete<Omit<Widget, 'data'>>(`/widgets/${widgetId}`);
  return response;
};

export const updateWidget = async (
  widgetId: number,
  widgetData: Omit<Widget, 'id' | 'data'>,
) => {
  const response = await api.put<Widget>(`/widgets/${widgetId}`, {
    ...widgetData,
  });
  return response;
};

export const createWidget = async (widgetData: Omit<Widget, 'id' | 'data'>) => {
  const response = await api.post<Widget>(`/widgets`, {
    ...widgetData,
  });
  return response;
};
