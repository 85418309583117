import { styled, Select as MuiSelect, SelectProps } from '@mui/material';
import FormField from '../formField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AppInputBase from '../appInputBase';
import { COLORS } from 'theme/colors';
import { forwardRef } from 'react';

const StyledSelect = styled(MuiSelect)(({ theme: { spacing } }) => ({
  height: '43.5px',
  padding: `${spacing(0.7, 0.6)} !important`,
}));

const StyledSelectAsButton = styled(MuiSelect, {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: SelectSize }>(({ size, theme: { typography, palette, spacing } }) => ({
  '&.MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
    ...typography.h6,
    fontWeight: 'bold',
  },
  '&.MuiInputBase-root': {
    border: 'none',
    backgroundColor: palette.primary.light,
    padding: spacing(0.5, 1),
    borderRadius: '4px',
  },
  '& .MuiInputBase-input': {
    padding: size ? spacing(0, 1) : spacing(1, 1.5),
    color: `${COLORS.DARK_BLUE.MAIN} !important`,
    fontWeight: 500,
  },
}));

type SelectVariant = 'standard' | 'asButton';
type SelectSize = 'small' | 'medium';
interface AppSelectProps extends SelectProps {
  errorText?: string;
  selectVariant?: SelectVariant;
  size?: SelectSize;
}

const AppSelect: React.ForwardRefRenderFunction<HTMLDivElement, AppSelectProps> = (
  {
    label,
    error,
    errorText,
    required,
    children,
    selectVariant = 'standard',
    size = 'small',
    startAdornment,
    ...rest
  },
  ref,
) => {
  const inputId = rest.id || `app-select-${Math.random().toString(36).substr(2, 9)}`;
  return (
    <FormField
      label={label}
      required={required}
      error={error}
      errorText={errorText}
      htmlFor={inputId}
    >
      {selectVariant === 'asButton' ? (
        <StyledSelectAsButton
          ref={ref}
          size={size}
          IconComponent={KeyboardArrowDownIcon}
          fullWidth
          input={<AppInputBase id={inputId} />}
          error={error}
          {...rest}
        >
          {children}
        </StyledSelectAsButton>
      ) : (
        <StyledSelect
          ref={ref}
          IconComponent={KeyboardArrowDownIcon}
          fullWidth
          input={
            <AppInputBase
              id={inputId}
              startAdornment={startAdornment}
              error={error || !!errorText}
            />
          }
          error={error || !!errorText}
          {...rest}
        >
          {children}
        </StyledSelect>
      )}
    </FormField>
  );
};

export default forwardRef(AppSelect);
