import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ButtonProps, Menu, MenuItem } from '@mui/material';

import AppButton from 'components/appButton';
import { useState } from 'react';

// App button which displays menu with selectable items
const AppMenuButton: React.FC<
  ButtonProps & {
    menuItems: { onClick: () => void; label: string }[];
  }
> = ({ menuItems, children, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppButton onClick={handleClick} endIcon={<KeyboardArrowDownIcon />} {...rest}>
        {children}
      </AppButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems.map((item, index) => (
          <MenuItem key={index} onClick={item.onClick} disableRipple>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AppMenuButton;
