import { Box, styled, useTheme } from '@mui/material';
import {
  ColumnConfig,
  DataObj,
  RenderData,
  renderKey,
  renderValue,
  TranslateFunc,
} from '../utility';

const Column = styled(Box)(() => ({}));

interface MultipleColumnContainerProps {
  columns: ColumnConfig;
  dataObj: DataObj;
  translateFunc: TranslateFunc;
  renderData?: RenderData;
  isBgGrey?: boolean;
  isBorderBottom?: boolean;
}

export const MultipleColumnContainer: React.FC<MultipleColumnContainerProps> = ({
  columns,
  dataObj,
  translateFunc,
  renderData,
  isBgGrey,
  isBorderBottom,
}) => {
  const { palette } = useTheme();

  const columnQty = columns.length;

  const displayKeyAndValue = (key: string) => (
    <Column sx={{ gridTemplateColumns: `repeat(2, 1fr)` }}>
      <Box
        sx={{
          marginRight: 3,
          color: palette.text.lightGrey.main,
        }}
      >
        {renderKey(key, translateFunc)}
      </Box>
      <Box>{renderValue(key, dataObj, renderData)}</Box>
    </Column>
  );

  return (
    <Box
      sx={{
        p: 3,
        display: 'grid',
        gap: 1,
        gridTemplateColumns: `repeat(${columnQty}, 1fr)`,
        backgroundColor: isBgGrey
          ? palette.background.lightGrey.light
          : palette.text.white,
        borderBottom: isBorderBottom ? '1px solid #E0E0E0' : 'none',
      }}
    >
      {columns.map((column) => {
        if (Array.isArray(column)) {
          return column.map((c) => displayKeyAndValue(c));
        } else {
          return displayKeyAndValue(column);
        }
      })}
    </Box>
  );
};
