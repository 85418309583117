import { Box, Dialog, Grid, Typography, useTheme } from '@mui/material';
import AppButton from 'components/appButton';
import { AppDataContainer } from 'components/appDataContainer';
import AppLink from 'components/appLink/appLink';
import { AppTableProps } from 'components/appTable';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import {
  exportTransactionReceipt,
  getRefundData,
  getTransactionLifecycleQuery,
  refundAction,
  useTransactionDetailQuery,
} from 'api/endpoints/transactions';
import { useParams } from 'react-router';
import { useAppSelector } from 'stores/store';
import { MultipleColumnContainer } from 'components/appDataContainer/multipleColumnContainer';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import {
  Refund,
  REVERSAL,
  TransactionDetail,
  TransactionLifecycle,
  TSortModel,
} from 'types/commonTypes';
import { LOCATIONS_URL, TRANSACTIONS_URL } from 'pages/appRoutes/appRoutesConst';
import { ReceiptContainer } from 'components/receiptContainer';
import Replay5Icon from '@mui/icons-material/Replay5';
import {
  RefundTransactionModal,
  TRefundData,
} from 'pages/detailPages/dialogs/refundTransaction';
import { COLORS } from 'theme/colors';
import useAuthentication from 'utils/hooks/useAuthentication';
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LifecycleTable } from 'components/appTable/LifecycleTable';
import SuccessDialog from 'components/Dialog/SuccessDialog';
import ErrorDialog from 'components/Dialog/ErrorDialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { errorHandling } from 'utils/funcs/errorHandling';
import FullscreenLoader from 'components/fullscreenLoader';

const customRenderFunctionsFirst = {
  uuid: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customer: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    const locationId = retypedData?.terminal?.location?.identifier;
    const merchantId = retypedData?.merchant_identifier;
    return (
      <AppLink to={`${LOCATIONS_URL}/${locationId}/${merchantId}`}>
        <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
          {retypedData?.terminal?.location?.name}
        </Typography>
      </AppLink>
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateAndTimeServer: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.date_server}
      </Typography>
    );
  },
  terminal: (value: string) => {
    const retypedValue = value as unknown as TransactionDetail['terminal'];
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedValue?.name}
      </Typography>
    );
  },
  merchant_acquirer: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
  merchant_identifier: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
  terminal_acquirer: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateAndTimeTerminal: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.date_terminal}
      </Typography>
    );
  },
  cin: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
};

const customRenderFunctionsSecond = () => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  amountAndCurrency: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {`${(retypedData?.amount / 100).toFixed(2)} ${retypedData?.currency}`}
      </Typography>
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  approvalCode: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.approval_code}
      </Typography>
    );
  },
  spdh_seq_num: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.spdh_seq_num}
      </Typography>
    );
  },
  card_expiry: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.card_expiry}
      </Typography>
    );
  },
  card_product: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.card_product}
      </Typography>
    );
  },
  card_product_type: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.card_product_type}
      </Typography>
    );
  },
  par: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.par}
      </Typography>
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardIssuer: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.card_issuer}
      </Typography>
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  status: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.status}
      </Typography>
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.type}
      </Typography>
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pan: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.pan}
      </Typography>
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responseCode: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.response_code}
      </Typography>
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rrn: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    return (
      <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
        {retypedData?.rrn}
      </Typography>
    );
  },
  tip: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    if (retypedData?.amount_tip && retypedData?.currency_tip) {
      return (
        <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
          {`${(retypedData?.amount_tip / 100).toFixed(2)} ${retypedData?.currency_tip}`}
        </Typography>
      );
    }
    return <></>;
  },
  cashback: (value: string, data: Record<string, any>) => {
    const retypedData = data as unknown as TransactionDetail;
    if (retypedData?.amount_cashback && retypedData?.currency_cashback) {
      return (
        <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
          {`${(retypedData?.amount_cashback / 100).toFixed(2)} ${
            retypedData?.currency_cashback
          }`}
        </Typography>
      );
    }
    return <></>;
  },
});

export const TransactionDetailPage: React.FC = () => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation('common', { keyPrefix: 'pages.transactionDetailPage' });
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const { networkId } = useAppSelector((state) => state.user);
  const { transactionId, merchantId, locationId, terminalId } = useParams();
  if (!transactionId || !merchantId || !locationId || !terminalId) return <></>;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [queryOptions, setQueryOptions] = React.useState<TSortModel>();
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(' ');
  const [isLoading, setIsLoading] = useState(false);
  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    setQueryOptions(sortModel[0] as TSortModel);
    setPage(0);
  }, []);
  const { data, isLoading: transactionLoading } = useTransactionDetailQuery(
    networkId,
    merchantId,
    locationId,
    terminalId,
    transactionId || '',
  );

  const { data: transactionLifecycle, isLoading: lifecycleLoading } =
    getTransactionLifecycleQuery(
      networkId,
      merchantId,
      locationId,
      terminalId,
      transactionId || '',
      { order_column: queryOptions?.field, order_type: queryOptions?.sort.toUpperCase() },
    );
  const { hasPermission } = useAuthentication();
  const [refundData, setRefundData] = useState<TRefundData>();

  useEffect(() => {
    if (data?.uuid) {
      setIsLoading(true);
      getRefundData(networkId, merchantId, locationId, terminalId, data?.uuid)
        .then((res) => setRefundData({ max_refund_amount: res.max_refund_amount / 100 }))
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, [networkId, merchantId, locationId, terminalId, data]);
  const createCallBack = (refundData: Refund | REVERSAL) => {
    setIsLoading(true);
    refundAction(
      networkId,
      merchantId,
      locationId,
      terminalId,
      data?.uuid ?? '',
      refundData,
    )
      .then(() => {
        setIsRefundModalOpen(false);
        setIsSuccessDialogOpen(true);
        setTimeout(() => {
          setIsSuccessDialogOpen(false);
        }, 2000);
      })
      .catch((err) => {
        if (err.errorMessage) {
          if (err.errorMessage === 'EXCEEDED_ORIGINAL_AMOUNT') {
            setErrorMessage(t('amountError'));
          } else if (err.errorMessage === 'TRANSACTION_ALREADY_REVERSED') {
            setErrorMessage(t('alreadyReversedError'));
          } else {
            setErrorMessage(err.errorMessage);
          }
        } else {
          setErrorMessage(t('refundErrorMessage'));
        }
        setIsErrorDialogOpen(true);
      })
      .finally(() => setIsLoading(false));
  };

  const TRANSACTIONS_LIFECYCLE_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'date_terminal',
        headerName: t('dateAndTime'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TransactionLifecycle>) => {
          const { row } = params;
          return <>{row.date_terminal}</>;
        },
      },
      {
        field: 'uuid',
        headerName: t('uuid'),
        flex: 2,
        renderCell: (params: GridRenderCellParams<TransactionLifecycle>) => {
          const { row } = params;
          return <>{row.uuid}</>;
        },
      },
      {
        field: 'amount',
        headerName: t('amountAndCurrency'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TransactionLifecycle>) => {
          const { row } = params;
          return (
            <Box>
              <Typography sx={{ marginRight: 0.5 }} component="span" fontWeight={'bold'}>
                {(row.amount / 100).toFixed(2)}
              </Typography>
              <Typography component="span">{row.currency}</Typography>
            </Box>
          );
        },
      },
      {
        field: 'status',
        headerName: t('status'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TransactionLifecycle>) => {
          const { row } = params;
          return (
            <AppStatusHighlight status={row.status}>{row.status}</AppStatusHighlight>
          );
        },
      },
      {
        field: 'goToDetail',
        sortable: false,
        headerName: '',
        renderCell: (params: GridRenderCellParams<TransactionLifecycle>) => {
          const { row } = params;
          return (
            <>
              {
                <AppLink
                  to={`${TRANSACTIONS_URL}/${row.uuid}/${row.merchant_identifier}/${row.location_identifier}/${row.terminal_identifier}`}
                  isGreyLink
                >
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [resolvedLanguage],
  );
  const loading = isLoading || transactionLoading;

  if (loading) return <FullscreenLoader />;

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Box>
        <AppBreadcrumbs
          customBreadCrumbs={[{ index: 1, breadCrumbLabel: t('breadCrumb'), route: '/' }]}
        />
      </Box>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: isTablet ? 'space-around' : 'start',
          gap: 2,
        }}
      >
        <Grid item xs={12} md={7}>
          <AppDataContainer
            titleRow={{
              title: t('pageTitle'),
              buttons: [
                <AppButton
                  key="RefundButton"
                  variant="contained"
                  hideButton={!hasPermission('refund')}
                  onClick={() => setIsRefundModalOpen(true)}
                  sx={{
                    backgroundColor: COLORS.LIGHT_BLUE.LIGHT,
                    color: COLORS.DARK_BLUE.MAIN,
                    fontSize: '0.9rem',
                    fontWeight: 500,
                    padding: theme.spacing(2),
                    ':hover': {
                      backgroundColor: COLORS.DARK_BLUE.LIGHT,
                    },
                    gap: 1,
                  }}
                >
                  <Replay5Icon />
                  {t('refundButton')}
                </AppButton>,
              ],
            }}
            sx={{ maxWidth: '100%' }}
          >
            <MultipleColumnContainer
              renderData={customRenderFunctionsFirst}
              columns={[
                ['uuid', 'customer', 'merchant_acquirer', 'merchant_identifier'],
                [
                  'dateAndTimeServer',
                  'terminal',
                  'dateAndTimeTerminal',
                  'terminal_acquirer',
                  'cin',
                ],
              ]}
              dataObj={data ?? {}}
              translateFunc={(k: string): string => {
                return t(k);
              }}
              isBgGrey
              isBorderBottom
            />
            <MultipleColumnContainer
              renderData={customRenderFunctionsSecond()}
              columns={[
                ['amountAndCurrency', 'approvalCode', 'cardIssuer'],
                [
                  'spdh_seq_num',
                  'status',
                  'type',
                  'responseCode',
                  'rrn',
                  'card_expiry',
                  'card_product',
                  'card_product_type',
                  'pan',
                  'par',
                  'tip',
                  'cashback',
                ],
              ]}
              dataObj={data ?? {}}
              translateFunc={(k: string): string => {
                return t(k);
              }}
            />
          </AppDataContainer>
        </Grid>
        <Grid item xs={12} md={4.5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: isTablet ? 'space-around' : 'flex-start',
            }}
          >
            {data && <ReceiptContainer data={data} />}
            <AppButton
              sx={{ marginTop: 2 }}
              onClick={async () =>
                await exportTransactionReceipt(
                  networkId,
                  merchantId,
                  locationId,
                  terminalId,
                  transactionId || '',
                )
              }
              variant="squared"
            >
              {t('downloadReceipt')}
            </AppButton>
          </Box>
        </Grid>
      </Grid>
      <LifecycleTable
        loading={lifecycleLoading}
        page={page}
        isNextPage={transactionLifecycle?.paging.nextPage ?? false}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        handleSortModelChange={handleSortModelChange}
        rows={transactionLifecycle?.data ?? []}
        columns={TRANSACTIONS_LIFECYCLE_COLUMNS}
        title={t('lifecycleTable')}
      />
      <Dialog open={isRefundModalOpen} fullWidth={true} maxWidth={'xs'}>
        <RefundTransactionModal
          handleClose={() => setIsRefundModalOpen(false)}
          uuid={data?.uuid}
          terminalDate={data?.date_terminal}
          createCallBack={createCallBack}
          refundData={refundData as TRefundData}
        />
      </Dialog>
      <SuccessDialog
        isDialogOpen={isSuccessDialogOpen}
        handleClose={() => setIsSuccessDialogOpen(false)}
        message={t('refundSuccessMessage')}
      />
      <ErrorDialog
        isDialogOpen={isErrorDialogOpen}
        handleClose={() => setIsErrorDialogOpen(false)}
        message={errorMessage}
      />
    </Grid>
  );
};
