import { alpha, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { typographyThemeOptions } from './typographyThemeOptions';
import { COLORS } from './colors';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    terniary: { main: string };
    border: {
      darkGrey: string;
      lightGrey: string;
      secondary: string;
    };
    disabled: { lightGrey: string };
  }

  interface TypeText {
    yellow: {
      main: string;
    };
    lightGrey: { dark: string; main: string };
    darkGrey: { main: string };
    black: { main: string };
    white: { main: string };
    darkBlue: { main: string };
  }

  interface TypeBackground {
    lightGrey: {
      light: string;
      main: string;
      dark: string;
    };
  }

  interface PaletteOptions {
    terniary: { main: string };
    border: {
      darkGrey: string;
      lightGrey: string;
      secondary: string;
    };
    disabled: { lightGrey: string };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    squared: true;
  }
}

let theme = createTheme({
  typography: typographyThemeOptions,
  palette: {
    primary: {
      light: COLORS.LIGHT_BLUE.EXTRA_LIGHT,
      main: COLORS.LIGHT_BLUE.LIGHT,
      dark: COLORS.LIGHT_BLUE.MAIN,
    },
    secondary: {
      light: COLORS.DARK_BLUE.LIGHT,
      main: COLORS.DARK_BLUE.MAIN,
    },
    terniary: {
      main: COLORS.ORANGE.MAIN,
    },
    disabled: { lightGrey: COLORS.LIGHT_GREY.MAIN },
    success: {
      light: COLORS.LIGHT_GREEN.LIGHT,
      main: COLORS.LIGHT_GREEN.MAIN,
    },
    error: {
      light: COLORS.RED.LIGHT,
      main: COLORS.RED.MAIN,
    },
    background: {
      lightGrey: {
        light: COLORS.LIGHT_GREY.LIGHT,
        main: COLORS.LIGHT_GREY.MAIN,
        dark: COLORS.LIGHT_GREY.DARK,
      },
    },
    text: {
      yellow: {
        main: COLORS.YELLOW.MAIN,
      },
      lightGrey: {
        main: COLORS.GREY.MAIN,
        dark: COLORS.DARK_BLUE.MAIN,
      },
      black: { main: COLORS.BLACK },
      white: { main: COLORS.WHITE },
      darkBlue: { main: COLORS.DARK_BLUE.MAIN },
      darkGrey: { main: COLORS.GREY.MAIN },
    },
    border: {
      lightGrey: COLORS.LIGHT_GREY.MAIN,
      darkGrey: COLORS.LIGHT_GREY.DARK,
      secondary: COLORS.DARK_BLUE.MAIN,
    },
  },
});

// Theme options dependent on another theme options.
theme = createTheme(theme, {
  components: {
    ...theme.components,
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            color: theme.palette.text.darkGrey.main,
          },
          '& .MuiDataGrid-row:nth-of-type(even)': {
            backgroundColor: theme.palette.background.lightGrey.main,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.light,
            '&.Mui-focusVisible': {
              backgroundColor: theme.palette.primary.light,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1.5, 2),
          '&.MuiButton-sizeSmall': {
            padding: theme.spacing(0.5, 1),
          },
        },
      },
      variants: [
        {
          props: { variant: 'text', color: 'secondary' },
          style: {
            '&.MuiButton-root': {
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.primary.light,
              ':hover': {
                backgroundColor: theme.palette.primary.main,
              },
              ':active': {
                backgroundColor: theme.palette.primary.dark,
              },
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            '&.Mui-disabled': {
              backgroundColor: theme.palette.disabled.lightGrey,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            '&.MuiButton-root': {
              color: theme.palette.secondary.main,
              boxShadow: 'none',
              backgroundColor: theme.palette.primary.light,
              '&.Mui-disabled': {
                backgroundColor: theme.palette.disabled.lightGrey,
              },
              ':hover': {
                backgroundColor: theme.palette.primary.main,
              },
              ':active': {
                backgroundColor: theme.palette.primary.dark,
              },
            },
          },
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            '&.MuiButton-root': {
              color: theme.palette.error.main,
              backgroundColor: theme.palette.error.light,
              boxShadow: 'none',
              '&.Mui-disabled': {
                backgroundColor: theme.palette.disabled.lightGrey,
              },
              ':hover': {
                backgroundColor: alpha(theme.palette.error.main, 0.3),
              },
              ':active': {
                backgroundColor: alpha(theme.palette.error.main, 0.5),
              },
            },
          },
        },
        {
          props: { variant: 'squared' },
          style: {
            '&.MuiButton-root': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.text.darkBlue.main,
              ':hover': {
                backgroundColor: alpha(theme.palette.text.darkBlue.main, 0.8),
              },
            },
          },
        },
      ],
    },
  },
});

export const dataGridTheme = createTheme({
  typography: typographyThemeOptions,
  palette: {
    ...theme.palette,
    primary: {
      main: COLORS.DARK_BLUE.LIGHT,
    },
  },
});

theme = responsiveFontSizes(theme, { disableAlign: false });

export { theme };
