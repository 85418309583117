import { AppDonutChart } from 'components/charts/donutChart';
import { AppPieChart } from 'components/charts/pieChart';
import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CardsWidget: React.FC<{ data: any }> = ({ data }) => {
  const dataFormated = useMemo(() => {
    return Object.entries(data).map((entry) => ({
      name: entry[0],
      value: entry[1] as number,
    }));
  }, [data]);
  return (
    <>
      {dataFormated.length > 6 ? (
        <AppPieChart data={dataFormated} />
      ) : (
        <AppDonutChart data={dataFormated} />
      )}
    </>
  );
};
