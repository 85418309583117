import React, { useEffect, useMemo, useState } from 'react';
import { Card, Box, Tab, useTheme, Typography, styled } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { AppBreadcrumbs } from '../components/breadcrumbs/BreadCrumbs';
import {
  readAllNotificationsFromUser,
  readSingleNotification,
  useNotificationCollectionsQuery,
} from '../api/endpoints/notifications';
import { areDatesEqual, isTodayDate } from '../utils/funcs/formatDate';
import { Notification } from '../types/commonTypes';
import AppButton from '../components/appButton';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useTranslation } from 'react-i18next';
import { formatToBeforeDays } from '../utils/funcs/formatDate';
import { QueryCache } from 'react-query';

const queryCache = new QueryCache({});

interface TAB {
  value: string;
  label: string;
}

const ReadMarker = styled('div')(({ theme: { palette } }) => ({
  backgroundColor: palette.primary.dark,
  width: '16px',
  height: '16px',
  borderRadius: '50%',
}));

const TypographyWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(1),
}));

const NotificationItemWrapper = styled('div')(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: spacing(4, 3),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: palette.primary.main,
  },
  transition: ' 0.3s',
}));

const NoMessages = styled('div')(({ theme: { spacing, typography, palette } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: spacing(3),
  fontWeight: typography.fontWeightBold,
  color: palette.text.darkBlue.main,
}));

const NotificationItem: React.FC<{ item: Notification }> = ({ item }) => {
  const { content } = item;
  const [markRead, setMarkRead] = useState(item);
  const readOneNotification = async (notificationId: number) => {
    const response = await readSingleNotification(notificationId);
    if (response.status > 199 && response.status < 300) {
      setMarkRead((item) => ({
        ...item,
        read: true,
      }));
    }
  };
  const makeNotificationRead = async () => {
    await readOneNotification(item.id);
  };
  const theme = useTheme();

  return (
    <NotificationItemWrapper onClick={() => makeNotificationRead()}>
      <TypographyWrapper>
        <Typography
          color="text.darkGrey.main"
          fontSize={theme.spacing(1.75)}
          fontWeight="bold"
        >
          {content}
        </Typography>
      </TypographyWrapper>
      {!markRead.read && (
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
          <ReadMarker />
        </Box>
      )}
    </NotificationItemWrapper>
  );
};

export const NotificationsPage: React.FC = () => {
  const [tabSwitch, setTabSwitch] = React.useState('1');
  const [triggerNotificationFetch, setTriggerNotificationFetch] = useState(0);
  const { data } = useNotificationCollectionsQuery(triggerNotificationFetch);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTriggerNotificationFetch(triggerNotificationFetch + 1);
    setTabSwitch(newValue);
  };

  const readAllNotifications = async () => {
    const response = await readAllNotificationsFromUser();
    if (response.status > 199 && response.status < 300) {
      setTriggerNotificationFetch(triggerNotificationFetch + 1);
    }
  };

  useEffect(() => {
    return queryCache.clear();
  }, []);

  const { t } = useTranslation('common');
  const theme = useTheme();
  let currentDateCreated: Date | string;

  const TAB_CONFIG: Array<TAB> = [
    {
      value: '1',
      label: 'pages.notificationPage.all',
    },
    {
      value: '2',
      label: 'pages.notificationPage.unread',
    },
  ];

  const sortedNotificationsByDate = useMemo(() => {
    const sortedNotifications = data?.data.sort((a, b) => {
      return new Date(b.date_created).getTime() - new Date(a.date_created).getTime();
    });
    if (tabSwitch === '2') {
      return sortedNotifications?.filter((notification) => !notification.read);
    } else {
      return sortedNotifications;
    }
  }, [data?.data, tabSwitch]);

  const sortedNotificationsFromNewestToLatest = useMemo(() => {
    return sortedNotificationsByDate?.map((notification, index) => {
      if (areDatesEqual(notification.date_created, currentDateCreated, 'day')) {
        return <NotificationItem item={notification} key={index} />;
      } else {
        currentDateCreated = notification.date_created;
        return (
          <React.Fragment key={index}>
            <Typography
              color={theme.palette.text.darkBlue.main}
              fontWeight={theme.typography.fontWeightBold}
              fontSize={theme.spacing(2.5)}
              sx={{
                padding: theme.spacing(5, 3, 0, 3),
                '&::first-letter': { textTransform: 'capitalize' },
              }}
            >
              {isTodayDate(notification.date_created)
                ? t('enums.dayOptions.today')
                : formatToBeforeDays(notification.date_created)}
            </Typography>
            <NotificationItem item={notification} />
          </React.Fragment>
        );
      }
    });
  }, [sortedNotificationsByDate]);

  return (
    <>
      <AppBreadcrumbs />
      <Card sx={{ width: '100%', marginTop: 2 }}>
        <TabContext value={tabSwitch}>
          <div>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <TabList onChange={handleChange}>
                {TAB_CONFIG.map((tabValue, index) => (
                  <Tab label={t(tabValue.label)} value={tabValue.value} key={index} />
                ))}
              </TabList>
              <Box>
                <AppButton
                  sx={{ margin: 2 }}
                  size="small"
                  startIcon={<CheckCircleRoundedIcon />}
                  color="secondary"
                  onClick={() => readAllNotifications()}
                  disabled={data?.data.length === 0}
                >
                  {t('pages.notificationPage.markAllRead')}
                </AppButton>
              </Box>
            </Box>
          </div>
          {TAB_CONFIG.map((tabValue, index) => (
            <TabPanel key={index} value={tabValue.value} sx={{ padding: 0 }}>
              {data?.data.length === 0 ? (
                <NoMessages>{t('pages.notificationPage.noMessages')}</NoMessages>
              ) : (
                sortedNotificationsFromNewestToLatest
              )}
            </TabPanel>
          ))}
        </TabContext>
      </Card>
    </>
  );
};
