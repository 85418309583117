import { api } from 'api';
import { useQuery } from 'react-query';
import { Location, LocationIdentifier, LocationType, UserRole } from 'types/commonTypes';
import { getNotEmptyParams } from 'utils/funcs/convert';
import { FileType, getFile } from 'utils/funcs/downloadFile';
import { ApiResponse } from '../../types/utilityInterfaces';

export interface LocationsFilter {
  search: string;
  order_type: 'ASC' | 'DESC';
  order_column: string;
}

export const getUserLocations = async (
  networkId: string,
  params: Partial<LocationsFilter>,
) => {
  const notEmptyParams = getNotEmptyParams(params);
  const response = await api.get<ApiResponse<Location[]>>(
    `/networks/${networkId}/locations`,
    {
      params: notEmptyParams,
    },
  );
  return response.data;
};
export const getLocationsIdentifiers = async (networkId: string) => {
  const response = await api.get<LocationIdentifier[]>(
    `/networks/${networkId}/locations/identifiers`,
  );
  return response.data;
};

export const useGetUserLocationsQuery = (
  networkId: string,
  currentRole: UserRole,
  filterParams = {},
) =>
  useQuery(['getUserLocations', currentRole, filterParams], () =>
    getUserLocations(networkId, filterParams),
  );
export const useGetLocationsIdentifiersQuery = (networkId: string) =>
  useQuery(['getLocationsIdentifiers', networkId], () =>
    getLocationsIdentifiers(networkId),
  );

const getLocationDetail = async (
  networkId: string,
  merchantId: string,
  locationId: string,
) => {
  const response = await api.get<LocationType>(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}`,
  );
  return response.data;
};

export const useLocationDetailQuery = (
  networkId: string,
  merchantId: string,
  locationId: string,
) =>
  useQuery(
    ['getTerminalDetail', networkId, merchantId, locationId],
    () => getLocationDetail(networkId, merchantId, locationId),
    {
      staleTime: 0,
    },
  );

export const exportLocationData = async (
  networkId: string,
  type: FileType,
  filterParams = {},
) => {
  const notEmptyParams = getNotEmptyParams(filterParams);
  return await getFile(
    `/networks/${networkId}/locations/export/${type}`,
    type,
    'Locations',
    notEmptyParams,
  );
};

export const getCurrencies = async (networkId: string, body: string[]) => {
  const response = await api.post<string[]>(
    `/networks/${networkId}/locations/currencies`,
    body,
  );
  return response;
};

export const getCurrenciesQuery = (networkId: string, locations: string[]) =>
  useQuery(['getCurrencies', networkId, locations], () =>
    getCurrencies(networkId, locations),
  );
