import {
  DialogTitle,
  Typography,
  IconButton,
  Box,
  useTheme,
  MenuItem,
  styled,
} from '@mui/material';
import AppButton from 'components/appButton';
import AppTextField from 'components/form/textField';
import CloseIcon from '@mui/icons-material/Close';
import { createWidget } from 'api/endpoints/widgets';
import AppSelect from 'components/form/select';
import { useTranslation } from 'react-i18next';
import { CardView, TimePeriod, WidgetTypes } from 'types/enumTypes';
import { COLORS } from 'theme/colors';
import { getCurrencies, useGetLocationsIdentifiersQuery } from 'api/endpoints/locations';
import { useAppSelector } from 'stores/store';
import { LocationSelect } from 'components/form/locationSelect';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import FormController from 'components/formController';
import { Widget } from 'types/commonTypes';
import React, { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { errorHandling } from 'utils/funcs/errorHandling';
import WidgetLoader from 'components/widgetLoader';
import { delayedCallback } from 'utils/funcs/delayedCallback';

const DEFAULT_WIDGET_POSITION = { x: 0, y: 999, w: 10, h: 10 };

interface CreateWidgetProps {
  handleClose: () => void;
  createCallBack?: () => void;
}

const TYPE = 'type';
const CARDS = 'card_view';
const TIME_PERIOD = 'time_period';
const CURRENCY = 'currency';
const LOCATIONS = 'locations';
const NAME = 'name';

type FormValues = {
  [TYPE]: WidgetTypes;
  [CARDS]: CardView;
  [TIME_PERIOD]: TimePeriod;
  [CURRENCY]: string;
  [LOCATIONS]: string[];
  [NAME]: string;
};

export const validTimePeriodTypes = [
  WidgetTypes['NETWORK_LOAD'],
  WidgetTypes['STATES_OF_TRANSACTIONS'],
  WidgetTypes['ACTIVITY_OVERVIEW'],
  WidgetTypes['CARDS'],
];

export const validCurrencyTypes = [
  WidgetTypes['LATEST_TRANSACTIONS'],
  WidgetTypes['STATES_OF_TRANSACTIONS'],
  WidgetTypes['ACTIVITY_OVERVIEW'],
];

export const CreateWidget: React.FC<CreateWidgetProps> = ({
  handleClose,
  createCallBack,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.createWidget' });
  const { t: tEnum } = useTranslation('common', { keyPrefix: 'enums' });
  const { t: tValidation } = useTranslation('', {
    keyPrefix: 'validation',
  });
  const { networkId } = useAppSelector((state) => state.user);
  const { data: locationsIdentifiers, isLoading: loadingLoationsId } =
    useGetLocationsIdentifiersQuery(networkId);
  const [currencies, setCurrencies] = useState<AxiosResponse<string[]>>();
  const [isLoading, setIsLoading] = useState(false);
  const creatingWidget = useRef(false);
  const defaultValues = {
    [TYPE]: WidgetTypes['LATEST_TRANSACTIONS'],
    [NAME]: '',
    settings: {
      [LOCATIONS]: [],
    },
  };

  const schema = Joi.object({
    [TYPE]: Joi.string()
      .trim()
      .required()
      .messages({
        'any.required': tValidation('required'),
        'string.empty': tValidation('required'),
      }),
    [CARDS]: Joi.string().when(TYPE, {
      is: Joi.string().valid(WidgetTypes['CARDS']),
      then: Joi.string()
        .trim()
        .required()
        .messages({
          'any.required': tValidation('required'),
          'string.empty': tValidation('required'),
        }),
      otherwise: Joi.optional(),
    }),
    [TIME_PERIOD]: Joi.string().when(TYPE, {
      is: Joi.valid(
        WidgetTypes['STATES_OF_TRANSACTIONS'],
        WidgetTypes['ACTIVITY_OVERVIEW'],
        WidgetTypes['NETWORK_LOAD'],
        WidgetTypes['CARDS'],
      ),
      then: Joi.string()
        .trim()
        .required()
        .messages({
          'any.required': tValidation('required'),
          'string.empty': tValidation('required'),
        }),
      otherwise: Joi.optional(),
    }),
    [CURRENCY]: Joi.string().when(TYPE, {
      is: Joi.valid(
        WidgetTypes['LATEST_TRANSACTIONS'],
        WidgetTypes['STATES_OF_TRANSACTIONS'],
        WidgetTypes['ACTIVITY_OVERVIEW'],
      ),
      then: Joi.string()
        .trim()
        .required()
        .messages({
          'any.required': tValidation('required'),
          'string.empty': tValidation('required'),
        }),
    }),

    [LOCATIONS]: Joi.array()
      .min(1)
      .required()
      .messages({
        'any.required': tValidation('required'),
        'array.empty': tValidation('required'),
        'array.min': tValidation('required'),
      }),
    [NAME]: Joi.string().trim().allow(''),
  }).options({ allowUnknown: true });

  const form = useForm<FormValues>({
    resolver: joiResolver(schema),
    defaultValues: defaultValues,
    mode: 'onBlur',
  });

  const { control, handleSubmit, watch } = form;

  const locations = watch(LOCATIONS);
  const widgetType = watch(TYPE);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCurrencies(networkId, locations);
        setCurrencies(data);
      } catch (error) {
        errorHandling(error);
      }
    };
    if (locations?.length) fetchData();
  }, [networkId, locations]);

  const theme = useTheme();
  const { spacing } = theme;

  const StyledMenuItem = styled(MenuItem)(({ theme: { palette } }) => ({
    fontSize: '0.9rem',
    fontWeight: 400,
    color: COLORS.GREY.MAIN,
    '&:hover': {
      backgroundColor: palette.background.lightGrey,
    },
  }));

  const createW = async (formData: FormValues) => {
    // Prevent double click
    if (creatingWidget.current) {
      return;
    }
    creatingWidget.current = true;
    setIsLoading(true);
    const updatedState: Omit<Widget, 'id' | 'data'> = {
      ...formData,
      position: DEFAULT_WIDGET_POSITION,
      settings: {
        time_period: formData.time_period,
        currency: formData.currency,
        card_view: formData.card_view,
        locations: formData.locations,
      },
    };

    if (
      updatedState.type === WidgetTypes['LATEST_TRANSACTIONS']
      // updatedState.type === WidgetTypes['TERMINALS']
      // updatedState.type === WidgetTypes['TERMINAL_NOTIFICATIONS']
    ) {
      delete updatedState.settings.time_period;
    }
    if (
      updatedState.type === WidgetTypes['NETWORK_LOAD'] ||
      updatedState.type === WidgetTypes['CARDS']
      // updatedState.type === WidgetTypes['TERMINALS']
      // updatedState.type === WidgetTypes['TERMINAL_NOTIFICATIONS']
    ) {
      delete updatedState.settings.currency;
    }
    if (updatedState.type !== WidgetTypes['CARDS']) {
      delete updatedState.settings.card_view;
    }
    if (updatedState.settings.locations.length < 1) return;

    try {
      await createWidget(updatedState);
      if (createCallBack) createCallBack();
      handleClose();
    } catch (error) {
      errorHandling(error);
    } finally {
      setIsLoading(false);
      creatingWidget.current = false;
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: isLoading || loadingLoationsId ? 'hidden' : 'auto',
      }}
    >
      {(isLoading || loadingLoationsId) && <WidgetLoader />}
      <form onSubmit={handleSubmit(delayedCallback(createW, 300))}>
        {' '}
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${COLORS.LIGHT_GREY.DARK}`,
          }}
        >
          <Typography variant="h6" fontWeight={700}>
            {t('widgetTitle')}
          </Typography>
          <IconButton key="Settings" onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box
          sx={{
            padding: spacing(4, 3),
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <FormController name={TYPE} control={control}>
            <AppSelect fullWidth label={t('chooseWidgetType')} required>
              {Object.entries(WidgetTypes).map((entry, index) => (
                <StyledMenuItem key={index} value={entry[1]}>
                  {tEnum(`widgetTypes.${entry[1]}`)}
                </StyledMenuItem>
              ))}
            </AppSelect>
          </FormController>
          {widgetType === WidgetTypes['CARDS'] && (
            <FormController name={CARDS} control={control}>
              <AppSelect fullWidth label={t('card_view')} required>
                {Object.entries(CardView).map((entry, index) => (
                  <StyledMenuItem key={index} value={entry[1]}>
                    {entry[1]}
                  </StyledMenuItem>
                ))}
              </AppSelect>
            </FormController>
          )}
          {validTimePeriodTypes.includes(widgetType) && (
            <FormController name={TIME_PERIOD} control={control}>
              <AppSelect fullWidth label={t('chooseTimePeriod')} required>
                {Object.entries(TimePeriod).map((entry, index) => (
                  <StyledMenuItem key={index} value={entry[1]}>
                    {tEnum(`timePeriod.${entry[1]}`)}
                  </StyledMenuItem>
                ))}
              </AppSelect>
            </FormController>
          )}

          <FormController name={LOCATIONS} control={control}>
            <LocationSelect
              label={t('chooseLocations')}
              locations={locationsIdentifiers || []}
              required
            />
          </FormController>

          {validCurrencyTypes.includes(widgetType) && locations?.length > 0 && (
            <FormController name={CURRENCY} control={control}>
              <AppSelect
                disabled={locations?.length < 1}
                fullWidth
                label={t('currency')}
                required
              >
                {currencies?.data.map((entry, index) => (
                  <StyledMenuItem key={index} value={entry}>
                    {entry}
                  </StyledMenuItem>
                ))}
              </AppSelect>
            </FormController>
          )}
          <FormController name={NAME} control={control}>
            <AppTextField label={t('customName')} />
          </FormController>
          <Box sx={{ alignSelf: 'end' }}>
            <AppButton
              disabled={isLoading}
              sx={{
                backgroundColor: COLORS.LIGHT_BLUE.LIGHT,
                color: COLORS.DARK_BLUE.MAIN,
                fontSize: '0.9rem',
                fontWeight: 500,
                padding: theme.spacing(2),
                ':hover': {
                  backgroundColor: COLORS.DARK_BLUE.LIGHT,
                },
              }}
              type="submit"
              variant="contained"
            >
              {t('confirm')}
            </AppButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
