import { Box, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRevenueOverviewWidget } from 'types/commonTypes';

const ImportantDataBoxColored = styled('div')(({ theme: { spacing, palette } }) => ({
  marginBottom: spacing(3),
  backgroundColor: palette.primary.light,
  padding: spacing(1, 2),
  borderRadius: '10px',
  width: '100%',
}));

const ImportantDataBox = styled('div')(({ theme: { spacing } }) => ({
  marginBottom: spacing(3),
}));

const ImportantDataBoxSmallText = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.lightGrey.main,
}));

const ImportantDataBoxLargeText = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.h3,
}));

export const RevenueOverviewWidget: React.FC<{
  data: TRevenueOverviewWidget['data'];
}> = ({ data }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.revenueOverview' });
  const convertAmount = (amount: number) => (amount / 100).toFixed(2);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 3,
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ marginY: 'auto', flex: 1, display: 'flex', flexDirection: 'column' }}>
        <ImportantDataBox>
          <ImportantDataBoxLargeText>{`${convertAmount(data?.amount_total ?? 0)} ${
            data?.currency ?? ''
          }`}</ImportantDataBoxLargeText>
          <ImportantDataBoxSmallText>{t('amountTotal')}</ImportantDataBoxSmallText>
        </ImportantDataBox>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
          <ImportantDataBoxColored>
            <ImportantDataBoxLargeText>
              {data?.transactions_total}
            </ImportantDataBoxLargeText>
            <ImportantDataBoxSmallText>
              {t('transactionsTotal')}
            </ImportantDataBoxSmallText>
          </ImportantDataBoxColored>
          <ImportantDataBoxColored>
            <ImportantDataBoxLargeText>
              {`${convertAmount(data?.average_amount ?? 0)} ${data?.currency ?? ''}`}
            </ImportantDataBoxLargeText>
            <ImportantDataBoxSmallText>
              {t('transactionsAverage')}
            </ImportantDataBoxSmallText>
          </ImportantDataBoxColored>
        </Box>
      </Box>
      {/*<Box sx={{ width: '400px' }}>*/}
      {/*  {data?.data && data?.data.length > 0 && (*/}
      {/*    <DataGraphContainer*/}
      {/*      yAxisUnit={` ${data?.currency}`}*/}
      {/*      dataBoxes={data.data.map((graphItem) => ({*/}
      {/*        value: graphItem.amount,*/}
      {/*        // label: formatDateToDay(graphItem.date, 'DD.MM.YYYY', 'YYYY-MM-DD'),*/}
      {/*        label: graphItem.date,*/}
      {/*      }))}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</Box>*/}
    </Box>
  );
};
