import { Box, Typography } from '@mui/material';
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro';
import { useTerminalDetailQuery } from 'api/endpoints/terminals';
import { useTransactionsCollectionsQuery } from 'api/endpoints/transactions';
import AppButton from 'components/appButton';
import { AppDataContainer } from 'components/appDataContainer';
import { MultipleColumnContainer } from 'components/appDataContainer/multipleColumnContainer';
import { TranslateFunc, DataObj } from 'components/appDataContainer/utility';
import AppLink from 'components/appLink/appLink';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import { AppTableProps } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { RevenueOverview } from 'components/revenueOverview';
import { LOCATIONS_URL, TRANSACTIONS_URL } from 'pages/appRoutes/appRoutesConst';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'stores/store';
import { TerminalDetail, TransactionDetail, TSortModel } from 'types/commonTypes';
import { formatDateToDay } from 'utils/funcs/formatDate';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TerminalStatus } from 'types/enumTypes';
import { TerminalStatusComponent } from 'components/terminalStatus';
import { useParams } from 'react-router';
import useAuthentication from 'utils/hooks/useAuthentication';
import { TransactionsTable } from 'components/appTable/TransactionsTable';
import * as React from 'react';
import FullscreenLoader from 'components/fullscreenLoader';

const locationCustomRenderFunctions = () => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  name: (key: string, data: Record<string, any>) => {
    const retypedData = data as TerminalDetail;
    return retypedData.location.name;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  address: (key: string, data: Record<string, any>) => {
    const retypedData = data as TerminalDetail;
    return (
      <>
        <p>{retypedData.location.street}</p>
        <p>{retypedData.location.city}</p>
        <p>{retypedData.location.zip_code}</p>
      </>
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  placeCode: (key: string, data: Record<string, any>) => {
    const retypedData = data as TerminalDetail;
    return retypedData.location.zip_code;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  status: () => {
    return ``;
  },
});

const customRenderFunctions = (translateFunc: TranslateFunc) => ({
  status: (key: string) => (
    <TerminalStatusComponent status={key?.toUpperCase() as TerminalStatus}>
      {translateFunc(`transactionStatus.${key}`)}
    </TerminalStatusComponent>
  ),
  gps: (key: string, data: DataObj) => <>{`${data?.gps?.lat}, ${data?.gps?.lng}`}</>,
});

export const TerminalDetailPage: React.FC = () => {
  const { networkId } = useAppSelector((state) => state.user);
  const { locationId, merchantId, terminalId } = useParams();
  if (!merchantId || !locationId || !terminalId) {
    return <></>;
  }

  const { data, isLoading: terminalLoading } = useTerminalDetailQuery(
    networkId,
    merchantId,
    locationId,
    terminalId || '',
  );
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation('common', { keyPrefix: 'pages.terminalDetailPage' });
  const { t: tEnums } = useTranslation('common', { keyPrefix: 'enums' });
  const { currentRole } = useAuthentication();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [queryOptions, setQueryOptions] = React.useState<TSortModel>({
    field: 'date_terminal',
    sort: 'DESC',
  });
  const { data: transactionsData, isLoading: transactionLoading } =
    useTransactionsCollectionsQuery(networkId, currentRole, {
      terminal: terminalId,
      limit: pageSize,
      offset: page * pageSize,
      order_column: queryOptions?.field,
      order_type: queryOptions?.sort.toUpperCase(),
    });
  // const markers: MarkerInterface[] = useMemo(
  //   () => [
  //     {
  //       lat: parseFloat(data?.location.gps_lat || '0'),
  //       lng: parseFloat(data?.location?.gps_lng || '0'),
  //     },
  //   ],
  //   [data],
  // );

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    setQueryOptions(sortModel[0] as TSortModel);
    setPage(1);
  }, []);

  const TRANSACTION_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'date_terminal',
        headerName: t('latestTransactions.date'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TransactionDetail>) => {
          const { row } = params;
          return (
            <div>
              <Typography component="div" fontWeight={'bold'}>
                {formatDateToDay(row.date_terminal, 'DD.MM.YYYY')}
              </Typography>
              <Typography component="div">
                {formatDateToDay(row.date_terminal, 'HH:mm:ss')}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'amount',
        headerName: t('latestTransactions.amountAndCurrency'),
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<TransactionDetail, TransactionDetail>,
        ) => {
          const { row } = params;
          return (
            <Box>
              <Typography sx={{ marginRight: 0.5 }} component="span" fontWeight={'bold'}>
                {(row.amount / 100).toFixed(2)}
              </Typography>
              <Typography component="span">{row.currency}</Typography>
            </Box>
          );
        },
      },
      {
        field: 'status',
        headerName: t('latestTransactions.status'),
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<TransactionDetail, TransactionDetail>,
        ) => {
          const { row } = params;

          return (
            <AppStatusHighlight status={row.status}>{row.status}</AppStatusHighlight>
          );
        },
      },
      {
        field: 'terminal_identifier',
        headerName: t('latestTransactions.terminalId'),
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<TransactionDetail, TransactionDetail>,
        ) => {
          const { row } = params;
          return <Typography>{row.terminal_identifier}</Typography>;
        },
      },
      {
        field: 'goToDetail',
        sortable: false,
        headerName: '',
        renderCell: (params: GridRenderCellParams<TransactionDetail>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink
                  to={`${TRANSACTIONS_URL}/${row.id}/${row.merchant_identifier}/${row.location_identifier}/${row.terminal_identifier}`}
                  isGreyLink
                >
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [resolvedLanguage],
  );

  if (!data) return <></>;

  if (terminalLoading) return <FullscreenLoader />;
  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <AppBreadcrumbs
          customBreadCrumbs={[
            { index: 1, breadCrumbLabel: data?.name || '', route: '/' },
          ]}
        />
      </Box>
      <Box sx={{ marginBottom: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <AppDataContainer
          sx={{ marginBottom: 3 }}
          titleRow={{
            title: t('pageTitle'),
            // NOT NEEDED RIGTH NOW
            // buttons: [
            //   <AppButton
            //     variant="contained"
            //     color="error"
            //     size="small"
            //     startIcon={<DeleteIcon />}
            //     key={0}
            //   >
            //     {t('deleteTerminal')}
            //   </AppButton>,
            //   <AppButton
            //     variant="contained"
            //     color="secondary"
            //     size="small"
            //     startIcon={<EditIcon />}
            //     key={1}
            //   >
            //     {t('editTerminal')}
            //   </AppButton>,
            // ],
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <MultipleColumnContainer
              renderData={customRenderFunctions(tEnums)}
              columns={[['identifier']]}
              dataObj={data ?? {}}
              translateFunc={(k: string): string => {
                return t(k);
              }}
            />
          </Box>
        </AppDataContainer>
        <RevenueOverview
          dataDirection="column"
          terminalId={terminalId}
          locationId={locationId}
          endpoint="terminals"
          propsData={data}
        />
        <TransactionsTable
          isLoading={transactionLoading}
          page={page}
          isNextPage={transactionsData?.paging.nextPage ?? false}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          rows={transactionsData?.data ?? []}
          columns={TRANSACTION_COLUMNS}
          appTitle={t('latestTransactions.tableTitle')}
          showSettings={false}
          rowCount={transactionsData?.paging.count ?? 0}
          handleSortModelChange={handleSortModelChange}
        />
        <AppDataContainer
          sx={{ marginBottom: 3 }}
          titleRow={{
            title: t('locationTitle'),
            // NOT NEEDED RIGTH NOW
            // buttons: [
            //   <AppButton
            //     variant="contained"
            //     color="error"
            //     size="small"
            //     startIcon={<DeleteIcon />}
            //     key={0}
            //   >
            //     {t('deleteTerminal')}
            //   </AppButton>,
            //   <AppButton
            //     variant="contained"
            //     color="secondary"
            //     size="small"
            //     startIcon={<EditIcon />}
            //     key={1}
            //   >
            //     {t('editTerminal')}
            //   </AppButton>,
            // ],
          }}
        >
          <Box sx={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
            <Box>
              {data && (
                <MultipleColumnContainer
                  renderData={locationCustomRenderFunctions()}
                  columns={[['name', 'address', 'placeCode', 'status']]}
                  dataObj={data ?? {}}
                  translateFunc={(k: string): string => {
                    return t(k);
                  }}
                />
              )}
              <Box sx={{ marginLeft: 3, paddingBottom: 3 }}>
                <AppLink
                  to={`${LOCATIONS_URL}/${data?.location.identifier}/${merchantId}`}
                >
                  <AppButton variant="squared">{t('showDetails')}</AppButton>
                </AppLink>
              </Box>
            </Box>
            {/*{data && <MapWithMarkers center={markers[0]} markers={markers} />}*/}
          </Box>
        </AppDataContainer>
      </Box>
    </>
  );
};
