import { styled, Typography } from '@mui/material';
import { ReactElement } from 'react';

export type RenderData = Record<
  string,
  //TODO: Change typings to use generics => You pass data obj type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (k: string, data: Record<string, any>) => string | ReactElement | ReactElement[]
>;

export type ColumnConfig = string[] | string[][];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataObj = Record<string, any>;

export type TranslateFunc = (k: string) => string;

export const ValueKeyWrapper = styled(Typography)(({ theme: { spacing } }) => ({
  margin: spacing(1, 0),
  wordBreak: 'break-all',
})) as typeof Typography;

// Map keys array to column
export const renderKey = (key: string, translateFunc: TranslateFunc) => {
  return <ValueKeyWrapper component="span">{translateFunc(key)}</ValueKeyWrapper>;
};

// Match column keys, with render data keys. Render data entry is function which then get passed data object value and render it.
export const renderValue = (key: string, dataObj: DataObj, renderData?: RenderData) => {
  const displayValue = (renderData: RenderData, dataObj: DataObj, key: string) => (
    <ValueKeyWrapper component="span">
      {renderData && renderData[key] ? (
        renderData[key](dataObj[key], dataObj)
      ) : dataObj[key] === undefined ? (
        <div>&nbsp;</div>
      ) : (
        dataObj[key]
      )}
    </ValueKeyWrapper>
  );
  if (renderData) return displayValue(renderData, dataObj, key);
};
