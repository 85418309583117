import dayjs from 'dayjs';

export const getTransformedTimePeriod = (time_period: string): Date[] => {
  if (!time_period) return [new Date(), new Date()];
  let startDate: dayjs.Dayjs;
  let endDate: dayjs.Dayjs;

  switch (time_period) {
    case 'TODAY':
      startDate = dayjs().startOf('day');
      endDate = dayjs().endOf('day');
      break;
    case 'YESTERDAY':
      startDate = dayjs().subtract(1, 'day').startOf('day');
      endDate = dayjs().subtract(1, 'day').endOf('day');
      break;
    case 'THIS_WEEK':
      startDate = dayjs().startOf('week');
      endDate = dayjs().endOf('week');
      break;
    case 'LAST_7_DAYS':
      startDate = dayjs().subtract(6, 'day').startOf('day');
      endDate = dayjs().endOf('day');
      break;
    case 'LAST_30_DAYS':
      startDate = dayjs().subtract(29, 'day').startOf('day');
      endDate = dayjs().endOf('day');
      break;
    case 'LAST_90_DAYS':
      startDate = dayjs().subtract(89, 'day').startOf('day');
      endDate = dayjs().endOf('day');
      break;
    case 'THIS_MONTH':
      startDate = dayjs().startOf('month');
      endDate = dayjs().endOf('month');
      break;
    case 'THIS_YEAR':
      startDate = dayjs().startOf('year');
      endDate = dayjs().endOf('year');
      break;
    case 'LAST_YEAR':
      startDate = dayjs().subtract(1, 'year').startOf('year');
      endDate = dayjs().subtract(1, 'year').endOf('year');
      break;
    default:
      throw new Error('Invalid range provided');
  }

  return [startDate.toDate(), endDate.toDate()];
};
