import { Box, useTheme } from '@mui/material';
import { AppBarChart } from 'components/charts/barChart';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface NetworkDataProps {
  data: Record<string, number>[];
}

export const NetworkLoad: React.FC<NetworkDataProps> = ({ data }) => {
  const theme = useTheme();
  const { spacing } = theme;
  const { t } = useTranslation('common', { keyPrefix: 'widgets.networkLoad' });
  const transformedData = useMemo(() => {
    return Object.entries(data).map((entry) => ({
      value: entry[1] as unknown as number,
      name: entry[0],
    }));
  }, [data]);

  return (
    <Box sx={{ padding: spacing(2) }}>
      <AppBarChart data={transformedData} yAxisLabel={t('barChartAxisLabel')} />
      <Box sx={{ maxWidth: '50%' }}></Box>
    </Box>
  );
};
