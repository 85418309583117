import { Toaster } from 'react-hot-toast';
import { HEADER_HEIGHT } from 'components/layouts/authenticatedLayout';
import { COLORS } from 'theme/colors';

export const Toast = () => {
  return (
    <Toaster
      position="top-right"
      containerStyle={{
        top: HEADER_HEIGHT + 16,
      }}
      toastOptions={{
        success: {
          duration: 5000,
          style: {
            background: COLORS.LIGHT_GREEN.MAIN,
            color: 'white',
          },
          iconTheme: {
            primary: 'white',
            secondary: COLORS.LIGHT_GREEN.MAIN,
          },
        },
        error: {
          duration: 5000,
          style: {
            background: COLORS.RED.MAIN,
            color: 'white',
          },
          iconTheme: {
            primary: 'white',
            secondary: COLORS.RED.MAIN,
          },
        },
      }}
    />
  );
};
