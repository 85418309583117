import { api } from '../index';
import { TerminalDetail, Terminal, UserRole } from 'types/commonTypes';
import { useQuery } from 'react-query';
import { ApiResponse } from 'types/utilityInterfaces';
import { FileType, getFile } from 'utils/funcs/downloadFile';
import { getNotEmptyParams } from 'utils/funcs/convert';

export interface TerminalsFilter {
  search: string;
  status: string;
  order_type: 'ASC' | 'DESC';
  order_column: string;
}

const getTerminalsCollection = async (
  networkId: string,
  params: Partial<TerminalsFilter>,
) => {
  if (!networkId) {
    return;
  }

  const notEmptyParams = getNotEmptyParams(params);
  const response = await api.get<ApiResponse<Terminal[]>>(
    `/networks/${networkId}/terminals`,
    {
      params: notEmptyParams,
    },
  );
  return response.data;
};

export const useTerminalsCollectionsQuery = (
  networkId: string,
  currentRole: UserRole,
  filterParams = {},
) =>
  useQuery(
    ['getTerminals', networkId, currentRole, filterParams],
    () => getTerminalsCollection(networkId, filterParams),
    {
      staleTime: 0,
    },
  );

const getTerminalDetail = async (
  networkId: string,
  merchantId: string,
  locationId: string,
  terminalId: string,
) => {
  const response = await api.get<TerminalDetail>(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/terminals/${terminalId}`,
  );
  return response.data;
};

export const useTerminalDetailQuery = (
  networkId: string,
  merchantId: string,
  locationId: string,
  terminalId: string,
) =>
  useQuery(['getTerminalDetail', networkId, merchantId, locationId, terminalId], () =>
    getTerminalDetail(networkId, merchantId, locationId, terminalId),
  );

export const exportTerminalData = async (
  networkId: string,
  type: FileType,
  filterParams = {},
) => {
  const notEmptyParams = getNotEmptyParams(filterParams);
  return await getFile(
    `/networks/${networkId}/terminals/export/${type}`,
    type,
    'Terminals',
    notEmptyParams,
  );
};
