import {
  Container,
  IconButton,
  Box,
  useTheme,
  styled,
  Drawer,
  MenuItem,
  SelectChangeEvent,
  ListSubheader,
} from '@mui/material';
import useLanguageSelect from 'utils/hooks/useLanguageSelect';
import switchioLogo from 'assets/images/switchio_blue_small.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { StandardNavigation } from './standardNavigation/standardNavigation';
import { UserAvatar } from './userAvatar';
import { SearchBox } from './searchBox';
import { useEffect, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { useTranslation } from 'react-i18next';
import { SimpleAppSelect } from '../form/select/simpleAppSelect';
import React from 'react';
import useAuthentication from 'utils/hooks/useAuthentication';
import AppLink from 'components/appLink/appLink';
import { COLORS } from 'theme/colors';
import { UserRole } from 'types/commonTypes';
import { LicenseInfo } from '@mui/x-license-pro';
import FullscreenLoader from 'components/fullscreenLoader';
import { UserAppsMenu } from 'components/layouts/userAppsMenu';

const DRAWER_WIDTH = 430;
const DRAWER_WIDTH_COLLAPSED = 104;
export const HEADER_HEIGHT = 100;

interface Props {
  children: React.ReactNode;
}

export interface NavbarField {
  icon?: React.ReactNode;
  url?: string;
  label: string;
  children?: NavbarField[];
  isCollapsed?: boolean;
}

const NavBar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<{ isCollapsed: boolean; isMobile: boolean }>(
  ({ theme: { spacing, palette }, isCollapsed, isMobile }) => {
    const DRAWER_WIDTH_MAIN = isCollapsed ? DRAWER_WIDTH_COLLAPSED : DRAWER_WIDTH;
    return {
      position: 'sticky',
      display: 'flex',
      top: 0,
      padding: isMobile ? spacing(1, 2) : spacing(2, 6),
      backgroundColor: palette.common.white,
      zIndex: 10,
      width: `calc(100% - ${DRAWER_WIDTH_MAIN}px)`,
      transition: 'all 0.4s ease-out',
      marginLeft: `${DRAWER_WIDTH_MAIN}px`,
      minHeight: `${HEADER_HEIGHT}px`,
    };
  },
);

const LogoLinkWrapper = styled(AppLink, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<{ isCollapsed: boolean }>(({ theme: { spacing }, isCollapsed }) => ({
  display: 'flex',
  padding: isCollapsed ? spacing(4, 2) : spacing(2, 4, 2),
  flexDirection: 'column',
  borderBottom: `1px solid ${COLORS.LIGHT_GREY.DARK}`,
  minHeight: `calc(${HEADER_HEIGHT}px + 1px)`,
  justifyContent: 'center',
}));

const AppWrapper = styled('div')(({ theme: { palette } }) => ({
  minHeight: '100vh',
  backgroundColor: palette.background.lightGrey.main,
  display: 'flex',
  flexDirection: 'column',
  fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
}));

const AppContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<{ isCollapsed: boolean; isMobile: boolean }>(({ isCollapsed, isMobile }) => {
  const DRAWER_WIDTH_MAIN = isCollapsed ? DRAWER_WIDTH_COLLAPSED : DRAWER_WIDTH;

  return {
    minHeight: 'calc(100vh - 128px)',
    marginLeft: `${DRAWER_WIDTH_MAIN}px`,
    width: `calc(100% - ${DRAWER_WIDTH_MAIN}px)`,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: '100% !important',
    transition: 'all 0.4s ease-out',
    padding: isMobile ? 0 : '5rem',
  };
});

const HelpAndFeedback = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<{ isCollapsed: boolean }>(({ theme: { spacing, typography }, isCollapsed }) => ({
  display: 'flex',
  justifyContent: isCollapsed ? 'center' : 'start',
  gap: spacing(2),
  marginTop: 'auto',
  margin: isCollapsed ? spacing('auto', 'auto', 3) : spacing('auto', 0, 3),
  fontWeight: typography.fontWeightMedium,
  height: spacing(3),
  padding: isCollapsed ? spacing(0, 4, 0, 5) : spacing(0, 4),
  transition: 'all 0.4s ease-out',
  color: COLORS.DARK_BLUE.MAIN,
}));

// const HelpAndFeedbackText = styled('div', {
//   shouldForwardProp: (prop) => prop !== 'isCollapsed',
// })<{ isCollapsed: boolean }>(({ isCollapsed }) => ({
//   color: COLORS.DARK_BLUE.MAIN,
//   whiteSpace: 'nowrap',
//   display: isCollapsed ? 'none' : 'block',
// }));

const DrawerFooter = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<{ isCollapsed: boolean }>(({ theme: { spacing }, isCollapsed }) => ({
  width: '100%',
  backgroundColor: COLORS.LIGHT_BLUE.LIGHT,
  padding: spacing(isCollapsed ? 2 : 4.25),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: spacing(12.5),
  columnGap: spacing(1.25),
  alignItems: 'start',
}));

const DrawerFooterHeading = styled('div')(
  ({ theme: { spacing, palette, typography } }) => ({
    color: palette.secondary.main,
    fontWeight: typography.fontWeightBold,
    fontSize: spacing(4),
  }),
);

const DrawerFooterContentWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: spacing(2),
}));

const ConfirmationNumberIconWrapper = styled('div')(
  ({ theme: { spacing, palette } }) => ({
    backgroundColor: palette.terniary.main,
    padding: spacing(0.625),
    borderRadius: spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

const StyledIconButton = styled(IconButton)(({ theme: { spacing, palette } }) => ({
  width: spacing(4),
  height: spacing(4),
  color: palette.common.white,
  backgroundColor: COLORS.GREY.LIGHT,
  '&:hover': {
    backgroundColor: palette.text.lightGrey.dark,
  },
}));

const StyledConfirmationNumberOutlinedIcon = styled(ConfirmationNumberOutlinedIcon)(
  ({ theme: { palette } }) => ({
    color: palette.common.white,
  }),
);

const AuthenticatedLayout = ({ children }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const languageSelect = useLanguageSelect({ isMobile });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { t } = useTranslation('common', { keyPrefix: '' });
  const { changeRole, userGroups, currentRole, currentUserGroup } = useAuthentication();
  const logoUrl = currentUserGroup?.logo;
  const handleToggleCollapsed = () => {
    setIsCollapsed((prev) => !prev);
  };

  LicenseInfo.setLicenseKey(window.appConfig.REACT_APP_MUIX_LICENSE_KEY ?? '');

  useEffect(() => {
    if (isTablet) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [isTablet]);
  const handleChange = async (e: SelectChangeEvent<unknown>) => {
    changeRole(e.target.value as string);
  };

  const DRAWER_WIDTH_MAIN = isCollapsed ? DRAWER_WIDTH_COLLAPSED : DRAWER_WIDTH;

  if (!currentRole) {
    return <FullscreenLoader />;
  }
  return (
    <AppWrapper>
      <Drawer
        sx={{
          width: DRAWER_WIDTH_MAIN,
          flexShrink: 0,
          position: 'relative',
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH_MAIN,
            transition: 'all 0.4s ease-out',
            boxSizing: 'border-box',
            overflow: 'hidden',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <LogoLinkWrapper to="/" isCollapsed={isCollapsed}>
          {isCollapsed ? (
            <>
              <img src={logoUrl ?? switchioLogo} alt="switchio logo" />
            </>
          ) : (
            <>
              <img src={logoUrl ?? switchioLogo} width={190} alt="switchio logo" />
            </>
          )}{' '}
        </LogoLinkWrapper>
        <Container>
          <StandardNavigation
            handleToggleCollapsed={handleToggleCollapsed}
            languageSelect={languageSelect}
            isCollapsed={isCollapsed}
          />
        </Container>

        <HelpAndFeedback isCollapsed={isCollapsed}>
          {/*<InfoIcon />{' '}*/}
          {/*<HelpAndFeedbackText isCollapsed={isCollapsed}>*/}
          {/*  {t('components.avatar.helpAndFeedback')}*/}
          {/*</HelpAndFeedbackText>*/}
        </HelpAndFeedback>

        <DrawerFooter isCollapsed={isCollapsed}>
          {!isCollapsed ? (
            <>
              {!logoUrl && <img width={82} height={14} src={switchioLogo} />}
              <DrawerFooterContentWrapper>
                <DrawerFooterHeading>{t('navigation.retailPortal')}</DrawerFooterHeading>
                <StyledIconButton
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                >
                  <ExpandLessIcon style={{ transform: 'rotate(270deg)' }} />{' '}
                </StyledIconButton>
              </DrawerFooterContentWrapper>
            </>
          ) : (
            <DrawerFooterContentWrapper>
              <ConfirmationNumberIconWrapper>
                <StyledConfirmationNumberOutlinedIcon />
              </ConfirmationNumberIconWrapper>
              <StyledIconButton
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                }}
              >
                <ExpandLessIcon style={{ transform: 'rotate(90deg)' }} />{' '}
              </StyledIconButton>
            </DrawerFooterContentWrapper>
          )}
        </DrawerFooter>
      </Drawer>
      <NavBar isCollapsed={isCollapsed} isMobile={isMobile}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            paddingRight: '1.5rem',
            flexDirection: isTablet ? 'column' : 'row',
            gap: 2,
          }}
        >
          <SearchBox />
          <Box display={'flex'} gap={isMobile ? 0.5 : 2}>
            <UserAppsMenu />
            <SimpleAppSelect
              sx={{ maxWidth: isMobile ? '140px' : '100%' }}
              value={currentRole?.id ?? ''}
              onChange={handleChange}
            >
              {userGroups.map((group) => [
                <ListSubheader key={group.id}>{group.name}</ListSubheader>,
                ...group.roles.map((role: UserRole) => (
                  <MenuItem value={role.id} key={role.id}>
                    {role.name}
                  </MenuItem>
                )),
              ])}
            </SimpleAppSelect>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2.5,
              }}
            >
              {/*<NotificationBell />*/}
              {languageSelect}
              <UserAvatar />
            </Box>
          </Box>
        </Box>
      </NavBar>
      <AppContainer isCollapsed={isCollapsed} isMobile={isMobile}>
        {children}
      </AppContainer>
    </AppWrapper>
  );
};

export default AuthenticatedLayout;
