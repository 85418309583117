import { api } from '../index';
import { useQuery } from 'react-query';
import { LocationType } from 'types/commonTypes';

// CUSTOMER_STATUS
export const getCustomerFilterOptions = async (merchantId: string) => {
  const response = await api.get(`/merchant/${merchantId}/enum/customer_status`);
  return Object.keys(response.data);
};
export const useCustomerFiltersQuery = (merchantId: string) =>
  useQuery(['getCustomerFilterOption', merchantId], () =>
    getCustomerFilterOptions(merchantId),
  );

//   VEHICLE
export const getVehicleFilterOptions = async (merchantId: string) => {
  const response = await api.get(`/merchant/${merchantId}/enum/vehicle_type`);
  return Object.values(response.data);
};
export const useVehicleFiltersQuery = (merchantId: string) =>
  useQuery(['getVehicleFilterOptions', merchantId], () =>
    getVehicleFilterOptions(merchantId),
  );

//   TOKEN_STATUS
export const getTokenFilterOptions = async (merchantId: string) => {
  const response = await api.get(`/merchant/${merchantId}/enum/token_status`);
  return Object.keys(response.data);
};
export const useTokenFiltersQuery = (merchantId: string) =>
  useQuery(['getTokenFilterOptions', merchantId], () =>
    getTokenFilterOptions(merchantId),
  );

//   PAYEMNT_WINDOW_STATUS
export const getPaymentWindowStatusFilterOptions = async (merchantId: string) => {
  const response = await api.get(`/merchant/${merchantId}/enum/payment_window_status`);
  return Object.keys(response.data);
};
export const usePaymentWindowStatusFiltersQuery = (merchantId: string) =>
  useQuery(['getTokenFilterOptions', merchantId], () =>
    getPaymentWindowStatusFilterOptions(merchantId),
  );

//   TERMINAL STATUS
export const getTerminalStatusFilterOptions = async () => {
  const response = await api.get(`/enums/terminal_status`);
  return response.data;
};
export const useTerminalStatusFiltersQuery = () =>
  useQuery(['getTerminalStatusFilterOptions'], () => getTerminalStatusFilterOptions());

//   TERMINAL TYPE
export const getTerminalTypeFilterOptions = async (merchantId: string) => {
  const response = await api.get(`/merchant/${merchantId}/enum/terminal_type`);
  return response.data;
};
export const useTerminalTypeFiltersQuery = (merchantId: string) =>
  useQuery(['getTerminalTypeFilterOptions', merchantId], () =>
    getTerminalTypeFilterOptions(merchantId),
  );

type TransactionStatus = Record<string, string>;

//   TRANSACTION STATUS
export const getTransactionStatusFilterOptions = async () => {
  const response = await api.get<TransactionStatus>(`/enums/transaction_status`);

  return Object.values(response.data);
};
export const useTransactionStatusFiltersQuery = () =>
  useQuery(['getTransactionStatusFilterOptions'], () =>
    getTransactionStatusFilterOptions(),
  );

type TransactionType = Record<string, string>;

//   TRANSACTION TYPE
export const getTransactionTypeFilterOptions = async () => {
  const response = await api.get<TransactionType>(`/enums/transaction_type`);
  return Object.values(response.data);
};
export const useTransactionTypeFiltersQuery = () =>
  useQuery(['getTransactionTypeFilterOptions'], () => getTransactionTypeFilterOptions());

type CardIssuer = Record<string, string>;

//   CARD ISSUER
export const getCardIssuerFilterOptions = async () => {
  const response = await api.get<CardIssuer>(`/enums/card_issuer`);
  return Object.values(response.data);
};
export const useCardIssuerFiltersQuery = () =>
  useQuery(['getCardIssuerFilterOptions'], () => getCardIssuerFilterOptions());

type CardSchema = Record<string, string>;

//   CARD SCHEMA
export const getCardSchemaFilterOptions = async () => {
  const response = await api.get<CardSchema>(`/enums/card_schema`);
  return Object.values(response.data);
};
export const useCardSchemaFiltersQuery = () =>
  useQuery(['getCardSchemaFilterOptions'], () => getCardSchemaFilterOptions());

type CardOrigin = Record<string, string>;

//   CARD ORIGIN
export const getCardOriginFilterOptions = async () => {
  const response = await api.get<CardOrigin>(`/enums/card_origin`);
  return Object.values(response.data);
};
export const useCardOriginFiltersQuery = () =>
  useQuery(['getCardOriginFilterOptions'], () => getCardOriginFilterOptions());

type LineEnum = Record<string, string>;

//   LINES
export const getLinesFilterOptions = async (merchantId: string) => {
  const response = await api.get<LineEnum>(`/merchant/${merchantId}/enum/lines`);
  return Object.values(response.data);
};
export const useLinesFiltersQuery = (merchantId: string) =>
  useQuery(['getLinesFilterOptions', merchantId], () =>
    getLinesFilterOptions(merchantId),
  );

type PaymentDeviceEnum = Record<string, string>;

//   PAYMENT DEVICES
export const getPaymentDevicesFilterOptions = async () => {
  const response = await api.get<PaymentDeviceEnum>(`/enums/payment_device`);
  return Object.values(response.data);
};
export const usePaymentDevicesFiltersQuery = () =>
  useQuery(['getPaymentDevicesFilterOptions'], () => getPaymentDevicesFilterOptions());

type PaymentType = Record<string, string>;

//   PAYMENT DEVICES
export const getPaymentTypesFilterOptions = async () => {
  const response = await api.get<PaymentType>(`/enums/payment_type`);
  return Object.values(response.data);
};
export const usePaymentTypesFiltersQuery = () =>
  useQuery(['getPaymentTypesFilterOptions'], () => getPaymentTypesFilterOptions());

type CardProductsType = Record<string, string>;

//   CARD PRODUCTS
export const getCardProductsFilterOptions = async () => {
  const response = await api.get<CardProductsType>(`/enums/card_product`);
  return Object.values(response.data);
};
export const useCardProductsFiltersQuery = () =>
  useQuery(['getCardProductsFilterOptions'], () => getCardProductsFilterOptions());

//   LOCATIONS
export const getLocationOptions = async () => {
  const response = await api.get<Omit<LocationType, 'terminal_count'>[]>(
    `/user/locations`,
  );
  return Object.values(response.data);
};
export const useLocationsQuery = () =>
  useQuery(['getLocationOptions'], () => getLocationOptions());
