import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  exportLocationData,
  LocationsFilter,
  useGetUserLocationsQuery,
} from 'api/endpoints/locations';
import AppLink from 'components/appLink/appLink';
import { AppTable, AppTableProps } from 'components/appTable';
import { useCallback, useEffect, useMemo } from 'react';
import useFilters from 'utils/hooks/filters/useFilters';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LocationType } from 'types/commonTypes';
import { useAppSelector } from 'stores/store';
import { useTranslation } from 'react-i18next';
import useAuthentication from 'utils/hooks/useAuthentication';
import * as React from 'react';
import { TOrderColumn } from 'api/endpoints/transactions';
import { useDebounce } from 'utils/hooks/useDebounce';
import { PageTitle } from 'components/pageTitle';
import { LOCATIONS_URL } from 'pages/appRoutes/appRoutesConst';
import usePagination from 'utils/hooks/usePagination';
import useQueryParameters from 'utils/hooks/useQueryParameters';
import { createHandleSortModelChange } from 'utils/hooks/createHandleSortModelChange';
import { useLocation, useNavigate } from 'react-router-dom';
interface TSortModel {
  field: TOrderColumn;
  sort: string;
}
export const LocationsPage: React.FC = () => {
  const { filters, filterValues, reset, loading } = useFilters<LocationsFilter>({
    filters: ['SEARCH_LOCATIONS'],
    namePairs: [{ filterName: 'SEARCH_LOCATIONS', queryname: 'search' }],
  });
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation('common', { keyPrefix: 'pages.locationsPage' });
  const { networkId } = useAppSelector((state) => state.user);
  const { currentRole } = useAuthentication();
  const getQueryParameter = useQueryParameters();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const queryOptionsQP = getQueryParameter('queryOptions');
  const filtersQP = getQueryParameter('filters');
  const { page, setPage, pageSize, setPageSize } = usePagination({
    location,
    navigate,
  });
  useEffect(() => {
    reset(filtersQP);
  }, []);

  const [queryOptions, setQueryOptions] = React.useState<TSortModel | null>(
    queryOptionsQP,
  );

  const handleSortModelChange = useCallback(
    createHandleSortModelChange({
      params,
      navigate,
      setQueryOptions,
      setPage,
    }),
    [navigate, params, setQueryOptions, setPage],
  );

  const debouncedSearch = useDebounce(filterValues?.search, 400);

  const { data, isLoading } = useGetUserLocationsQuery(networkId, currentRole, {
    ...filterValues,
    offset: page * pageSize,
    limit: pageSize,
    search: debouncedSearch,
    order_column: queryOptions?.field,
    order_type: queryOptions?.sort.toUpperCase(),
  });

  const LOCATIONS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('table.name'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;
          return <div>{row.name}</div>;
        },
      },
      {
        field: 'street',
        headerName: t('table.street'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;
          return <div>{row.street}</div>;
        },
      },
      {
        field: 'city',
        headerName: t('table.city'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;
          return <div>{row.city}</div>;
        },
      },
      {
        field: 'zip_code',
        headerName: t('table.zipCode'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;
          return <div>{row.zip_code}</div>;
        },
      },
      {
        field: 'goToDetail',
        sortable: false,
        headerName: '',
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink
                  to={`${LOCATIONS_URL}/${row.identifier}/${row.merchant_identifier}`}
                  isGreyLink
                >
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [resolvedLanguage],
  );
  // const markers: MarkerInterface[] = useMemo(
  //   () =>
  //     data?.data.map((d) => ({
  //       lat: parseFloat(d.gps_lat),
  //       lng: parseFloat(d.gps_lng),
  //     })) ?? [],
  //   [data],
  // );

  const changePageSizeHandler = useCallback(
    (pageSize: number) => {
      setPage(0);
      setPageSize(pageSize);
    },
    [setPage, setPageSize],
  );

  return (
    <>
      <PageTitle title={t('pageTitle')} />
      {filters}
      <AppTable
        loading={isLoading || loading}
        // mapComponent={<MapWithMarkers markers={markers} />}
        rows={data?.data ?? []}
        columns={LOCATIONS_COLUMNS}
        appTitle={t('tableTitle')}
        showSettings={false}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={(p) => changePageSizeHandler(p)}
        totalRows={data?.paging.total_count ?? 0}
        handleSortModelChange={handleSortModelChange}
        exportMenuItems={[
          {
            onClick: async () =>
              await exportLocationData(networkId, 'xlsx', filterValues),
            label: 'XLSX',
          },
          {
            onClick: async () => await exportLocationData(networkId, 'csv', filterValues),
            label: 'CSV',
          },
        ]}
      />
    </>
  );
};
