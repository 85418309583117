import { ArrowForwardIos } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { AppTable, AppTableProps } from '../components/appTable';
import AppLink from '../components/appLink/appLink';
import { MERCHANT_DETAIL_URL } from './appRoutes/appRoutesConst';
import {
  exportMerchants,
  MerchantsFilter,
  useGetAllMerchantsQuery,
} from 'api/endpoints/merchants';
import { useAppSelector } from 'stores/store';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Merchant } from 'types/commonTypes';
import useFilters from 'utils/hooks/filters/useFilters';
import useAuthentication from 'utils/hooks/useAuthentication';
import { useTranslation } from 'react-i18next';
import { TOrderColumn } from 'api/endpoints/transactions';
import * as React from 'react';
import { PageTitle } from 'components/pageTitle';
import usePagination from 'utils/hooks/usePagination';
import useQueryParameters from 'utils/hooks/useQueryParameters';
import { createHandleSortModelChange } from 'utils/hooks/createHandleSortModelChange';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'utils/hooks/useDebounce';
interface TSortModel {
  field: TOrderColumn;
  sort: string;
}
export default function MerchantsPage() {
  const { filters, filterValues, reset, loading } = useFilters<MerchantsFilter>({
    filters: ['MERCHANT_ID', 'MERCHANT_NAME'],
    namePairs: [
      { filterName: 'MERCHANT_ID', queryname: 'merchantId' },
      { filterName: 'MERCHANT_NAME', queryname: 'name' },
    ],
  });
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation('common', { keyPrefix: 'pages.merchantsPage' });
  const { networkId } = useAppSelector((state) => state.user);
  const { currentRole } = useAuthentication();
  const getQueryParameter = useQueryParameters();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const queryOptionsQP = useMemo(
    () => getQueryParameter('queryOptions'),
    [location.search],
  );
  const filtersQP = useMemo(() => getQueryParameter('filters'), [location.search]);

  const { page, setPage, pageSize, setPageSize } = usePagination({
    location,
    navigate,
  });

  useEffect(() => {
    reset(filtersQP);
  }, []);

  const [queryOptions, setQueryOptions] = React.useState<TSortModel | null>(
    queryOptionsQP,
  );

  const handleSortModelChange = useCallback(
    createHandleSortModelChange({
      params,
      navigate,
      setQueryOptions,
      setPage,
    }),
    [navigate, params, setQueryOptions, setPage],
  );
  const changePageSizeHandler = useCallback(
    (pageSize: number) => {
      setPage(0);
      setPageSize(pageSize);
    },
    [setPage, setPageSize],
  );

  const debouncedMerchantId = useDebounce(filterValues?.merchantId, 400);
  const debouncedMerchantName = useDebounce(filterValues?.name, 400);

  const queryParameters = useMemo(
    () => ({
      ...filterValues,
      offset: page * pageSize,
      limit: pageSize,
      merchantId: debouncedMerchantId,
      name: debouncedMerchantName,
      order_column: queryOptions?.field || null,
      order_type: queryOptions?.sort?.toUpperCase() || null,
    }),
    [networkId, currentRole, filterValues, page, pageSize, queryOptions],
  );

  const { data, isLoading: merchantsLoading } = useGetAllMerchantsQuery(
    networkId,
    currentRole,
    queryParameters,
  );
  const MERCHANTS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'identifier',
        headerName: t('table.id'),
        renderCell: (params: GridRenderCellParams<Merchant>) => {
          const { row } = params;

          return <>{row.identifier}</>;
        },
        flex: 1,
      },
      {
        field: 'name',
        headerName: t('table.name'),
        renderCell: (params: GridRenderCellParams<Merchant>) => {
          const { row } = params;

          return <>{row.name}</>;
        },
        flex: 1,
      },
      {
        field: 'terminal_count',
        headerName: t('table.terminals'),
        renderCell: (params: GridRenderCellParams<Merchant>) => {
          const { row } = params;

          return <>{row.terminal_count}</>;
        },
        flex: 1,
      },
      {
        field: 'location_count',
        headerName: t('table.locations'),
        renderCell: (params: GridRenderCellParams<Merchant>) => {
          const { row } = params;

          return <>{row.location_count}</>;
        },
        flex: 1,
      },
      {
        field: 'goToDetail',
        sortable: false,
        headerName: '',
        renderCell: (params: GridRenderCellParams<Merchant>) => {
          const { row } = params;

          return (
            <AppLink
              to={`/${MERCHANT_DETAIL_URL.replace(/\/:id$/, '')}/${row.identifier}`}
              isGreyLink
            >
              <ArrowForwardIos />
            </AppLink>
          );
        },
      },
    ],
    [t, resolvedLanguage],
  );

  return (
    <main>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <PageTitle title={t('pageTitle')} />
      </Grid>
      {filters}
      <AppTable
        loading={loading || merchantsLoading}
        rows={data?.data ?? []}
        columns={MERCHANTS_COLUMNS}
        appTitle={t('list')}
        showSettings={false}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={(p) => changePageSizeHandler(p)}
        totalRows={data?.paging.total_count ?? 0}
        handleSortModelChange={handleSortModelChange}
        exportMenuItems={[
          {
            onClick: async () => await exportMerchants(networkId, 'xlsx', filterValues),
            label: 'XLSX',
          },
          {
            onClick: async () => await exportMerchants(networkId, 'csv', filterValues),
            label: 'CSV',
          },
        ]}
      />
    </main>
  );
}
