import {
  styled,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';
import FormField from '../formField';
import { forwardRef } from 'react';

interface ToggleButtonProps<T> extends ToggleButtonGroupProps {
  value: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void;
  label: string;
  errorText: string;
  options: { label: string; value: T }[];
}

const StyledToggleButton = styled(ToggleButton)(({ theme: { spacing } }) => ({
  padding: spacing(1.15, 1),
}));

const AppToggleButton: React.ForwardRefRenderFunction<
  HTMLDivElement,
  ToggleButtonProps<any>
> = ({ value, onChange, label, errorText, options }, ref) => {
  return (
    <FormField label={label} required={undefined} error={undefined} errorText={errorText}>
      <ToggleButtonGroup
        ref={ref}
        value={value}
        exclusive
        onChange={onChange}
        aria-label="text alignment"
        fullWidth
      >
        {options.map((option, index) => (
          <StyledToggleButton key={index} value={option.value} aria-label="left aligned">
            {option.label}
          </StyledToggleButton>
        ))}
      </ToggleButtonGroup>
    </FormField>
  );
};

export default forwardRef(AppToggleButton);
