import { api } from '../index';
import { useQuery } from 'react-query';
import { Notification } from 'types/commonTypes';

const getNotificationCollection = async (read: number) => {
  return await api.get<Notification[]>(`/notifications?read=${read}`);
};

export const useNotificationCollectionsQuery = (
  notificationTrigger: number,
  read = 1,
) => {
  return useQuery(['getNotifications', notificationTrigger], () =>
    getNotificationCollection(read),
  );
};

export const readSingleNotification = async (notificationId: number) => {
  return await api.patch<Notification[]>(`/notification/${notificationId}/read`);
};

export const readAllNotificationsFromUser = async () => {
  return await api.patch<Notification[]>(`/notification/read-all`);
};
