import axios from 'axios';
import toast from 'react-hot-toast';
import i18n from 'i18next';

export const errorHandling = async (error: any) => {
  if (axios.isAxiosError(error)) {
    switch (error?.response?.status) {
      case 401: {
        return toast.error(i18n.t('errorHandling.unauthorized'));
      }
      case 403: {
        return toast.error(i18n.t('errorHandling.accessDenied'));
      }
      case 500: {
        return toast.error(i18n.t('errorHandling.serverError'));
      }
      default: {
        const err = error?.response?.data?.errorMessage || error?.response?.data?.message;
        if (err) {
          toast.error(err);
        } else {
          toast.error(i18n.t('errorHandling.generalError'));
        }
      }
    }
  } else {
    toast.error(i18n.t('errorHandling.generalError'));
  }
};
