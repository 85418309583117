import { Box, Grid, Typography } from '@mui/material';
import { useGetMerchantQuery } from 'api/endpoints/merchants';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'stores/store';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'theme/colors';
import { theme } from 'theme';
import { AppDataContainer } from 'components/appDataContainer';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { MERCHANTS_URL } from 'pages/appRoutes/appRoutesConst';
import FullscreenLoader from 'components/fullscreenLoader';

export default function MerchantDetail() {
  const { networkId } = useAppSelector((state) => state.user);
  const params = useParams();
  const { data, isLoading } = useGetMerchantQuery(networkId, params.id ?? '');
  const { t } = useTranslation('common', { keyPrefix: 'pages.merchantDetail' });
  const { spacing } = theme;

  if (isLoading) return <FullscreenLoader />;

  if (!data) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <AppBreadcrumbs
          customBreadCrumbs={[
            { index: 1, breadCrumbLabel: data?.name || '', route: MERCHANTS_URL },
          ]}
        />
      </Box>
      <Box sx={{ marginBottom: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <AppDataContainer
          sx={{ marginBottom: 3 }}
          titleRow={{
            title: 'Basic info',
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            padding={spacing(4, 3)}
          >
            <Grid item xs={2} sm={4} md={3}>
              <Typography variant={'body2'} fontWeight={400} color={COLORS.GREY.MAIN}>
                {t('merchantId')}
              </Typography>
              <Typography variant={'body2'} fontWeight={700} color={COLORS.GREY.MAIN}>
                {data.identifier}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <Typography variant={'body2'} fontWeight={400} color={COLORS.GREY.MAIN}>
                {t('name')}
              </Typography>
              <Typography variant={'body2'} fontWeight={700} color={COLORS.GREY.MAIN}>
                {data.name}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <Typography variant={'body2'} fontWeight={400} color={COLORS.GREY.MAIN}>
                {t('terminals')}
              </Typography>
              <Typography variant={'body2'} fontWeight={700} color={COLORS.GREY.MAIN}>
                {data.terminal_count}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={3}>
              <Typography variant={'body2'} fontWeight={400} color={COLORS.GREY.MAIN}>
                {t('locations')}
              </Typography>
              <Typography variant={'body2'} fontWeight={700} color={COLORS.GREY.MAIN}>
                {data.location_count}
              </Typography>
            </Grid>
          </Grid>
        </AppDataContainer>
      </Box>
    </>
  );
}
