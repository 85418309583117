import { OidcRpClient, OidcRpLoginSuccess } from '@idport/oidc-rp-sdk';
import { ERROR_PAGE, LOGOUT_URL } from 'pages/appRoutes/appRoutesConst';
import React, { Dispatch, createContext, useContext, useState } from 'react';
import { errorHandling } from 'utils/funcs/errorHandling';
import { navigateWithReload } from 'utils/funcs/navigateWithReload';

interface GlobalContextData {
  oidcRpClient: OidcRpClient;
  oidcRpLoginData: OidcRpLoginSuccess | null;
  SetOidcRpLoginData: Dispatch<React.SetStateAction<OidcRpLoginSuccess | null>>;
}

const GlobalContext = createContext<GlobalContextData | null>(null);

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext');
  }
  return context;
};

interface Props {
  children: React.ReactNode;
}

export const GlobalContextProvider: React.FC<Props> = ({ children }) => {
  const [oidcRpLoginData, SetOidcRpLoginData] = useState<OidcRpLoginSuccess | null>(null);
  const [oidcRpClient] = useState(
    OidcRpClient.createClient({
      clientId: window.appConfig.REACT_APP_AUTH_CLIENT_ID ?? '',
      issuer:
        `${window.appConfig.REACT_APP_AUTH_ISSUER_HOST}/${window.appConfig.REACT_APP_AUTH_ISSUER_URL}` ??
        '',
      responseType: 'code',
      checkSession: { interval: 1, iframeId: 'op' },
      checkUserInactivity: { type: 'app', timeout: 600, interval: 1 },
      callback: (e) => {
        switch (e.type) {
          case 'session_changed':
            //REDIRECT NA LOGOUT
            navigateWithReload(LOGOUT_URL);
            break;
          case 'session_error':
            break;
          case 'user_inactivity_timeout':
            userInactivityTimeout();
            break;
        }
      },
    }),
  );

  const userInactivityTimeout = async () => {
    try {
      await oidcRpClient
        ?.revokeToken()
        .then(() => navigateWithReload(LOGOUT_URL))
        .catch((e) => {
          errorHandling(e);
          navigateWithReload(ERROR_PAGE, { replace: true });
        });
    } catch (error) {
      errorHandling(error);
      navigateWithReload(ERROR_PAGE, { replace: true });
    }
  };

  const values = {
    oidcRpClient,
    oidcRpLoginData,
    SetOidcRpLoginData,
  };

  return <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>;
};
