import { Box } from '@mui/material';
import { AppBarChart } from 'components/charts/barChart';
import { useRef } from 'react';

interface DataGraphContainerProps {
  dataBoxes: { value: number; label: string }[];
  yAxisLabel?: string;
  yAxisUnit?: string;
}

export const DataGraphContainer: React.FC<DataGraphContainerProps> = ({
  dataBoxes,
  yAxisLabel,
  yAxisUnit,
}) => {
  const ref = useRef(null);

  return (
    <Box sx={{ width: '100%' }} ref={ref}>
      <AppBarChart
        yAxisLabel={yAxisLabel}
        yAxisUnit={yAxisUnit}
        barAreaHeight={400}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        barAreaWidth={ref?.current?.offsetWidth * 1}
        data={dataBoxes.map((d) => ({ value: d.value, name: d.label })) ?? []}
      />
    </Box>
  );
};
