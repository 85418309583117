import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserApp, UserGroup, UserRole } from 'types/commonTypes';
import { ENGLISH } from 'utils/const/languages';

export interface User {
  unitId: string;
  merchantId: string;
  networkId: string;
  accountUrl: string;
  currentRole: null | UserRole;
  currentUserGroup: null | UserGroup;
  userApps: UserApp[];
  isNetworkRole: boolean; // has additional access to merchants
  lang: 'en' | 'cs' | 'de' | 'fr' | 'nl' | 'es' | 'ro' | 'sk';
}

interface SetUserRolePayload {
  unitId: string;
  merchantId: string;
  networkId: string;
  role: UserRole;
  isNetworkRole: boolean;
}

export interface IAMLang {
  lang: 'en' | 'cs' | 'de' | 'fr' | 'nl' | 'es' | 'ro' | 'sk';
}

const initialState: User = {
  unitId: '',
  merchantId: '',
  networkId: '',
  accountUrl: '',
  currentRole: null,
  currentUserGroup: null,
  userApps: [] as UserApp[],
  lang: ENGLISH,
  isNetworkRole: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserRole: (state, action: PayloadAction<SetUserRolePayload>) => ({
      ...state,
      unitId: action.payload.unitId,
      merchantId: action.payload.merchantId,
      networkId: action.payload.networkId,
      currentRole: action.payload.role,
      isNetworkRole: action.payload.isNetworkRole,
    }),
    setIAMLang: (state, action: PayloadAction<IAMLang>) => ({
      ...state,
      lang: action.payload.lang,
    }),
    setUserGroup: (state, action) => ({
      ...state,
      currentUserGroup: action.payload.currentUserGroup,
    }),
    setAccountUrl: (state, action) => ({
      ...state,
      accountUrl: action.payload.accountUrl,
    }),
    setUserApps: (state, action) => ({
      ...state,
      userApps: action.payload.userApps,
    }),
  },
});

export const { setUserRole, setIAMLang, setUserGroup, setAccountUrl, setUserApps } =
  userSlice.actions;

export default userSlice.reducer;
