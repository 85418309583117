import { Box, Typography, useTheme } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useLocationDetailQuery } from 'api/endpoints/locations';
import { useTerminalsCollectionsQuery } from 'api/endpoints/terminals';
import { AppDataContainer } from 'components/appDataContainer';
import { TranslateFunc } from 'components/appDataContainer/utility';
import AppLink from 'components/appLink/appLink';
import { AppTable, AppTableProps } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { RevenueOverview } from 'components/revenueOverview';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppSelector } from 'stores/store';
import { LocationType, Terminal, Transaction } from 'types/commonTypes';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import { formatDateToDay } from 'utils/funcs/formatDate';
import { useTransactionsCollectionsQuery } from 'api/endpoints/transactions';
import { MultipleColumnContainer } from 'components/appDataContainer/multipleColumnContainer';
import AppButton from 'components/appButton';
import { TERMINALS_URL, TRANSACTIONS_URL } from 'pages/appRoutes/appRoutesConst';
import useAuthentication from 'utils/hooks/useAuthentication';
import * as React from 'react';
import { TransactionsTable } from 'components/appTable/TransactionsTable';
import { useGridState } from 'utils/hooks/useGridState';
import useMediaQuery from '@mui/material/useMediaQuery';
import FullscreenLoader from 'components/fullscreenLoader';

const customRenderFunctions = (
  translateFunc: TranslateFunc,
  navigateFunc: (route: string, params?: Record<string, any>) => void,
) => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contact: (key: string, data: Record<string, any>) => {
    const retypedData = data as LocationType;
    return retypedData.street;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  address: (key: string, data: Record<string, any>) => {
    const retypedData = data as LocationType;
    return (
      <>
        <p>{retypedData.street}</p>
        <p>{retypedData.city}</p>
        <p>{retypedData.zip_code}</p>
      </>
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  placeCode: (key: string, data: Record<string, any>) => {
    const retypedData = data as LocationType;
    return retypedData.zip_code;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  terminalsQty: (key: string, data: Record<string, any>) => {
    const retypedData = data as LocationType;
    return (
      <>
        {retypedData.terminal_count}
        <AppButton
          onClick={() =>
            navigateFunc(TERMINALS_URL, {
              state: { locationId: retypedData?.identifier },
            })
          }
          sx={{ marginLeft: 2 }}
          variant="contained"
          color="primary"
        >
          {translateFunc('show')}
        </AppButton>
      </>
    );
  },
});

export const LocationDetailPage: React.FC = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const { networkId } = useAppSelector((state) => state.user);
  const { currentRole } = useAuthentication();
  const { locationId, merchantId } = useParams();

  if (!merchantId || !locationId) {
    return <></>;
  }

  const { data, isLoading: locationsLoading } = useLocationDetailQuery(
    networkId,
    merchantId,
    locationId,
  );

  const navigate = useNavigate();

  const {
    page: transactionPage,
    setPage: setTransactionPage,
    pageSize: transactionPageSize,
    setPageSize: setTransactionPageSize,
    queryOptions: transactionsQueryOptions,
    handleSortModelChange: handleSortTransactionsModelChange,
  } = useGridState(0, 5, {
    field: 'date_terminal',
    sort: 'DESC',
  });

  const {
    page: terminalPage,
    setPage: setTerminalPage,
    pageSize: terminalPageSize,
    setPageSize: setTerminalPageSize,
    queryOptions: terminalsQueryOptions,
    handleSortModelChange: handleSortTerminalsModelChange,
  } = useGridState(0, 10);

  const { data: terminalsData, isLoading: terminalsLoading } =
    useTerminalsCollectionsQuery(networkId, currentRole, {
      location: locationId,
      offset: terminalPage * terminalPageSize,
      limit: terminalPageSize,
      order_column: terminalsQueryOptions?.field,
      order_type: terminalsQueryOptions?.sort.toUpperCase(),
    });
  const { data: transactionsData, isLoading: transactionsLoading } =
    useTransactionsCollectionsQuery(networkId, currentRole, {
      location: locationId,
      limit: transactionPageSize,
      offset: transactionPage * transactionPageSize,
      order_column: transactionsQueryOptions?.field,
      order_type: transactionsQueryOptions?.sort.toUpperCase(),
    });

  const changePageSizeHandler = React.useCallback((pageSize: number) => {
    setTerminalPage(0);
    setTerminalPageSize(pageSize);
  }, []);

  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation('common', { keyPrefix: 'pages.locationDetailPage' });
  const TERMINALS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      { field: 'identifier', headerName: t('terminals.id'), flex: 1 },
      { field: 'name', headerName: t('terminals.name'), flex: 1 },
      {
        field: 'goToDetail',
        sortable: false,
        headerName: '',
        renderCell: (params: GridRenderCellParams<Terminal>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink
                  to={`${TERMINALS_URL}/${row.identifier}/${row.location.identifier}/${row.merchant.identifier}`}
                  isGreyLink
                >
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [resolvedLanguage],
  );

  // const markers: MarkerInterface[] = useMemo(
  //   () => [
  //     { lat: parseFloat(data?.gps_lat || '0'), lng: parseFloat(data?.gps_lng || '0') },
  //   ],
  //   [data],
  // );

  const TRANSACTION_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'date_terminal',
        headerName: t('latestTransactions.date'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction>) => {
          const { row } = params;
          return (
            <div>
              <Typography component="div" fontWeight={'bold'}>
                {formatDateToDay(row.date_terminal, 'DD.MM.YYYY')}
              </Typography>
              <Typography component="div">
                {formatDateToDay(row.date_terminal, 'HH:mm:ss')}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'amount',
        headerName: t('latestTransactions.amountAndCurrency'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;
          return (
            <Box>
              <Typography sx={{ marginRight: 0.5 }} component="span" fontWeight={'bold'}>
                {(row.amount / 100).toFixed(2)}
              </Typography>
              <Typography component="span">{row.currency}</Typography>
            </Box>
          );
        },
      },
      {
        field: 'status',
        headerName: t('latestTransactions.status'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;

          return (
            <AppStatusHighlight status={row.status}>{row.status}</AppStatusHighlight>
          );
        },
      },
      {
        field: 'terminal_identifier',
        headerName: t('latestTransactions.terminalId'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;
          return <Typography>{row.terminal_identifier}</Typography>;
        },
      },
      {
        field: 'goToDetail',
        sortable: false,
        headerName: '',
        renderCell: (params: GridRenderCellParams<Transaction>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink
                  to={`${TRANSACTIONS_URL}/${row.id}/${row.merchant_identifier}/${row.location_identifier}/${row.terminal_identifier}`}
                  isGreyLink
                >
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [resolvedLanguage],
  );

  if (locationsLoading) return <FullscreenLoader />;

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <AppBreadcrumbs
          customBreadCrumbs={[
            { index: 1, breadCrumbLabel: data?.name || '', route: '/' },
          ]}
        />
      </Box>
      <AppDataContainer
        sx={{ marginBottom: 3 }}
        titleRow={{
          title: t('pageTitle'),
        }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: isTablet ? '1fr' : '1fr  1fr' }}>
          <MultipleColumnContainer
            renderData={customRenderFunctions(t, navigate)}
            columns={[['contact', 'address', 'placeCode', 'terminalsQty']]}
            dataObj={data ?? {}}
            translateFunc={(k: string): string => {
              return t(k);
            }}
          />
          {/*{data && <MapWithMarkers center={markers[0]} markers={markers} />}*/}
        </Box>
      </AppDataContainer>
      <Box sx={{ marginBottom: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {locationId && (
          <RevenueOverview
            showSquaredButton
            locationId={locationId}
            endpoint="location"
            propsData={data}
          />
        )}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: isTablet ? '1fr' : '1fr 1fr',
            gap: 2,
          }}
        >
          <TransactionsTable
            isLoading={transactionsLoading}
            page={transactionPage}
            isNextPage={transactionsData?.paging.nextPage ?? false}
            rowCount={transactionsData?.paging.count ?? 0}
            setPage={setTransactionPage}
            pageSize={transactionPageSize}
            setPageSize={setTransactionPageSize}
            rows={transactionsData?.data ?? []}
            columns={TRANSACTION_COLUMNS}
            appTitle={t('latestTransactions.tableTitle')}
            showSettings={false}
            handleSortModelChange={handleSortTransactionsModelChange}
          />

          <AppTable
            loading={terminalsLoading}
            rows={terminalsData?.data ?? []}
            columns={TERMINALS_COLUMNS}
            appTitle={t('terminals.tableTitle')}
            showSettings={false}
            exportMenuItems={[]}
            page={terminalPage}
            pageSize={terminalPageSize}
            setPage={setTerminalPage}
            setPageSize={(p) => changePageSizeHandler(p)}
            totalRows={terminalsData?.paging.total_count ?? 0}
            handleSortModelChange={handleSortTerminalsModelChange}
          />
        </Box>
      </Box>
    </>
  );
};
