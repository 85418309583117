import { api } from '../index';

export interface FilterObject {
  id: number;
  name: string;
  filter: Record<string, string>;
}

export const saveFilters = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterValues: Record<string, any>,
  filterName: string,
) => {
  const response = await api.post(`/filters/transaction`, {
    name: filterName,
    filter: { ...filterValues },
  });
  return response;
};

export const getFilters = async () => {
  const response = await api.get<FilterObject[]>(`/filters/transaction`);
  return response.data;
};

export const deleteFilter = async (filterId: number) => {
  const response = await api.delete<FilterObject>(`/filters/transaction/${filterId}`);
  return response;
};
