import * as React from 'react';
import FormField from '../formField';
import { Box } from '@mui/material';
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AppTextField from '../textField';
import i18n from 'i18next';
import { forwardRef } from 'react';

interface DateRangeSelectProps {
  value: DateRange<Date>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void;
  label: string;
  error: any;
  helperText: string;
}

const AppDateRangeSelect: React.ForwardRefRenderFunction<any, DateRangeSelectProps> = (
  { value, onChange, label, error, helperText },
  ref,
) => {
  const inputId = `app-date-range-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <FormField
      label={label}
      error={error}
      errorText={helperText ? i18n.t(helperText) : undefined}
      required={true}
      htmlFor={inputId}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          ref={ref}
          startText=""
          endText=""
          inputFormat="DD/MM/YYYY"
          value={value}
          onChange={onChange}
          renderInput={(startProps: any, endProps: any) => (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <AppTextField {...startProps} id={inputId} />
              <AppTextField {...endProps} />
            </Box>
          )}
        />
      </LocalizationProvider>
    </FormField>
  );
};

export default forwardRef(AppDateRangeSelect);
