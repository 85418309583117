import { Typography, useTheme } from '@mui/material';
import { TerminalStatus } from 'types/enumTypes';
import CircleIcon from '@mui/icons-material/Circle';
import { useEffect, useState } from 'react';

interface TerminalStatusProps {
  status: TerminalStatus;
  showCircle?: boolean;
}

export const TerminalStatusComponent: React.FC<TerminalStatusProps> = ({
  status,
  showCircle = true,
}) => {
  const [color, setColor] = useState('error');
  // const { t } = useTranslation('common', {
  //   keyPrefix: 'enums.terminalStatus',
  // });
  const theme = useTheme();
  const { palette } = theme;

  useEffect(() => {
    switch (status) {
      case TerminalStatus['ACTIVE']:
        setColor(palette.success.main);
        break;
      case TerminalStatus['INACTIVE']:
        setColor(palette.text.lightGrey.main);
        break;
      default:
        setColor(palette.error.main);
        break;
    }
  }, [status]);

  return (
    <Typography
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      color={color}
    >
      {showCircle && <CircleIcon sx={{ marginRight: 1, color }} />}
      <Typography component={'span'} sx={{ color }}>
        {status && status}
      </Typography>
    </Typography>
  );
};
