import dayjs, { Dayjs, UnitTypeLong } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(calendar);
dayjs.extend(customParseFormat);

type DifferenceUnits =
  | 'day'
  | 'week'
  | 'quarter'
  | 'month'
  | 'year'
  | 'hour'
  | 'minute'
  | 'second'
  | 'millisecond';

// export const firstDayOfMonth = dayjs().startOf('month').format('YYYY-MM-DD') as Date;
export const firstDayOfMonth: Date = dayjs().startOf('month').toDate();

export const formatDateToDay = (
  date: Date | string,
  format = 'D.MM.YYYY',
  inputFormat?: string,
) => {
  if (inputFormat) return dayjs(date, inputFormat).format(format);
  return dayjs(date).format(format);
};

export const formatDateToTime = (date: Date | string, format = 'hh:mm') =>
  dayjs(date).format(format);

export const formatToBeforeDays = (dateBeforeToday: Date | string) =>
  dayjs(dateBeforeToday).from(new Date());

export const areDatesEqual = (
  datum: Date | string,
  dateCreated: Date | string,
  calendarUnitType: UnitTypeLong,
) => dayjs(datum).isSame(dateCreated, calendarUnitType);

export const isTodayDate = (date: Date | string) => dayjs(date).isToday();

export const calculateDifferenceBetweenDates = (
  dateOne: Dayjs,
  dateTwo: Dayjs,
  differenceUnit: DifferenceUnits = 'minute',
) => dateOne.diff(dateTwo, differenceUnit, true);

export const createDayJsFromCustomFormat = (date: string, inputFormat: string) =>
  dayjs(date, inputFormat);

export const convertDayJsToIso = (date: Dayjs) => date.toISOString();

export const startOfDay = dayjs().startOf('day'),
  endOfDay = dayjs().endOf('day');
