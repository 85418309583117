import { Box, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import AppLink from 'components/appLink/appLink';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import { AppTableProps, AppTable } from 'components/appTable';
import { useMemo } from 'react';
import { TransactionDetail } from 'types/commonTypes';
import { formatDateToDay } from 'utils/funcs/formatDate';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { TRANSACTIONS_URL } from 'pages/appRoutes/appRoutesConst';

interface LatestTransactionProps {
  data: TransactionDetail[];
}

export const LatestTransaction: React.FC<LatestTransactionProps> = ({ data }) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation('common', { keyPrefix: 'pages.transactionsPage' });

  const LATEST_TRANSACTION_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'date',
        sortable: false,
        headerName: t('table.date'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TransactionDetail>) => {
          const { row } = params;
          return (
            <Box>
              <Typography component="div" fontWeight={'bold'}>
                {formatDateToDay(row.date_terminal, 'DD.MM.YYYY')}
              </Typography>
              <Typography component="div">
                {formatDateToDay(row.date_terminal, 'HH:mm:ss')}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: 'amount',
        sortable: false,
        headerName: t('table.amountAndCurrency'),
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<TransactionDetail, TransactionDetail>,
        ) => {
          const { row } = params;
          return (
            <Box>
              <Typography component="div" fontWeight={'bold'}>
                {(row.amount / 100).toFixed(2)}
              </Typography>
              <Typography component="div">{row.currency}</Typography>
            </Box>
          );
        },
      },
      {
        field: 'status',
        sortable: false,
        headerName: t('table.status'),
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<TransactionDetail, TransactionDetail>,
        ) => {
          const { row } = params;

          return (
            <AppStatusHighlight displayCircle={false} status={row.status}>
              {row.status}
            </AppStatusHighlight>
          );
        },
      },
      { field: 'type', sortable: false, headerName: t('table.opType'), flex: 1 },
      {
        field: 'approval_code',
        sortable: false,
        headerName: t('table.approvalCode'),
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<TransactionDetail, TransactionDetail>,
        ) => {
          const { row } = params;

          return <>{row.approval_code}</>;
        },
      },
      {
        field: 'goToDetail',
        sortable: false,
        headerName: '',
        renderCell: (params: GridRenderCellParams<TransactionDetail>) => {
          const { row } = params;
          return (
            <>
              {
                <AppLink
                  to={`${TRANSACTIONS_URL}/${row.uuid}/${row.merchant_identifier}/${row.location_identifier}/${row.terminal_identifier}`}
                  isGreyLink
                >
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [resolvedLanguage],
  );
  return (
    <AppTable
      rows={data ?? []}
      columns={LATEST_TRANSACTION_COLUMNS}
      appTitle={t('tableTitle')}
      hideHeaderRow
    />
  );
};
