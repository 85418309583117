import { Card, ThemeProvider, Typography, useTheme } from '@mui/material';
import { DataGridPro, GridColumns, GridSortModel } from '@mui/x-data-grid-pro';
import { CustomPagination } from 'components/appTable/customPagging';
import { dataGridTheme } from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { csCZ, deDE, enUS, esES, frFR, nlNL, roRO, skSK } from '@mui/x-data-grid/locales';
export interface LifecycleTableProps {
  rows: readonly {
    [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }[];
  columns: GridColumns;
  page: number;
  setPage: (value: number) => void;
  pageSize: number;
  setPageSize: (value: number) => void;
  rowsPerPage?: number[];
  isNextPage: boolean;
  title: string;
  handleSortModelChange?: (sortModel: GridSortModel) => void;
  loading: boolean;
}

export const LifecycleTable = ({
  rows,
  columns,
  page,
  setPage,
  pageSize,
  setPageSize,
  isNextPage,
  title,
  handleSortModelChange,
  loading,
}: LifecycleTableProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const rowsWithId = rows.map((row) => ({ ...row, id: row.id ?? row.uuid }));
  const {
    i18n: { resolvedLanguage },
  } = useTranslation('common', { keyPrefix: 'components.table' });

  const [localeText, setLocaleText] = useState(enUS);

  useEffect(() => {
    if (resolvedLanguage === 'en') setLocaleText(enUS);
    if (resolvedLanguage === 'cs') setLocaleText(csCZ);
    if (resolvedLanguage === 'de') setLocaleText(deDE);
    if (resolvedLanguage === 'fr') setLocaleText(frFR);
    if (resolvedLanguage === 'nl') setLocaleText(nlNL);
    if (resolvedLanguage === 'es') setLocaleText(esES);
    if (resolvedLanguage === 'ro') setLocaleText(roRO);
    if (resolvedLanguage === 'sk') setLocaleText(skSK);
  }, [resolvedLanguage]);

  return (
    <Card sx={{ width: '100%' }}>
      <Typography
        component="div"
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '8px',
        }}
      >
        <Typography component="div" sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" component="div">
            {title}
          </Typography>
        </Typography>
      </Typography>
      <ThemeProvider theme={dataGridTheme}>
        <DataGridPro
          loading={loading}
          disableColumnSelector
          disableColumnFilter
          columns={columns as unknown as GridColumns}
          rows={rowsWithId}
          localeText={localeText.components.MuiDataGrid.defaultProps.localeText}
          pagination
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          components={{ Pagination: CustomPagination }}
          componentsProps={{
            pagination: {
              page,
              setPage,
              pageSize,
              setPageSize,
              isNextPage,
            },
          }}
          autoHeight
          getRowId={(row) => (row?.uuid ? row.uuid : row.identifier)}
          paginationMode="server"
          sx={{
            width: '100%',
            borderRadius: '0px',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: 'none',
            color: theme.palette.text.darkGrey.main,
            '& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-columnHeaderDraggableContainer':
              {
                flexDirection: isMobile ? 'row-reverse' : 'row',
              },
          }}
        />
      </ThemeProvider>
    </Card>
  );
};
