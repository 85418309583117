export const parseRoleContext = (context: string) => {
  const colonSplit = context.split(':');
  const isNetworkRole = colonSplit[0] === 'network';

  if (colonSplit.length < 2) {
    return {
      networkId: '',
      merchantId: '',
      unitId: '',
      isNetworkRole,
    };
  }

  const backslashSplit = colonSplit[1].split('/');

  return {
    networkId: backslashSplit[0] ?? '',
    merchantId: backslashSplit[1] ?? '',
    unitId: backslashSplit[2] ?? '',
    isNetworkRole: colonSplit[0] === 'network',
  };
};

export const getNotEmptyParams = (params: Record<string, unknown>) =>
  Object.keys(params).reduce((acc, curr) => {
    const currentValue = params[curr];
    if (currentValue === '' || currentValue === undefined) {
      return acc;
    }
    return {
      ...acc,
      [curr]: currentValue,
    };
  }, {});
