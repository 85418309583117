import {
  DialogTitle,
  Typography,
  IconButton,
  Box,
  useTheme,
  styled,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import AppButton from 'components/appButton';
import AppTextField from 'components/form/textField';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'theme/colors';
import { useEffect } from 'react';
import { DateLiteral, Refund, REVERSAL } from 'types/commonTypes';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import FormController from 'components/formController';

export interface TRefundData {
  max_refund_amount: number;
}

interface Props {
  handleClose: () => void;
  createCallBack: (refundData: Refund | REVERSAL) => void;
  uuid: string | undefined;
  terminalDate: DateLiteral | undefined;
  refundData: TRefundData;
}

const StyledTextField = styled(TextField)(({ theme: { spacing } }) => ({
  '& .MuiFilledInput-root': {
    border: `1px solid ${COLORS.LIGHT_GREY.DARK} !important`,
    borderRadius: spacing(1),
    background: `${COLORS.LIGHT_GREY.LIGHT} !important`,
    '&:before': {
      border: 'none !important',
    },
    '&:after': {
      border: 'none !important',
    },
  },
  '& .MuiInputLabel-root': {
    fontSize: spacing(1.5),
    fontWeight: 700,
  },
  '& .MuiInputBase-input': {
    fontSize: spacing(1.8),
    fontWeight: 400,
  },
}));
const StyledHelperText = styled(Typography)(({ theme: { spacing } }) => ({
  fontSize: spacing(1.4),
  fontWeight: 400,
}));

const TYPE = 'type';
const AMOUNT = 'amount';
const REASON = 'reason';

type FormValues = {
  [TYPE]: boolean;
  [AMOUNT]: number;
  [REASON]: string;
};

export const RefundTransactionModal: React.FC<Props> = ({
  handleClose,
  uuid,
  terminalDate,
  createCallBack,
  refundData,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.refundDialog' });
  const { t: tValidation } = useTranslation('', {
    keyPrefix: 'validation',
  });
  const theme = useTheme();
  const { spacing } = theme;

  const defaultValues = {
    [TYPE]: false,
    [AMOUNT]: 0,
    [REASON]: '',
  };

  const schema = Joi.object({
    [TYPE]: Joi.boolean(),
    [AMOUNT]: Joi.when(TYPE, {
      is: false,
      then: Joi.number()
        .min(Number.MIN_VALUE)
        .max(refundData.max_refund_amount)
        .required()
        .messages({
          'number.base': tValidation('required'),
          'number.empty': tValidation('required'),
          'number.max': tValidation('refund.maximumAmountError'),
          'number.min': tValidation('refund.invalidAmountValue'),
        }),
      otherwise: Joi.number().optional(),
    }),
    [REASON]: Joi.string()
      .trim()
      .required()
      .messages({
        'string.empty': tValidation('required'),
      }),
  });

  const form = useForm<FormValues>({
    resolver: joiResolver(schema),
    defaultValues: defaultValues,
    mode: 'onBlur',
  });

  const { control, handleSubmit, watch, setValue, trigger } = form;

  const refundType = watch(TYPE);
  const refundAmount = watch(AMOUNT);

  const remainingAmount = refundData ? refundData.max_refund_amount - refundAmount : 0;

  useEffect(() => {
    if (refundType) {
      setValue(AMOUNT, 0);
      trigger(AMOUNT);
    }
  }, [refundType, trigger]);

  const onSubmit = (formData: FormValues) => {
    if (formData.type) {
      createCallBack({
        type: 'REVERSAL',
        reason: formData.reason,
      });
    } else {
      if (formData?.amount) {
        createCallBack({
          ...formData,
          type: 'REFUND',
          amount: Math.round(refundAmount * 100),
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {' '}
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${COLORS.LIGHT_GREY.DARK}`,
        }}
      >
        <Typography variant="h6" fontWeight={700}>
          {t('title')}
        </Typography>
        <IconButton key="Settings" onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          padding: spacing(4, 3),
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <StyledTextField
          variant={'filled'}
          disabled
          label={t('UUID')}
          value={uuid ?? ''}
        />
        <StyledTextField
          variant={'filled'}
          disabled
          label={t('terminalDate')}
          value={terminalDate ?? ''}
        />
        <FormController name={TYPE} control={control}>
          <FormControlLabel
            control={<Checkbox />}
            label={
              <Typography
                sx={{
                  fontSize: spacing(1.8),
                  fontWeight: 400,
                  color: COLORS.GREY.MAIN,
                }}
              >
                {t('refundAllAmountCheckbox') as string}
              </Typography>
            }
          />
        </FormController>
        <FormController name={AMOUNT} control={control}>
          <AppTextField
            required
            label={t('refundAmount')}
            InputProps={{ type: 'number' }}
            disabled={refundType}
            helperText={
              <Grid container justifyContent={'space-between'}>
                <StyledHelperText>{`${t('maximumAmount')}: ${
                  refundData?.max_refund_amount.toFixed(2) ?? 0
                }`}</StyledHelperText>
                <StyledHelperText>{`${t('remainingAmount')}: ${remainingAmount.toFixed(
                  2,
                )}`}</StyledHelperText>
              </Grid>
            }
          />
        </FormController>
        <FormController name={REASON} control={control}>
          <AppTextField label={t('refundReason')} multiline rows={4} required />
        </FormController>

        <Grid container gap={2} justifyContent={'flex-end'}>
          <AppButton
            sx={{
              backgroundColor: COLORS.LIGHT_GREY.MAIN,
              color: COLORS.GREY.MAIN,
              fontSize: '0.9rem',
              fontWeight: 500,
              padding: theme.spacing(2),
              ':hover': {
                backgroundColor: COLORS.LIGHT_GREY.DARK,
              },
            }}
            onClick={() => handleClose()}
            variant="contained"
          >
            {t('close')}
          </AppButton>
          <AppButton
            sx={{
              backgroundColor: COLORS.LIGHT_BLUE.LIGHT,
              color: COLORS.DARK_BLUE.MAIN,
              fontSize: '0.9rem',
              fontWeight: 500,
              padding: theme.spacing(2),
              ':hover': {
                backgroundColor: COLORS.DARK_BLUE.LIGHT,
              },
            }}
            type="submit"
            variant="contained"
          >
            {t('confirm')}
          </AppButton>
        </Grid>
      </Box>
    </form>
  );
};
