import {
  Dialog,
  DialogTitle,
  IconButton,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { CheckCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'theme/colors';
import CloseIcon from '@mui/icons-material/Close';

interface successDialogProps {
  isDialogOpen: boolean;
  handleClose: () => void;
  message: string | undefined;
}

const SuccessIcon = styled(CheckCircleOutline)(({ theme: { spacing, palette } }) => ({
  width: spacing(7),
  height: spacing(7),
  color: palette.success.main,
  padding: '0 !important',
}));

const SuccessDialog: React.FC<successDialogProps> = ({
  isDialogOpen,
  message,
  handleClose,
}: successDialogProps) => {
  const { spacing } = useTheme();
  const { t } = useTranslation('common', { keyPrefix: 'components.dialog' });
  return (
    <Dialog open={isDialogOpen} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${COLORS.LIGHT_GREY.DARK}`,
        }}
      >
        <Typography variant="h6" fontWeight={700}>
          {t('successDialogTitle')}
        </Typography>
        <IconButton key="Settings" onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: spacing(6, 6),
          gap: spacing(2),
        }}
      >
        <SuccessIcon />
        <Typography textAlign={'center'}>{message}</Typography>
      </Box>
    </Dialog>
  );
};

export default SuccessDialog;
