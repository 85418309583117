import { InputBase, styled } from '@mui/material';
import { COLORS } from 'theme/colors';

const AppInputBase = styled(InputBase)(({ theme: { palette, spacing }, error }) => ({
  '&.MuiInputBase-root': {
    borderRadius: '8px',
    border: `1px solid ${error ? COLORS.RED.MAIN : palette.border.darkGrey}`,
  },
  '& .MuiInputBase-input': {
    fontSize: '0.9rem',
    fontWeight: 400,
    color: COLORS.GREY.MAIN,
    borderColor: `${error ? COLORS.RED.MAIN : COLORS.GREY.MAIN}`,
    backgroundColor: 'transparent',
    padding: spacing(1.5, 2),
  },
  '&:hover': {
    borderColor: palette.border.secondary,
  },
  '&:focus-within': {
    borderColor: error ? COLORS.RED.MAIN : palette.border.secondary,
    borderWidth: '1px',
  },
  '&.Mui-focused': {
    borderColor: error ? COLORS.RED.MAIN : palette.border.secondary,
    borderWidth: '1px',
  },
}));

export default AppInputBase;
