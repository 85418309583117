import AppRoutes from 'pages/appRoutes/appRoutes';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'theme';
import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import { store } from 'stores/store';
import { Suspense } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import FullscreenLoader from 'components/fullscreenLoader';
import { GlobalContextProvider } from 'GlobalContext';
import { FiltersProvider } from 'context/FiltersContext';
import { Toast } from 'components/Toast';
import { errorHandling } from 'utils/funcs/errorHandling';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 0,
      },
    },
    queryCache: new QueryCache({
      onError: (err: any) => {
        errorHandling(err);
      },
    }),
  });

  return (
    <Suspense fallback={<FullscreenLoader />}>
      <CssBaseline />
      <Toast />
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <GlobalContextProvider>
              <FiltersProvider>
                <AppRoutes />
              </FiltersProvider>
            </GlobalContextProvider>
          </ThemeProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Provider>
    </Suspense>
  );
}

export default App;
