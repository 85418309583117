import { api } from '../index';
import { SearchGroup } from 'types/commonTypes';
import { useQuery } from 'react-query';

const getSearchedTermsCollection = async (networkId: string, text: string) => {
  const response = await api.get<SearchGroup>(`networks/${networkId}/search/${text}`);
  return response.data;
};

export const useSearchTermsQuery = (
  networkId: string,
  searchedText: string,
  canBeSendQuery: boolean,
) =>
  useQuery(
    ['getSearchedTerms', searchedText, canBeSendQuery, networkId],
    () => getSearchedTermsCollection(networkId, searchedText),
    {
      enabled: searchedText.length >= 3 && canBeSendQuery,
    },
  );
