import { styled, TextField as MuiTextField, TextFieldProps } from '@mui/material';
import FormField from '../formField';
import { forwardRef } from 'react';

const StyledTextField = styled(MuiTextField)(({ theme: { palette, spacing } }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.9rem',
    padding: spacing(1.5, 2),
    borderRadius: '8px',
    '& input': {
      padding: 0,
      color: palette.text.lightGrey.main,
      fontWeight: 400,
    },
    '& textarea': {
      padding: 0,
      color: palette.text.lightGrey.main,
      fontWeight: 400,
    },
  },
  '&.MuiTextField-root': {
    backgroundColor: 'transparent',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: palette.border.darkGrey,
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: palette.border.secondary,
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: palette.border.secondary,
    borderWidth: '1px',
  },
}));

const AppTextField: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TextFieldProps & { errorText?: string; optional?: boolean }
> = ({ label, error, errorText, required, optional, ...rest }, ref) => {
  const inputId = rest.id || `app-text-field-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <FormField
      label={label}
      required={required}
      error={error}
      errorText={errorText}
      optional={optional}
      htmlFor={inputId}
    >
      <StyledTextField fullWidth error={error} id={inputId} {...rest} inputRef={ref} />
    </FormField>
  );
};

export default forwardRef(AppTextField);
