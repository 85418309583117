import React, { createContext, useContext, useEffect, useState } from 'react';
import { TimeSource } from 'types/enumTypes';
import dayjs from 'dayjs';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { endOfDay, startOfDay } from 'utils/funcs/formatDate';
import { FilterTypes } from 'utils/hooks/filters/useFilters';
import { isEqual } from 'lodash';

const FiltersContext = createContext<any>(null);

const defaultState = {
  VEHICLE_TYPE: '',
  LINE: '',
  CUSTOMER_NAME: '',
  STATUS: '',
  CUSTOMER_STATUS: '',
  TOKEN_STATUS: '',
  PAYMENT_WINDOW_STATUS: '',
  TOKEN_ID: '',
  DATE_RANGE: [
    startOfDay.format('YYYY-MM-DDTHH:mm:ss'),
    endOfDay.format('YYYY-MM-DDTHH:mm:ss'),
  ],
  APPROVAL_CODE: '',
  PAN: '',
  AMOUNT_RANGE: [],
  CURRENCY: '',
  LOCATION: '',
  PAYMENT_TERMINAL_NAME: '',
  TERMINAL_ID: '',
  VARIABLE_SYMBOL: '',
  TRANSACTION_STATUS: '',
  TRANSACTION_TYPE: '',
  UUID: '',
  TIME_SOURCE: TimeSource['TERMINAL'],
  CARD_ISSUER: '',
  CARD_SCHEMA: '',
  PAYMENT_DEVICE: '',
  PAYMENT_TYPE: '',
  CARD_ORIGIN: '',
  CARD_PRODUCT: '',
  SEARCH_LOCATIONS: '',
  SEARCH_TERMINAL: '',
  DATE: dayjs().format('DD/MM/YYYY'),
  TERMINAL_STATUS: '',
  MERCHANT_ID: '',
  MERCHANT_NAME: '',
  MERCHANT: '',
  TIP: '',
  CASHBACK: '',
};

export const useFiltersContext = () => useContext(FiltersContext);

export const FiltersProvider: React.FC = ({ children }) => {
  const location: Location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const filters = params.get('filters');

  const [filterValues, setFilters] = useState<any>(defaultState);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (filters) {
      setFilters({ ...defaultState, ...JSON.parse(filters) });
    } else {
      setFilters({ ...defaultState });
    }
    setIsInitialized(true);
  }, [location.pathname]);

  useEffect(() => {
    if (isInitialized) {
      const params = new URLSearchParams(location.search);

      const nonDefaultFilters = Object.entries(filterValues)
        .filter(([key, value]) => {
          const defaultValue = defaultState[key as FilterTypes];
          return !isEqual(value, defaultValue);
        })
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {} as any);

      if (Object.keys(nonDefaultFilters).length > 0) {
        params.set('filters', JSON.stringify(nonDefaultFilters));
      } else {
        params.delete('filters');
      }

      navigate({ search: params.toString() }, { replace: true });
    }
  }, [filterValues, isInitialized]);

  return (
    <FiltersContext.Provider value={{ filterValues, setFilters, defaultState }}>
      {children}
    </FiltersContext.Provider>
  );
};

export default FiltersContext;
