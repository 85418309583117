export enum TransactionStatus {
  ACCEPTED = 'accept',
  DECLINED = 'declined',
  FAILED = 'fail',
  FINALIZED = 'finalized',
  OPENED = 'open',
  REVERSED = 'reversed',
}

export enum TerminalStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ERROR = 'ERROR',
}

export enum WidgetTypes {
  LATEST_TRANSACTIONS = 'latest_transactions',
  NETWORK_LOAD = 'network_load',
  CARDS = 'cards',
  STATES_OF_TRANSACTIONS = 'states_of_transactions',
  ACTIVITY_OVERVIEW = 'activity_overview',
  // TERMINALS = 'terminals',
  // TERMINAL_NOTIFICATIONS = 'terminal_notifications',
}

export enum TimeSource {
  SERVER = 'server',
  TERMINAL = 'terminal',
}

export enum TokenStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DENY = 'Deny',
}

export enum PaymentWindowStatus {
  OPEN = 'Open',
  CLOSED = 'Closed',
  DEBT = 'Debt',
}

export enum TimePeriod {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THIS_WEEK = 'THIS_WEEK',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  THIS_MONTH = 'THIS_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
}
export enum CardView {
  AFFILIATION = 'AFFILIATION',
  PRODUCT = 'PRODUCT',
  CARD_SCHEMA = 'CARD_SCHEMA',
  CARD_TYPE = 'CARD_TYPE',
  DEVICE = 'DEVICE',
  ACQUIRER = 'ACQUIRER',
  ORIGIN = 'ORIGIN',
}
