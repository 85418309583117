import { GridRenderCellParams, GridSortItem } from '@mui/x-data-grid-pro';
import AppLink from 'components/appLink/appLink';
import { AppTableProps, AppTable } from 'components/appTable';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Terminal, TerminalState, TSortModel } from 'types/commonTypes';
import useFilters from 'utils/hooks/filters/useFilters';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAppSelector } from 'stores/store';
import {
  TerminalsFilter,
  useTerminalsCollectionsQuery,
  exportTerminalData,
} from 'api/endpoints/terminals';
import useAuthentication from 'utils/hooks/useAuthentication';
import * as React from 'react';
import { useLocation } from 'react-router';
import { useDebounce } from 'utils/hooks/useDebounce';
import { TERMINALS_URL } from 'pages/appRoutes/appRoutesConst';
import { PageTitle } from 'components/pageTitle';
import usePagination from 'utils/hooks/usePagination';
import { createHandleSortModelChange } from 'utils/hooks/createHandleSortModelChange';
import useQueryParameters from 'utils/hooks/useQueryParameters';
import { useNavigate } from 'react-router-dom';
export const TerminalsPage: React.FC = () => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation('common', { keyPrefix: 'pages.terminalsPage' });
  const { networkId } = useAppSelector((state) => state.user);
  const { currentRole } = useAuthentication();
  const locationState = useLocation().state as TerminalState | undefined;
  const locationId = locationState?.locationId;
  const { filters, filterValues, reset, loading } = useFilters<TerminalsFilter>({
    filters: ['SEARCH_TERMINAL'],
    namePairs: [{ filterName: 'SEARCH_TERMINAL', queryname: 'search' }],
  });
  const getQueryParameter = useQueryParameters();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const queryOptionsQP = getQueryParameter('queryOptions');
  const filtersQP = getQueryParameter('filters');
  const { page, setPage, pageSize, setPageSize } = usePagination({ location, navigate });

  const [queryOptions, setQueryOptions] = React.useState<TSortModel | null>(
    queryOptionsQP,
  );

  useEffect(() => {
    reset(filtersQP);
  }, []);

  const handleSortModelChange = useCallback(
    createHandleSortModelChange({
      params,
      navigate,
      setQueryOptions,
      setPage,
    }),
    [navigate, params, setQueryOptions, setPage],
  );

  const debouncedSearch = useDebounce(filterValues?.search, 400);

  const { data, isLoading } = useTerminalsCollectionsQuery(networkId, currentRole, {
    ...filterValues,
    offset: page * pageSize,
    limit: pageSize,
    search: debouncedSearch,
    order_column: queryOptions?.field,
    order_type: queryOptions?.sort.toUpperCase(),
    location: locationId,
  });

  const TERMINALS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('table.name'),
        renderCell: (params: GridRenderCellParams<Terminal>) => {
          const { row } = params;
          return <>{row.name}</>;
        },
        flex: 1,
      },
      {
        field: 'identifier',
        headerName: t('table.terminalId'),
        renderCell: (params: GridRenderCellParams<Terminal>) => {
          const { row } = params;
          return <>{row.identifier}</>;
        },
        flex: 1,
      },
      {
        field: 'place',
        headerName: t('table.place'),
        renderCell: (params: GridRenderCellParams<Terminal>) => {
          const { row } = params;
          return (
            <AppLink
              to={`/locations/${row.location.identifier}/${row.merchant.identifier}`}
            >
              {row.location.name}
            </AppLink>
          );
        },
        flex: 1,
      },
      {
        field: 'address',
        headerName: t('table.address'),
        renderCell: (params: GridRenderCellParams<Terminal>) => {
          const { row } = params;

          return (
            <>{`${row.location.street}, ${row.location.zip_code}, ${row.location.city} `}</>
          );
        },
        flex: 1,
      },
      {
        field: 'goToDetail',
        sortable: false,
        headerName: '',
        renderCell: (params: GridRenderCellParams<Terminal>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink
                  to={`${TERMINALS_URL}/${row.identifier}/${row.location.identifier}/${row.merchant.identifier}`}
                  isGreyLink
                >
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [resolvedLanguage],
  );

  const changePageSizeHandler = useCallback(
    (pageSize: number) => {
      setPage(0);
      setPageSize(pageSize);
    },
    [setPage, setPageSize],
  );

  return (
    <>
      <PageTitle title={t('pageTitle')} />
      {filters}
      <AppTable
        loading={isLoading || loading}
        rows={data?.data ?? []}
        columns={TERMINALS_COLUMNS}
        appTitle={t('tableTitle')}
        showSettings={false}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={(p) => changePageSizeHandler(p)}
        totalRows={data?.paging.total_count ?? 0}
        handleSortModelChange={handleSortModelChange}
        exportMenuItems={[
          {
            onClick: async () =>
              await exportTerminalData(networkId, 'xlsx', filterValues),
            label: 'XLSX',
          },
          {
            onClick: async () => await exportTerminalData(networkId, 'csv', filterValues),
            label: 'CSV',
          },
        ]}
      />
    </>
  );
};
