import {
  alpha,
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import AppButton from 'components/appButton';
import AppTextField from 'components/form/textField';
import { useState } from 'react';

interface SaveFilterDialogProps {
  isDialogOpen: boolean;
  handleClose: () => void;
  onSubmit: (name: string) => void;
}

export const SaveFilterDialog: React.FC<SaveFilterDialogProps> = ({
  isDialogOpen,
  handleClose,
  onSubmit,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.saveFilterDialog' });
  const theme = useTheme();
  const { spacing } = theme;
  const [filterName, setFilterName] = useState('');
  return (
    <Dialog open={isDialogOpen}>
      <DialogTitle
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography variant="h5">{t('dialogTitle')}</Typography>
        <IconButton key="Settings" onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          padding: spacing(3),
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <AppTextField
          label={t('filterName')}
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />

        <Box sx={{ margin: 'auto' }}>
          <AppButton
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              padding: theme.spacing(2, 8),
              ':hover': {
                backgroundColor: alpha(theme.palette.secondary.main, 0.8),
              },
            }}
            onClick={() => {
              onSubmit(filterName);
              setFilterName('');
            }}
            variant="contained"
          >
            {t('confirm')}
          </AppButton>
        </Box>
      </Box>
    </Dialog>
  );
};
