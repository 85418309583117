import {
  alpha,
  Box,
  Card,
  Collapse,
  IconButton,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import AppButton from 'components/appButton';
import AppSelect from 'components/form/select';
import AppTextField from 'components/form/textField';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import {
  getCardIssuerFilterOptions,
  getCardOriginFilterOptions,
  getCardProductsFilterOptions,
  getCardSchemaFilterOptions,
  getCustomerFilterOptions,
  getPaymentDevicesFilterOptions,
  getPaymentTypesFilterOptions,
  getPaymentWindowStatusFilterOptions,
  getTerminalStatusFilterOptions,
  getTokenFilterOptions,
  getTransactionStatusFilterOptions,
  getTransactionTypeFilterOptions,
} from 'api/endpoints/enums';
import { QueryFunction, QueryKey, useQueries } from 'react-query';
import AppDateRangeSelect from 'components/form/appDateRangeSelect';
import { DateRange } from '@mui/x-date-pickers-pro';
import { isArray } from 'lodash';
import { AppDatePicker } from 'components/form/appDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AmountField from 'components/form/amountField';
import AppToggleButton from 'components/form/toggleButton';
import {
  PaymentWindowStatus,
  TimeSource,
  TokenStatus,
  TransactionStatus,
} from 'types/enumTypes';
import {
  deleteFilter,
  FilterObject,
  getFilters,
  saveFilters,
} from 'api/endpoints/filters';
import DeleteIcon from '@mui/icons-material/Delete';
import { SaveFilterDialog } from './saveFilterDialog';
import { useAppSelector } from 'stores/store';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFiltersContext } from 'context/FiltersContext';
import { endOfDay, startOfDay } from 'utils/funcs/formatDate';
import { errorHandling } from 'utils/funcs/errorHandling';

type QueryFnType = () => Promise<unknown>;

interface UseFiltersOptions {
  showSaveFilter?: boolean;
}

interface SearchButtonProps extends React.ComponentProps<typeof AppButton> {
  isAdvancedFilterCollapsed: boolean;
  onClick: () => void;
}

interface FilterProps<T = void> {
  filters: FilterTypes[];
  advancedFilters?: FilterTypes[];
  namePairs?: NamePair<T, FilterTypes>[];
  options?: UseFiltersOptions;
  isSearchButton?: boolean;
  onSearchHandler?: () => void;
}

interface FilterOptionsLists {
  vehicleTypes: string[];
  transactionStatuses: TransactionStatus[];
  tokenStatuses: TokenStatus[];
  paymentWindowStatuses: PaymentWindowStatus[];
  lines: string[];
  transactionTypes: string[];
  terminalStatuses: string[];
  cardIssuers: string[];
  cardSchemas: string[];
  cardOrigins: string[];
  paymentDevices: string[];
  paymentTypes: string[];
  cardProducts: string[];
}

type QueryObject = {
  queryKey: QueryKey;
  queryFn: QueryFunction;
  isLoading?: boolean;
};

type LoadQueries = {
  [K in `${FilterTypes}`]+?: QueryObject;
};

type PossibleFieldValues =
  | undefined
  | string
  | [number, number]
  | DateRange<Dayjs>
  | Date
  | boolean
  | [];

type FieldValues = ControllerRenderProps<
  // Field values
  Record<Partial<FilterTypes>, PossibleFieldValues>,
  // Field names
  FilterTypes
>;

type NamePair<T, R> = {
  queryname: keyof T | (keyof T)[];
  filterName: R;
};

export type FilterTypes =
  | 'VEHICLE_TYPE'
  | 'LINE'
  | 'CUSTOMER_NAME'
  | 'STATUS'
  | 'CUSTOMER_STATUS'
  | 'TOKEN_STATUS'
  | 'PAYMENT_WINDOW_STATUS'
  | 'TOKEN_ID'
  | 'DATE_RANGE'
  | 'DATE'
  | 'APPROVAL_CODE'
  | 'PAN'
  | 'AMOUNT_RANGE'
  | 'CURRENCY'
  | 'LOCATION'
  | 'PAYMENT_TERMINAL_NAME'
  | 'TERMINAL_ID'
  | 'VARIABLE_SYMBOL'
  | 'TRANSACTION_STATUS'
  | 'TRANSACTION_TYPE'
  | 'UUID'
  | 'TIME_SOURCE'
  | 'CARD_ISSUER'
  | 'CARD_SCHEMA'
  | 'PAYMENT_DEVICE'
  | 'PAYMENT_TYPE'
  | 'CARD_ORIGIN'
  | 'CARD_PRODUCT'
  | 'SEARCH_LOCATIONS'
  | 'SEARCH_TERMINAL'
  | 'MERCHANT_ID'
  | 'MERCHANT_NAME'
  | 'MERCHANT'
  | 'TERMINAL_STATUS'
  | 'TIP'
  | 'CASHBACK';

let hasLoggedError = false;

const createQueryFnWithErrorHandling = (queryFn: QueryFnType) => {
  return async () => {
    try {
      return await queryFn();
    } catch (error) {
      if (!hasLoggedError) {
        console.error(error);
        hasLoggedError = true;
      }
      return null;
    }
  };
};

const loadFilterOptions = (filters: FilterProps['filters'], merchantId: string) => {
  const loadCustomerStatuses: QueryObject = {
    queryKey: ['customerStatuses', { merchantId }],
    queryFn: createQueryFnWithErrorHandling(() => getCustomerFilterOptions(merchantId)),
  };
  const loadTokenTypes: QueryObject = {
    queryKey: ['tokenStatuses', { merchantId }],
    queryFn: createQueryFnWithErrorHandling(() => getTokenFilterOptions(merchantId)),
  };
  const loadTerminalStatuses: QueryObject = {
    queryKey: ['terminalStatuses'],
    queryFn: createQueryFnWithErrorHandling(() => getTerminalStatusFilterOptions()),
  };
  const loadPayWindowStatuses: QueryObject = {
    queryKey: ['paymentWindowStatuses', { merchantId }],
    queryFn: createQueryFnWithErrorHandling(() =>
      getPaymentWindowStatusFilterOptions(merchantId),
    ),
  };
  const loadTransactionStatus: QueryObject = {
    queryKey: ['transactionStatuses'],
    queryFn: createQueryFnWithErrorHandling(() => getTransactionStatusFilterOptions()),
  };
  const loadTransactionType: QueryObject = {
    queryKey: ['transactionTypes'],
    queryFn: createQueryFnWithErrorHandling(() => getTransactionTypeFilterOptions()),
  };
  const loadCardIssuer: QueryObject = {
    queryKey: ['cardIssuers'],
    queryFn: createQueryFnWithErrorHandling(() => getCardIssuerFilterOptions()),
  };
  const loadCardSchema: QueryObject = {
    queryKey: ['cardSchemas'],
    queryFn: createQueryFnWithErrorHandling(() => getCardSchemaFilterOptions()),
  };
  const loadCardProducts: QueryObject = {
    queryKey: ['cardProducts'],
    queryFn: createQueryFnWithErrorHandling(() => getCardProductsFilterOptions()),
  };
  const loadPaymentDevices: QueryObject = {
    queryKey: ['paymentDevices'],
    queryFn: createQueryFnWithErrorHandling(() => getPaymentDevicesFilterOptions()),
  };
  const loadPaymentTypes: QueryObject = {
    queryKey: ['paymentTypes'],
    queryFn: createQueryFnWithErrorHandling(() => getPaymentTypesFilterOptions()),
  };
  const loadCardOrigins: QueryObject = {
    queryKey: ['cardOrigins'],
    queryFn: createQueryFnWithErrorHandling(() => getCardOriginFilterOptions()),
  };
  const loadFunctions: LoadQueries = {
    TRANSACTION_STATUS: loadTransactionStatus,
    TRANSACTION_TYPE: loadTransactionType,
    CARD_ISSUER: loadCardIssuer,
    CARD_SCHEMA: loadCardSchema,
    CARD_ORIGIN: loadCardOrigins,
    CARD_PRODUCT: loadCardProducts,
    PAYMENT_DEVICE: loadPaymentDevices,
    PAYMENT_TERMINAL_NAME: loadPaymentTypes,
    CUSTOMER_STATUS: loadCustomerStatuses,
    TOKEN_STATUS: loadTokenTypes,
    PAYMENT_WINDOW_STATUS: loadPayWindowStatuses,
    TERMINAL_STATUS: loadTerminalStatuses,
  };

  const loading = [
    loadCustomerStatuses.isLoading,
    loadTokenTypes.isLoading,
    loadTerminalStatuses.isLoading,
    loadPayWindowStatuses.isLoading,
    loadTransactionStatus.isLoading,
    loadTransactionType.isLoading,
    loadCardIssuer.isLoading,
    loadCardSchema.isLoading,
    loadCardProducts.isLoading,
    loadPaymentDevices.isLoading,
    loadPaymentTypes.isLoading,
    loadCardOrigins.isLoading,
  ].some((isLoading) => isLoading);

  const callableFunctions: QueryObject[] = [];
  const loadFunctionsAsArray = Object.entries(loadFunctions);
  const filteredLoadFunctions = loadFunctionsAsArray.filter(([key]) => {
    return filters.includes(key as FilterTypes);
  });

  filteredLoadFunctions.forEach((keyValuePair) =>
    callableFunctions.push(keyValuePair[1]),
  );

  return {
    callableFunctions,
    queryKeys: callableFunctions.map((func) => func.queryKey[0] as string),
    loading,
  };
};

// Default state for filter. This can be changed bu customers prefference in future.
const renderCorrectFilterField = (
  filter: FilterTypes,
  filterLists: FilterOptionsLists,
  field: FieldValues,
  errors?: any,
) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.filters' });
  const { setFilters } = useFiltersContext();

  useEffect(() => {
    setFilters((prev: any) => ({
      ...prev,
      [filter]: field.value,
    }));
  }, [field.value]);

  switch (filter) {
    case 'AMOUNT_RANGE':
      return (
        <AmountField
          {...field}
          label={t(`labels.${filter}`)}
          value={(field.value as [number, number]) ?? []}
        />
      );
    case 'DATE_RANGE':
      return (
        <AppDateRangeSelect
          {...field}
          label={t(`labels.${filter}`)}
          value={isArray(field.value) ? (field.value as DateRange<Date>) : [null, null]}
          error={!!errors[filter]}
          helperText={errors[filter] ? errors[filter].message : ''}
        />
      );
    case 'DATE':
      return (
        <AppDatePicker
          {...field}
          label={t(`labels.${filter}`)}
          value={
            field?.value && typeof field?.value === 'string'
              ? field.value
              : dayjs().format('DD/MM/YYYY')
          }
        />
      );
    case 'CARD_ISSUER':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
          startAdornment={
            <InputAdornment position="end">
              <CreditCardIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseCardIssuer')}
          </MenuItem>
          {filterLists.cardIssuers.map((transStatus, index) => (
            <MenuItem key={index} value={transStatus}>
              {transStatus}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'CARD_ORIGIN':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
          startAdornment={
            <InputAdornment position="end">
              <CreditCardIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseCardOrigin')}
          </MenuItem>
          {filterLists.cardOrigins.map((transStatus, index) => (
            <MenuItem key={index} value={transStatus}>
              {transStatus}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'CARD_PRODUCT':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
          startAdornment={
            <InputAdornment position="end">
              <CreditCardIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseCardProduct')}
          </MenuItem>
          {filterLists.cardProducts.map((cardProduct, index) => (
            <MenuItem key={index} value={cardProduct}>
              {cardProduct}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'CARD_SCHEMA':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
          startAdornment={
            <InputAdornment position="end">
              <CreditCardIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseCardSchema')}
          </MenuItem>
          {filterLists.cardSchemas.map((transStatus, index) => (
            <MenuItem key={index} value={transStatus}>
              {transStatus}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'TIME_SOURCE':
      return (
        <AppToggleButton
          {...field}
          fullWidth
          value={(field.value as string) ?? ''}
          label={t(`labels.${filter}`)}
          errorText={''}
          sx={{
            width: '100%', // Set full width
            padding: (theme) => theme.spacing(5),
          }}
          options={Object.entries(TimeSource).map((timeSource) => ({
            value: timeSource[1],
            label: t(`labels.${timeSource[0]}`),
          }))}
        />
      );
    case 'TRANSACTION_STATUS':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          startAdornment={
            <InputAdornment position="end">
              <CheckCircleIcon />
            </InputAdornment>
          }
          {...field}
          value={field.value ?? ''}
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseTransactionStatus')}
          </MenuItem>
          {filterLists.transactionStatuses.map((transStatus, index) => (
            <MenuItem key={index} value={transStatus}>
              {transStatus}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'TIP':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          startAdornment={
            <InputAdornment position="end">
              <CreditCardIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseTipOption')}
          </MenuItem>
          <MenuItem value="1">{t('filterOptions.yes')}</MenuItem>
          <MenuItem value="0">{t('filterOptions.no')}</MenuItem>
        </AppSelect>
      );
    case 'CASHBACK':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
          startAdornment={
            <InputAdornment position="end">
              <CreditCardIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseCashbackOption')}
          </MenuItem>
          <MenuItem value="1">{t('filterOptions.yes')}</MenuItem>
          <MenuItem value="0">{t('filterOptions.no')}</MenuItem>
        </AppSelect>
      );
    case 'TERMINAL_STATUS':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseTerminalStatus')}
          </MenuItem>
          {filterLists.terminalStatuses.map((terminalStatus, index) => (
            <MenuItem key={index} value={terminalStatus}>
              {terminalStatus}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'TRANSACTION_TYPE':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
          startAdornment={
            <InputAdornment position="end">
              <SettingsIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseTransactionType')}
          </MenuItem>
          {filterLists.transactionTypes.map((transType, index) => (
            <MenuItem key={index} value={transType}>
              {transType}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'LINE':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseLine')}
          </MenuItem>
          {filterLists.lines.map((s, index) => (
            <MenuItem key={index} value={s}>
              {s}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'PAYMENT_DEVICE':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
          startAdornment={
            <InputAdornment position="end">
              <CreditCardIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.choosePaymentDevice')}
          </MenuItem>
          {filterLists.paymentDevices.map((s, index) => (
            <MenuItem key={index} value={s}>
              {s}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'PAYMENT_TYPE':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
          startAdornment={
            <InputAdornment position="end">
              <CreditCardIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.choosePaymentType')}
          </MenuItem>
          {filterLists.paymentTypes.map((s, index) => (
            <MenuItem key={index} value={s}>
              {s}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'PAYMENT_WINDOW_STATUS':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseStatus')}
          </MenuItem>
          {filterLists.paymentWindowStatuses.map((s, index) => (
            <MenuItem key={index} value={s}>
              {s}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'SEARCH_LOCATIONS':
      return (
        <AppTextField
          placeholder={t(`placeholders.${filter}`)}
          label={t(`labels.${filter}`)}
          {...field}
          ref={null}
        />
      );
    case 'TOKEN_STATUS':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseStatus')}
          </MenuItem>
          {filterLists.tokenStatuses.map((s, index) => (
            <MenuItem key={index} value={s}>
              {s}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'VEHICLE_TYPE':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseVehicleType')}
          </MenuItem>
          {filterLists.vehicleTypes.map((vehicleType, index) => (
            <MenuItem key={index} value={vehicleType}>
              {vehicleType}
            </MenuItem>
          ))}
        </AppSelect>
      );
    default:
      return (
        <AppTextField
          label={t(`labels.${filter}`)}
          {...field}
          value={field.value ?? ''}
          ref={null}
        />
      );
  }
};

const composeDefaultFormState = (filters: FilterProps['filters'], filterValues: any) => {
  const defaultState: Record<Partial<FilterTypes>, PossibleFieldValues> = {
    VEHICLE_TYPE: filterValues.VEHICLE_TYPE ?? '',
    LINE: filterValues.LINE ?? '',
    CUSTOMER_NAME: filterValues.CUSTOMER_NAME ?? '',
    STATUS: filterValues.STATUS ?? '',
    CUSTOMER_STATUS: filterValues.CUSTOMER_STATUS ?? '',
    TOKEN_STATUS: filterValues.TOKEN_STATUS ?? '',
    PAYMENT_WINDOW_STATUS: filterValues.PAYMENT_WINDOW_STATUS ?? '',
    TOKEN_ID: filterValues.TOKEN_ID ?? '',
    DATE_RANGE: filterValues.DATE_RANGE ?? [
      startOfDay.format('YYYY-MM-DDTHH:mm:ss'),
      endOfDay.format('YYYY-MM-DDTHH:mm:ss'),
    ],
    APPROVAL_CODE: filterValues.APPROVAL_CODE ?? '',
    PAN: filterValues.PAN ?? '',
    AMOUNT_RANGE: filterValues.AMOUNT_RANGE ?? [],
    CURRENCY: filterValues.CURRENCY ?? '',
    LOCATION: filterValues.LOCATION ?? '',
    PAYMENT_TERMINAL_NAME: filterValues.PAYMENT_TERMINAL_NAME ?? '',
    TERMINAL_ID: filterValues.TERMINAL_ID ?? '',
    VARIABLE_SYMBOL: filterValues.VARIABLE_SYMBOL ?? '',
    TRANSACTION_STATUS: filterValues.TRANSACTION_STATUS ?? '',
    TRANSACTION_TYPE: filterValues.TRANSACTION_TYPE ?? '',
    UUID: filterValues.UUID ?? '',
    TIME_SOURCE: filterValues.TIME_SOURCE ?? TimeSource['TERMINAL'],
    CARD_ISSUER: filterValues.CARD_ISSUER ?? '',
    CARD_SCHEMA: filterValues.CARD_SCHEMA ?? '',
    PAYMENT_DEVICE: filterValues.PAYMENT_DEVICE ?? '',
    PAYMENT_TYPE: filterValues.PAYMENT_TYPE ?? '',
    CARD_ORIGIN: filterValues.CARD_ORIGIN ?? '',
    CARD_PRODUCT: filterValues.CARD_PRODUCT ?? '',
    SEARCH_LOCATIONS: filterValues.SEARCH_LOCATIONS ?? '',
    SEARCH_TERMINAL: filterValues.SEARCH_TERMINAL ?? '',
    DATE: filterValues.DATE ?? dayjs().format('DD/MM/YYYY'),
    TERMINAL_STATUS: filterValues.TERMINAL_STATUS ?? '',
    MERCHANT_ID: filterValues.MERCHANT_ID ?? '',
    MERCHANT_NAME: filterValues.MERCHANT_NAME ?? '',
    MERCHANT: filterValues.MERCHANT ?? '',
    TIP: filterValues.TIP ?? '',
    CASHBACK: filterValues.CASHBACK ?? '',
  };
  const objKeys = Object.keys(defaultState) as FilterTypes[];

  // Create default state only for required filters
  objKeys.forEach(
    (defStateKey) => !filters.includes(defStateKey) && delete defaultState[defStateKey],
  );

  return defaultState;
};

const findIndexOfQueryKey = (queryKeyArray: QueryKey[], key: string) => {
  return queryKeyArray.flat().findIndex((value) => value === key);
};

const initialFilterOptionsLists = {
  vehicleTypes: [],
  status: [],
  customerStatuses: [],
  tokenStatuses: [],
  paymentWindowStatuses: [],
  lines: [],
  transactionTypes: [],
  transactionStatuses: [],
  cardIssuers: [],
  cardSchemas: [],
  cardOrigins: [],
  cardProducts: [],
  paymentDevices: [],
  paymentTypes: [],
  terminalStatuses: [],
};

function useFilters<T>({
  filters,
  advancedFilters,
  namePairs,
  options,
  isSearchButton = false,
  onSearchHandler,
}: FilterProps<T>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const { filterValues, defaultState } = useFiltersContext();
  const { merchantId } = useAppSelector((state) => state.user);
  const [filterOptionsAreLoading, setFilterOptionsAreLoading] = useState(false);
  const { t } = useTranslation('common', { keyPrefix: 'components.filters' });
  const [isAdvancedFilterCollapsed, setIsAdvancedFilterCollapsed] = useState(true);
  const [isCreateFilterDialogOpen, setIsCreateFilterDialogOpen] = useState(false);
  const { callableFunctions, queryKeys, loading } = loadFilterOptions(
    [...filters, ...(advancedFilters || [])],
    merchantId,
  );
  const filterOptions = useQueries(callableFunctions);
  const [savedFilters, setSavedFilters] = useState<FilterObject[]>([]);
  const [mySavedFilter, setMySavedFilter] = useState<number>();

  const closeCreateFilterDialog = () => setIsCreateFilterDialogOpen(false);

  const loadSavedFilters = async () => {
    try {
      const data = await getFilters();
      setSavedFilters(data);
    } catch (error) {
      errorHandling(error);
    }
  };

  const deleteFilterFunc = async (filterId: number) => {
    try {
      await deleteFilter(filterId);
      const savedFiltersWithoutDeletedFilter = savedFilters.filter(
        (filter) => filter.id !== filterId,
      );
      setSavedFilters(savedFiltersWithoutDeletedFilter);
    } catch (error) {
      errorHandling(error);
    }
  };

  const defaultValues = composeDefaultFormState(
    [...filters, ...(advancedFilters || [])],
    filterValues,
  );

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  useEffect(() => {
    if (options?.showSaveFilter === true) {
      loadSavedFilters();
    }
    if (filterValues) {
      Object.keys(filterValues).some((key: any) => {
        setValue(key, filterValues[key]);
      });
    }

    const filteredArr = Object.entries(filterValues).filter(([key]) =>
      advancedFilters?.includes(key as FilterTypes),
    ) as [FilterTypes, string][];

    filteredArr.forEach(([key, value]) => {
      if (key !== 'TIME_SOURCE' && value.length > 0) {
        setIsAdvancedFilterCollapsed(false);
      }
    });
  }, []);

  useEffect(() => {
    if (filterOptions.some((option) => option.isLoading !== filterOptionsAreLoading)) {
      setFilterOptionsAreLoading(!filterOptionsAreLoading);
    }
  }, [filterOptions]);

  const SearchButton: React.FC<SearchButtonProps> = ({
    isAdvancedFilterCollapsed,
    onClick,
    ...props
  }) => {
    return (
      <AppButton
        size="medium"
        startIcon={<SearchIcon />}
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.common.white,
          ':hover': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.8),
          },
          ':disabled': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.3),
            color: '#fff',
          },
          width: 'fit-content',
          alignSelf: 'center',
          marginTop: !isAdvancedFilterCollapsed ? '2rem' : 0,
        }}
        onClick={onClick}
        {...props}
      >
        {t('searchButton')}
      </AppButton>
    );
  };

  const handleReset = () => {
    reset(defaultState);
  };

  const filterOptionsListsMemoized = useMemo(() => {
    if (filterOptions.some((option) => option.isLoading !== false)) {
      return initialFilterOptionsLists;
    } else {
      const flattenQueryKeys = queryKeys.flat() as (keyof FilterOptionsLists)[];
      const newFilterOptions = { ...initialFilterOptionsLists };
      flattenQueryKeys.map((queryKey) => {
        const queryKeyData = filterOptions[findIndexOfQueryKey(queryKeys, queryKey)];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const queryKeyDataValues = Object.values(queryKeyData.data ?? {});
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (newFilterOptions[queryKey] = queryKeyDataValues);
      });
      return newFilterOptions;
    }
  }, [filterOptions]);

  // If pairs provided, refactor keys of filterValues to be same as in namePairs
  const refactorFilterValues = (
    filterValuesBeforeRefactor: typeof filterValues,
    namePairs: {
      filterName: keyof typeof filterValues;
      queryname: keyof T | (keyof T)[];
    }[],
  ) => {
    const refactoredFilter: Partial<T> = {};
    if (filterValues && namePairs.length > 0) {
      namePairs.map((namePair) => {
        if (namePair?.queryname && namePair?.filterName) {
          // TODO: Refactor
          if (
            isArray(namePair?.queryname) &&
            namePair?.queryname.length > 0 &&
            isArray(filterValuesBeforeRefactor[namePair.filterName])
          ) {
            namePair?.queryname.map((q, index) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              refactoredFilter[q] =
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                filterValuesBeforeRefactor[namePair.filterName][index];
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            refactoredFilter[namePair.queryname] =
              filterValuesBeforeRefactor[namePair.filterName];
          }
        }
      });
      return refactoredFilter;
    } else {
      return filterValuesBeforeRefactor;
    }
  };

  const filtersToRender = (
    <>
      <SaveFilterDialog
        isDialogOpen={isCreateFilterDialogOpen}
        handleClose={closeCreateFilterDialog}
        onSubmit={async (name: string) => {
          const filteredValues = Object.keys(filterValues as Record<FilterTypes, any>)
            .filter((key): key is FilterTypes => filters.includes(key as FilterTypes))
            .reduce((obj, key) => {
              obj[key] = filterValues[key];
              return obj;
            }, {} as Record<FilterTypes, PossibleFieldValues>);

          try {
            const response = await saveFilters(filteredValues, name);
            const transactionsFilters = [...savedFilters];
            transactionsFilters.push(response.data);
            setSavedFilters(transactionsFilters);
          } catch (error) {
            errorHandling(error);
          }
          closeCreateFilterDialog();
        }}
      />
      <Card sx={{ p: [3, 2], marginY: 2, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Typography component="div">{t('title')}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {(options?.showSaveFilter ?? false) && (
              <>
                <Box>
                  <AppButton
                    size="small"
                    startIcon={<SaveIcon />}
                    color="secondary"
                    onClick={() => setIsCreateFilterDialogOpen(true)}
                  >
                    {t('saveFilter')}
                  </AppButton>
                </Box>
                <Box>
                  <AppButton
                    size="small"
                    startIcon={<CancelIcon />}
                    color="secondary"
                    onClick={handleReset}
                  >
                    {t('reset')}
                  </AppButton>
                </Box>
                <AppSelect
                  size="small"
                  selectVariant="asButton"
                  displayEmpty
                  name={'myFilters'}
                  onChange={(e: SelectChangeEvent<any>) => {
                    setMySavedFilter(e.target.value);
                  }}
                  value={mySavedFilter ?? ''}
                >
                  <MenuItem key={-1} value={''}>
                    {t('myFilters')}
                  </MenuItem>
                  {savedFilters.length > 0 ? (
                    savedFilters.map((savedFilter, index) => (
                      <MenuItem
                        value={savedFilter.id}
                        onClick={() => {
                          Object.entries(savedFilter.filter).forEach((entry) =>
                            setValue(entry[0] as FilterTypes, entry[1]),
                          );
                        }}
                        key={index}
                      >
                        {`Filter ${savedFilter.id}: ${savedFilter.name}`}
                        {savedFilter.id !== mySavedFilter && (
                          <IconButton
                            onClick={async (e) => {
                              e.stopPropagation();
                              await deleteFilterFunc(savedFilter.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>{t('noSavedFilters')}</MenuItem>
                  )}
                </AppSelect>
              </>
            )}
          </Box>
        </Box>
        <Box>
          {filters.length > 0 && (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: isMobile ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
                gridTemplateRows: '1fr',
                columnGap: 1,
                rowGap: 1,
              }}
            >
              {filters.map((filter, index) => {
                return (
                  <Box key={index}>
                    <Controller
                      name={filter}
                      control={control}
                      rules={{
                        validate: (value: PossibleFieldValues): string | true => {
                          if (filter === 'DATE_RANGE') {
                            if (Array.isArray(value) && value.length === 2) {
                              const [start, end]: any = value;
                              if (!start || !end) {
                                return 'validation.required';
                              }
                              if (start >= end) {
                                return 'validation.invalidDateOrder';
                              }
                              const maxRangeInDays = 90;
                              const startDate = new Date(start);
                              const endDate = new Date(end);
                              const rangeInDays =
                                (endDate.getTime() - startDate.getTime()) /
                                (1000 * 60 * 60 * 24);
                              if (rangeInDays > maxRangeInDays) {
                                return 'validation.maxDateRange';
                              }
                            } else {
                              return 'validation.invalidFormat';
                            }
                          }
                          return true;
                        },
                      }}
                      render={({ field }) =>
                        renderCorrectFilterField(
                          filter,
                          filterOptionsListsMemoized,
                          field,
                          errors,
                        )
                      }
                    />
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
        {advancedFilters && (
          <div>
            <Typography
              component={'span'}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                marginY: 2,
              }}
              onClick={() => setIsAdvancedFilterCollapsed(!isAdvancedFilterCollapsed)}
            >
              <KeyboardArrowDownIcon
                sx={{
                  transition: '0.45s ease',
                  transform: isAdvancedFilterCollapsed
                    ? 'rotate(0deg)'
                    : 'rotate(-180deg)',
                }}
              />{' '}
              <Typography component={'span'}>{t('advancedFiltering')}</Typography>
            </Typography>
          </div>
        )}
        <Collapse in={!isAdvancedFilterCollapsed}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: isMobile
                ? '1fr'
                : isTablet
                ? 'repeat(2, 1fr)'
                : 'repeat(3, 1fr)',
              gridTemplateRows: '1fr',
              columnGap: 1,
              rowGap: 1,
            }}
          >
            {advancedFilters &&
              advancedFilters.length > 0 &&
              advancedFilters.map((advancedFilter, index) => {
                return (
                  <Box key={index}>
                    <Controller
                      control={control}
                      name={advancedFilter}
                      render={({ field }) =>
                        renderCorrectFilterField(
                          advancedFilter,
                          filterOptionsListsMemoized,
                          field,
                        )
                      }
                    />
                  </Box>
                );
              })}
          </Box>
        </Collapse>
        {isSearchButton && (
          <SearchButton
            isAdvancedFilterCollapsed={isAdvancedFilterCollapsed}
            onClick={handleSubmit(onSearchHandler!)}
            disabled={!!Object.keys(errors).length}
          />
        )}
      </Card>
    </>
  );
  return {
    reset: reset,
    loading: loading,
    setValue: setValue,
    filters: filtersToRender,
    filterValues: namePairs
      ? refactorFilterValues(filterValues, namePairs)
      : filterValues,
  };
}

export default useFilters;
