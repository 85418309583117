import { useState, useEffect, useCallback } from 'react';
import { Location, NavigateFunction } from 'react-router-dom';

interface UsePaginationReturn {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
}

interface UsePaginationProps {
  location: Location;
  navigate: NavigateFunction;
}

const usePagination = ({
  location,
  navigate,
}: UsePaginationProps): UsePaginationReturn => {
  const initialParams = new URLSearchParams(location.search);
  const initialPage = Number(initialParams.get('page')) || 0;
  const initialPageSize = Number(initialParams.get('pageSize')) || 5;

  const [page, setPage] = useState<number>(initialPage);
  const [pageSize, setPageSize] = useState<number>(initialPageSize);

  const updateUrlParams = useCallback(() => {
    const params = new URLSearchParams(location.search);

    if (page !== initialPage) {
      params.set('page', String(page));
    }
    if (pageSize !== initialPageSize) {
      params.set('pageSize', String(pageSize));
    }

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [
    page,
    pageSize,
    navigate,
    location.search,
    location.pathname,
    initialPage,
    initialPageSize,
  ]);

  useEffect(() => {
    updateUrlParams();
  }, [page, pageSize, updateUrlParams]);

  return { page, setPage, pageSize, setPageSize };
};

export default usePagination;
