import {
  Avatar,
  ClickAwayListener,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SvgIconTypeMap,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useState } from 'react';
import { Lock, Logout } from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useTranslation } from 'react-i18next';
// import { useAuth } from 'oidc-react';
import { COLORS } from 'theme/colors';
import { useGlobalContext } from 'GlobalContext';
import { useAppSelector } from 'stores/store';
import { errorHandling } from 'utils/funcs/errorHandling';

interface MenuItem {
  label: string;
  onClick: () => void;
  icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & {
    muiName: string;
  };
}

export const UserAvatar = () => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation('common', { keyPrefix: 'components.avatar' });
  const { oidcRpClient } = useGlobalContext();
  const { accountUrl } = useAppSelector((state) => state.user);

  const menuItems = [
    {
      label: 'changePassword',
      icon: <Lock />,
      onClick: () => {
        window.location.href = accountUrl;
      },
    },
    // {
    //   label: 'helpAndFeedback',
    //   icon: <Info />,
    //   onClick: () => {},
    // },
    {
      label: 'logout',
      icon: <Logout />,
      onClick: () => {
        oidcRpClient
          .logout({
            postLogoutRedirectUri: `${window.location.origin}/logout`,
            state: 'logout',
          })
          .then(() => {
            window.location.reload();
          })
          .catch((e) => {
            errorHandling(e);
          });
      },
    },
  ];

  return (
    <>
      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setIsPopperOpen(!isPopperOpen);
        }}
        sx={{ p: 0 }}
      >
        <Avatar style={{ backgroundColor: COLORS.GREY.LIGHT }} alt="Avatar">
          <PersonIcon color="secondary" />
        </Avatar>
      </IconButton>
      <Popper
        style={{ zIndex: 10 }}
        open={isPopperOpen}
        placement="bottom-start"
        disablePortal={false}
        anchorEl={anchorEl}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (isPopperOpen) setIsPopperOpen(false);
          }}
        >
          <Paper sx={{ width: 320, maxWidth: '100%' }}>
            <MenuList>
              {menuItems.map((menuItem, index) => (
                <MenuItem key={index} onClick={menuItem.onClick}>
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText>{t(menuItem.label)}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
