import axios from 'axios';

const url = `${window.appConfig.REACT_APP_API_KEY}/api/${window.appConfig.REACT_APP_API_VERSION}`;

const apiConfig = {
  baseURL: url,
};

const api = axios.create({ ...apiConfig });
const notAthenticatedApi = axios.create({ ...apiConfig });

const addAuthData = (token: string | null | undefined) => {
  api.interceptors.request.use(
    (config) => {
      const roleId = window.userRoleId;
      if (token) {
        config.headers.set({
          ...config.headers,
          Authorization: `Bearer ${token}`,
          'x-role-id': roleId,
        });
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};

export { api, notAthenticatedApi, addAuthData };
