import React from 'react';
import { Controller } from 'react-hook-form';

const FormController: React.FC<{
  name: string;
  children: React.ReactElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  checkbox?: boolean;
}> = ({ name, children, control }) => (
  <div style={{ width: '100%' }}>
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) =>
        React.cloneElement(children, {
          onChange,
          value,
          error: !!error?.message,
          errorText: error?.message,
          checked: value,
        })
      }
    />
  </div>
);

export default FormController;
