import { GridSortModel } from '@mui/x-data-grid-pro';
import { NavigateFunction } from 'react-router-dom';
import { TSortModel } from 'types/commonTypes';

interface HandleSortModelChangeProps {
  params: URLSearchParams;
  navigate: NavigateFunction;
  setQueryOptions: (options: TSortModel | null) => void;
  setPage: (page: number) => void;
}

export function createHandleSortModelChange({
  params,
  navigate,
  setQueryOptions,
  setPage,
}: HandleSortModelChangeProps) {
  return (sortModel: GridSortModel) => {
    if (sortModel.length > 0) {
      const newSortOption = sortModel[0];
      params.set('queryOptions', JSON.stringify(newSortOption));
      setQueryOptions(newSortOption as TSortModel);
      setPage(0);
    } else {
      setQueryOptions(null);
      params.delete('queryOptions');
    }
    navigate({ search: params.toString() }, { replace: true });
  };
}
