import {
  DialogTitle,
  Typography,
  IconButton,
  Box,
  useTheme,
  alpha,
  MenuItem,
  styled,
} from '@mui/material';
import AppButton from 'components/appButton';
import AppTextField from 'components/form/textField';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { WidgetState } from '..';
import { deleteWidget, updateWidget } from 'api/endpoints/widgets';
import AppSelect from 'components/form/select';
import { TimePeriod, WidgetTypes } from 'types/enumTypes';
import { LocationSelect } from 'components/form/locationSelect';
import { getCurrencies, useGetLocationsIdentifiersQuery } from 'api/endpoints/locations';
import { useAppSelector } from 'stores/store';
import { errorHandling } from 'utils/funcs/errorHandling';
import FormController from 'components/formController';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { COLORS } from 'theme/colors';
import { validCurrencyTypes } from 'components/widgetContainer/dialogs/createWidget';
import { LocationIdentifier } from 'types/commonTypes';
import WidgetLoader from 'components/widgetLoader';

const ButtonsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,
}));

const StyledMenuItem = styled(MenuItem)(({ theme: { palette } }) => ({
  fontSize: '0.9rem',
  fontWeight: 400,
  color: COLORS.GREY.MAIN,
  '&:hover': {
    backgroundColor: palette.background.lightGrey,
  },
}));

interface UpdateWidgetProps {
  handleClose: () => void;
  setWidgetState: React.Dispatch<React.SetStateAction<WidgetState>>;
  widgetState: WidgetState;
  updateCallback?: () => void;
  setTimeValue?: (timePeriod: TimePeriod) => void;
}

const NAME = 'name';
const LOCATIONS = 'locations';
const TIME_PERIOD = 'time_period';
const CURRENCY = 'currency';

type FormValues = {
  [NAME]: string;
  [LOCATIONS]: string[];
  [TIME_PERIOD]: TimePeriod;
  [CURRENCY]: string;
};

export const UpdateWidget: React.FC<UpdateWidgetProps> = ({
  handleClose,
  widgetState,
  setWidgetState,
  updateCallback,
  setTimeValue,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.updateWidget' });
  const { t: tEnum } = useTranslation('common', { keyPrefix: 'enums' });
  const { t: tValidation } = useTranslation('', {
    keyPrefix: 'validation',
  });
  const [isLoading, setIsLoading] = useState(false);
  const { networkId } = useAppSelector((state) => state.user);
  const theme = useTheme();
  const { spacing } = theme;
  const { data: locationsIdentifiers } = useGetLocationsIdentifiersQuery(networkId);
  const [currencies, setCurrencies] = useState<AxiosResponse<string[]>>();
  const isCurrencyRequired = validCurrencyTypes.includes(widgetState.type);

  const defaultLocations: LocationIdentifier[] = locationsIdentifiers
    ? locationsIdentifiers.filter((object) =>
        widgetState.settings.locations.includes(object.identifier),
      )
    : [];

  const defaultLocationsValues = defaultLocations.map(
    (l: LocationIdentifier) => l.identifier,
  );

  const defaultValues = {
    [NAME]: widgetState.name,
    [LOCATIONS]: defaultLocationsValues,
    [TIME_PERIOD]: widgetState.settings.time_period,
    [CURRENCY]: widgetState.settings.currency,
  };

  const deleteW = async (widgetId: number) => {
    setIsLoading(true);
    try {
      await deleteWidget(widgetId);
    } catch (error) {
      errorHandling(error);
    } finally {
      if (updateCallback) updateCallback();
      handleClose();
      setIsLoading(false);
    }
  };

  const showTimePeriodTypes = [
    WidgetTypes['NETWORK_LOAD'],
    WidgetTypes['CARDS'],
    WidgetTypes['STATES_OF_TRANSACTIONS'],
    WidgetTypes['ACTIVITY_OVERVIEW'],
  ];

  const isShowTimePeriod = showTimePeriodTypes.includes(widgetState.type);

  const schema = Joi.object({
    [NAME]: Joi.string().trim().allow(''),

    [TIME_PERIOD]: Joi.string()
      .trim()
      .messages({
        'any.required': tValidation('required'),
        'string.empty': tValidation('required'),
      }),
    [CURRENCY]: Joi.string(),
    [LOCATIONS]: Joi.array()
      .min(1)
      .required()
      .messages({
        'any.required': tValidation('required'),
        'array.empty': tValidation('required'),
        'array.min': tValidation('required'),
      }),
  }).options({ allowUnknown: true });

  const form = useForm<FormValues>({
    resolver: joiResolver(schema),
    defaultValues: defaultValues,
    mode: 'onBlur',
  });

  const { control, handleSubmit, watch } = form;

  const update = async (formData: FormValues) => {
    setIsLoading(true);
    const id = widgetState.id;
    try {
      await updateWidget(id, {
        type: widgetState.type,
        position: widgetState.position,
        name: formData.name,
        settings: {
          time_period: formData.time_period,
          card_view: widgetState.settings.card_view,
          locations: formData.locations,
          currency: formData.currency,
        },
      });
      setWidgetState({
        ...widgetState,
        name: formData.name,
        settings: {
          ...widgetState.settings,
          time_period: formData.time_period,
          locations: formData.locations,
          currency: formData.currency,
        },
      });
      if (updateCallback) updateCallback();
      if (setTimeValue && formData.time_period) setTimeValue(formData.time_period);
      handleClose();
    } catch (error) {
      errorHandling(error);
    } finally {
      setIsLoading(false);
    }
  };

  const locations = watch(LOCATIONS);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCurrencies(networkId, locations);
        setCurrencies(data);
      } catch (error) {
        errorHandling(error);
      }
    };
    if (
      locations?.length &&
      isCurrencyRequired &&
      locations.every((item) => typeof item === 'string')
    ) {
      fetchData();
    }
  }, [networkId, locations]);

  const currenciesList = currencies?.data?.length
    ? currencies.data
    : [widgetState.settings.currency];

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: isLoading ? 'hidden' : 'auto',
      }}
    >
      {isLoading && <WidgetLoader />}
      <form onSubmit={handleSubmit(update)}>
        <DialogTitle
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h5">{t('widgetTitle')}</Typography>
          <IconButton key="Settings" onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box
          sx={{ padding: spacing(3), display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <FormController name={NAME} control={control}>
            <AppTextField label={t('customName')} />
          </FormController>
          {isShowTimePeriod && (
            <FormController name={TIME_PERIOD} control={control}>
              <AppSelect fullWidth label={t('chooseTimePeriod')} required>
                {Object.entries(TimePeriod).map((entry, index) => (
                  <MenuItem key={index} value={entry[1]}>
                    {tEnum(`timePeriod.${entry[1]}`)}
                  </MenuItem>
                ))}
              </AppSelect>
            </FormController>
          )}

          <FormController name={LOCATIONS} control={control}>
            <LocationSelect
              defaultValue={defaultLocations}
              label={t('chooseLocations')}
              locations={locationsIdentifiers || []}
              required
            />
          </FormController>
          {isCurrencyRequired && (
            <FormController name={CURRENCY} control={control}>
              <AppSelect fullWidth label={t('currency')} required>
                {currenciesList.map((entry, index) => (
                  <StyledMenuItem key={index} value={entry}>
                    {entry}
                  </StyledMenuItem>
                ))}
              </AppSelect>
            </FormController>
          )}
          <ButtonsContainer>
            <AppButton
              disabled={isLoading}
              variant="contained"
              color="error"
              fullWidth
              onClick={() => deleteW(widgetState.id)}
              startIcon={<DeleteIcon />}
            >
              {t('delete')}
            </AppButton>
            <AppButton
              disabled={isLoading}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                padding: theme.spacing(2, 8),
                ':hover': {
                  backgroundColor: alpha(theme.palette.secondary.main, 0.8),
                },
              }}
              type="submit"
              variant="contained"
            >
              {t('confirm')}
            </AppButton>
          </ButtonsContainer>
        </Box>
      </form>
    </Box>
  );
};
