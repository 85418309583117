import { styled, Typography, useTheme } from '@mui/material';

interface FormFieldProps {
  label: React.ReactNode;
  error: boolean | undefined;
  errorText?: string;
  required: boolean | undefined;
  optional?: boolean;
  htmlFor?: string;
}

const TopInfoWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'label' })<{
  label: FormFieldProps['label'];
}>(({ label, theme: { spacing } }) => ({
  display: 'flex',
  marginBottom: label ? spacing(0.7) : 0,
}));

const FormField: React.FC<FormFieldProps> = ({
  children,
  label,
  error,
  errorText,
  htmlFor,
  required,
}) => {
  const theme = useTheme();

  return (
    <div>
      <TopInfoWrapper label={label}>
        {label && (
          <Typography
            fontSize={'0.9rem'}
            fontWeight={700}
            color={theme.palette.text.lightGrey.main}
            noWrap
            component="label"
            htmlFor={htmlFor}
            display={'flex'}
          >
            {label}
            {required && <span>*</span>}
          </Typography>
        )}
      </TopInfoWrapper>
      {children}
      {error && errorText && <Typography color="error">{errorText}</Typography>}
    </div>
  );
};

export default FormField;
