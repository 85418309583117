import { Typography, useTheme } from '@mui/material';
import { useRoutesObject } from 'pages/appRoutes/appRoutes';
import React from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs';
import useMediaQuery from '@mui/material/useMediaQuery';

interface BreadCrumbsProps {
  customBreadCrumbs?: { index: number; breadCrumbLabel: string; route: string }[];
}

const returnCorrectBreadCrumb = (
  customBreadCrumbs: BreadCrumbsProps['customBreadCrumbs'],
  breadCrumbIndex: number,
  breadCrumbItem: BreadcrumbData<string>,
  isLastBreadCrumb: boolean,
) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { breadcrumb } = breadCrumbItem;
  const foundCustomBreadCrumb = customBreadCrumbs?.find(
    (customBread) => customBread.index === breadCrumbIndex,
  );

  if (isLastBreadCrumb) {
    return (
      <Typography
        variant="h4"
        width={'100%'}
        textAlign={isMobile ? 'center' : 'start'}
        component="span"
      >
        {foundCustomBreadCrumb ? foundCustomBreadCrumb.breadCrumbLabel : breadcrumb}
      </Typography>
    );
  } else {
    return (
      <>
        <Link
          style={{ textDecoration: 'none' }}
          to={foundCustomBreadCrumb ? foundCustomBreadCrumb.route : breadCrumbItem.key}
        >
          {' '}
          <Typography
            color="text.lightGrey.main"
            variant="h4"
            component="span"
            sx={{
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {foundCustomBreadCrumb ? foundCustomBreadCrumb.breadCrumbLabel : breadcrumb}
          </Typography>
        </Link>{' '}
        <Typography sx={{ mx: 1 }} color="primary.main" variant="h4" component="span">
          /
        </Typography>
      </>
    );
  }
};

// Automaticaly generate breadcrumbs, custom breadcrumb routes and labels can be passed in customBreadCrumbs array
export const AppBreadcrumbs: React.FC<BreadCrumbsProps> = ({
  customBreadCrumbs = [],
}) => {
  const routesObject = useRoutesObject();
  const breadcrumbs = useBreadcrumbs(routesObject, {
    excludePaths: ['/'],
  });

  return (
    <>
      {breadcrumbs.length > 0 && (
        <React.Fragment>
          {returnCorrectBreadCrumb(customBreadCrumbs, 0, breadcrumbs[0], false)}
        </React.Fragment>
      )}

      {breadcrumbs.length > 0 && (
        <Typography variant="h4" component="span">
          {customBreadCrumbs.length > 0
            ? customBreadCrumbs[customBreadCrumbs.length - 1].breadCrumbLabel
            : breadcrumbs[breadcrumbs.length - 1].breadcrumb}
        </Typography>
      )}
    </>
  );
};
