import { api } from '../index';
import {
  Refund,
  REVERSAL,
  Transaction,
  TransactionDetail,
  TransactionLifecycle,
  TransactionOverviewResponse,
  UserRole,
} from 'types/commonTypes';
import { useQuery } from 'react-query';
import { FileType, getFile } from 'utils/funcs/downloadFile';
import { getNotEmptyParams } from 'utils/funcs/convert';
import { ApiResponse } from 'types/utilityInterfaces';

export type TOrderColumn =
  | 'date_terminal'
  | 'amount'
  | 'approval_code'
  | 'status'
  | 'type'
  | 'pan'
  | 'uuid'
  | 'terminal_identifier';

export interface TransactionsFilter {
  limit: number;
  offset: number;
  approval_code: string;
  pan: string;
  amount_from: number;
  amount_to: number;
  curency: string;
  date_from: string;
  date_to: string;
  use_date_terminal: boolean;
  location: string;
  merchant: string;
  terminal: string;
  status: string;
  uuid: string;
  transaction_type: string;
  card_issuer: string;
  card_schema: string;
  card_origin: string;
  card_product: string;
  payment_type: string;
  payment_device: string;
  order_type: string;
  order_column: TOrderColumn;
  'locations[]': string | undefined;
  vs: string;
}

export interface TTransactionsCollection {
  data: Transaction[];
  paging: {
    limit: number;
    offset: number;
    count: number;
    nextPage: boolean;
  };
}

export const getTransactionsCollection = async (
  networkId: string,
  params: Partial<TransactionsFilter>,
) => {
  const notEmptyParams = getNotEmptyParams(params);
  const response = await api.get<TTransactionsCollection>(
    `/networks/${networkId}/transactions`,
    {
      params: notEmptyParams,
    },
  );
  return response.data;
};

export const useTransactionsCollectionsQuery = (
  networkId: string,
  currentRole: UserRole,
  filterParams: Partial<TransactionsFilter> = {},
) =>
  useQuery(
    ['getTransactions', networkId, currentRole, filterParams],
    () => getTransactionsCollection(networkId, filterParams),
    {
      staleTime: 0,
    },
  );

const getTransactionDetail = async (
  networkId: string,
  merchantId: string,
  locationId: string,
  terminalId: string,
  transactionId: string,
) => {
  const response = await api.get<TransactionDetail>(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/terminals/${terminalId}/transactions/${transactionId}`,
  );
  return response.data;
};

export const useTransactionDetailQuery = (
  networkId: string,
  merchantId: string,
  locationId: string,
  terminalId: string,
  transactionId: string,
) =>
  useQuery(
    [
      'getTransactionDetail',
      networkId,
      merchantId,
      locationId,
      terminalId,
      transactionId,
    ],
    () =>
      getTransactionDetail(networkId, merchantId, locationId, terminalId, transactionId),
  );

export const exportTransactions = async (
  networkId: string,
  type: FileType,
  filterParams = {},
) => {
  const notEmptyParams = getNotEmptyParams(filterParams);
  return await getFile(
    `/networks/${networkId}/transactions/export/${type}`,
    type,
    'Transaction',
    notEmptyParams,
  );
};

export const exportTransactionReceipt = async (
  networkId: string,
  merchantId: string,
  locationId: string,
  terminalId: string,
  transactionId: string,
) => {
  return await getFile(
    `networks/${networkId}/merchants/${merchantId}/locations/${locationId}/terminals/${terminalId}/transactions/${transactionId}/receipt`,
    'pdf',
    'Transaction',
  );
};

export const getTransactionOverviewForLocation = async (
  networkId: string,
  merchantId: string,
  locationId: string,
  params: { timeframe: string; currency: string },
) => {
  const notEmptyParams = getNotEmptyParams(params);

  const response = await api.get<TransactionOverviewResponse>(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/overview`,
    { params: notEmptyParams },
  );
  return response.data;
};

export const getTransactionOverviewForTerminal = async (
  networkId: string,
  merchantId: string,
  locationId: string,
  terminalId: string,
  params: { timeframe: string; currency: string },
) => {
  const notEmptyParams = getNotEmptyParams(params);

  const response = await api.get<TransactionOverviewResponse>(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/terminals/${terminalId}/overview`,
    { params: notEmptyParams },
  );
  return response.data;
};

export const getRefundData = async (
  networkId: string,
  merchantId: string,
  locationId: string,
  terminalId: string,
  transactionUuid: string,
) => {
  const response = await api.get(
    `networks/${networkId}/merchants/${merchantId}/locations/${locationId}/terminals/${terminalId}/transactions/${transactionUuid}/refund-data`,
  );
  return response.data;
};

export const refundAction = async (
  networkId: string,
  merchantId: string,
  locationId: string,
  terminalId: string,
  transactionUuid: string,
  refundData: Refund | REVERSAL,
) => {
  const response = await api.post<Refund | REVERSAL>(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/terminals/${terminalId}/transactions/${transactionUuid}/refund`,
    {
      ...refundData,
    },
  );
  return response;
};

const getTransactionLifecycle = async (
  networkId: string,
  merchantId: string,
  locationId: string,
  terminalId: string,
  transactionUuid: string,
  params: Record<string, unknown>,
) => {
  const notEmptyParams = getNotEmptyParams(params);

  const response = await api.get<ApiResponse<TransactionLifecycle[]>>(
    `networks/${networkId}/merchants/${merchantId}/locations/${locationId}/terminals/${terminalId}/transactions/${transactionUuid}/lifecycle`,
    {
      params: notEmptyParams,
    },
  );
  return response.data;
};

export const getTransactionLifecycleQuery = (
  networkId: string,
  merchantId: string,
  locationId: string,
  terminalId: string,
  transactionUuid: string,
  params = {},
) =>
  useQuery(
    [
      'getTransactionLifecycle',
      networkId,
      merchantId,
      locationId,
      terminalId,
      transactionUuid,
      params,
    ],
    () =>
      getTransactionLifecycle(
        networkId,
        merchantId,
        locationId,
        terminalId,
        transactionUuid,
        params,
      ),
  );
