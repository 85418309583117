import { Box } from '@mui/material';
import FormField from '../formField';
import AppTextField from 'components/form/textField';
import { forwardRef } from 'react';

interface AmountFieldProps {
  value: [number | null, number | null];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void;
  label: string;
}

const AmountField: React.ForwardRefRenderFunction<any, AmountFieldProps> = (
  { value, onChange, label },
  ref,
) => {
  const inputId = `app-text-field-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <FormField
      label={label}
      error={false}
      errorText={''}
      required={false}
      htmlFor={inputId}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <AppTextField
          ref={ref}
          value={value[0] ?? ''}
          onChange={(event) => {
            const newValue = parseFloat(event.target.value) || null;
            const updatedValue = [newValue, value[1]];
            onChange(updatedValue);
          }}
          id={inputId}
          InputProps={{ type: 'number' }}
        />
        {' - '}
        <AppTextField
          value={value[1] ?? ''}
          onChange={(event) => {
            const newValue = parseFloat(event.target.value) || null;
            const updatedValue = [value[0], newValue];
            onChange(updatedValue);
          }}
          InputProps={{ type: 'number' }}
        />
      </Box>
    </FormField>
  );
};

export default forwardRef(AmountField);
