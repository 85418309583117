import DashboardPage from 'pages/dashBoardPage';
import { LocationDetailPage } from 'pages/detailPages/locationDetail';
import { TerminalDetailPage } from 'pages/detailPages/terminalDetail';
import { TransactionDetailPage } from 'pages/detailPages/transactionDetail';
import { LocationsPage } from 'pages/locationsPage';
import { NotificationsPage } from 'pages/notificationsPage';
import { TerminalsPage } from 'pages/terminalsPage';
import { TransactionsPage } from 'pages/transactionsPage';
import { useTranslation } from 'react-i18next';
import { useRoutes } from 'react-router';
import {
  CALLBACK_URL,
  LOCATIONS_URL,
  LOCATION_DETAIL_URL,
  LOGOUT_URL,
  NAVBAR_LABELS,
  NOTIFICATION_URL,
  ROOT_URL,
  TERMINALS_URL,
  TERMINAL_DETAIL_URL,
  TRANSACTIONS_URL,
  TRANSACTION_DETAIL_URL,
  MERCHANTS_URL,
  MERCHANT_DETAIL_URL,
  ERROR_PAGE,
} from './appRoutesConst';
import { AuthProvider } from '../../providers/authProvider';
import Callback from 'pages/Callback';
import Logout from 'pages/Logout';
import MerchantsPage from 'pages/merchantsPage';
import MerchantDetail from 'pages/detailPages/merchantDetail';
import ErrorPage from 'pages/ErrorPage';
import NotFoundPage from 'pages/NotFoundPage';

export const useRoutesObject = () => {
  const { t } = useTranslation('common', { keyPrefix: 'navigation' });

  const notificationRoutes = {
    path: NOTIFICATION_URL,
    breadcrumb: t(NAVBAR_LABELS.NOTIFICATION_LABEL),
    element: (
      <AuthProvider>
        <NotificationsPage />
      </AuthProvider>
    ),
  };
  const merchantsRoutes = {
    path: MERCHANTS_URL,
    breadcrumb: t(NAVBAR_LABELS.MERCHANTS_LABEL),
    element: (
      <AuthProvider>
        <MerchantsPage />
      </AuthProvider>
    ),
  };
  const terminalsRoutes = {
    path: TERMINALS_URL,
    breadcrumb: t(NAVBAR_LABELS.TERMINALS_LABEL),
    element: (
      <AuthProvider>
        <TerminalsPage />
      </AuthProvider>
    ),
  };
  const locationsRoutes = {
    path: LOCATIONS_URL,
    breadcrumb: t(NAVBAR_LABELS.LOCATIONS_LABEL),
    element: (
      <AuthProvider>
        <LocationsPage />
      </AuthProvider>
    ),
  };
  const transactionsRoutes = {
    path: TRANSACTIONS_URL,
    breadcrumb: t(NAVBAR_LABELS.TRANSACTIONS_LABEL),
    element: (
      <AuthProvider>
        <TransactionsPage />
      </AuthProvider>
    ),
  };
  const merchantDetail = {
    path: MERCHANT_DETAIL_URL,
    breadcrumb: t('breadcrumbs.merchantDetail'),
    element: (
      <AuthProvider>
        <MerchantDetail />
      </AuthProvider>
    ),
  };
  const terminalDetail = {
    path: TERMINAL_DETAIL_URL,
    breadcrumb: t('breadcrumbs.terminalDetail'),
    element: (
      <AuthProvider>
        <TerminalDetailPage />
      </AuthProvider>
    ),
  };
  const locationDetail = {
    path: LOCATION_DETAIL_URL,
    breadcrumb: t('breadcrumbs.locationDetail'),
    element: (
      <AuthProvider>
        <LocationDetailPage />
      </AuthProvider>
    ),
  };
  const transactionDetail = {
    path: TRANSACTION_DETAIL_URL,
    breadcrumb: t('breadcrumbs.transactionDetail'),
    element: (
      <AuthProvider>
        <TransactionDetailPage />
      </AuthProvider>
    ),
  };

  const routesObject = [
    {
      path: ROOT_URL,
      children: [
        {
          breadcrumb: t('breadcrumbs.dashboard'),
          index: true,
          element: (
            <AuthProvider>
              <DashboardPage />
            </AuthProvider>
          ),
        },
        notificationRoutes,
        merchantsRoutes,
        terminalsRoutes,
        locationsRoutes,
        transactionsRoutes,
        merchantDetail,
        terminalDetail,
        locationDetail,
        transactionDetail,
        {
          path: '*',
          element: (
            <AuthProvider>
              <NotFoundPage />,{' '}
            </AuthProvider>
          ),
        },
      ],
    },
    {
      path: CALLBACK_URL,
      element: <Callback />,
    },
    {
      path: LOGOUT_URL,
      element: <Logout />,
    },
    {
      path: ERROR_PAGE,
      element: <ErrorPage />,
    },
  ];
  return routesObject;
};

const AppRoutes = () => {
  const routesObject = useRoutesObject();
  const element = useRoutes(routesObject);
  return <>{element}</>;
};

export default AppRoutes;
